import { convert, RequestType } from '@atomica.co/utils';
import {
  DeleteConnectingsRequest,
  DeleteConnectingsResponse,
  DELETE_CONNECTINGS,
  FetchCommonConnectingsRequest,
  FetchCommonConnectingsResponse,
  FetchConnectingsRequest,
  FetchConnectingsResponse,
  FETCH_COMMON_CONNECTINGS,
  FETCH_CONNECTINGS,
  toFuncName
} from '@atomica.co/yosemal';
import { functions } from '../firebase';

export default class ConnectionRequest {
  public static fetchConnectings = async (request: FetchConnectingsRequest): Promise<FetchConnectingsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_CONNECTINGS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchCommonConnectings = async (
    request: FetchCommonConnectingsRequest
  ): Promise<FetchCommonConnectingsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_COMMON_CONNECTINGS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static deleteConnectings = async (request: DeleteConnectingsRequest): Promise<DeleteConnectingsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(DELETE_CONNECTINGS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}

import {
  Button,
  CheckBox,
  Component,
  DateBox,
  InputBox,
  Scrollable,
  SingleLineText,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { ProviderId } from '@atomica.co/types';
import { builder, Email, EMPTY, Message, MINUS_ONE, Name, Password } from '@atomica.co/utils';
import { ErrorCodeEnum, ErrorItemEnum, ERROR_MESSAGES, FrameId, SaveNewUserRequest } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import firebase from 'firebase';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Logo from '../../../components/logo/Logo';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import { auth } from '../../../firebase';
import Remarks from './Remarks';

interface P {
  email: Email;
  onNext(request: SaveNewUserRequest): void;
}

const InputAccountInfoWithPassword: React.FC<P> = React.memo(props => {
  const { email, onNext } = props;
  const unmountRef = useUnmountRef();
  const [isTermOfUseAgreed, setIsTermOfUseAgreed] = useSafeState<boolean>(unmountRef, false);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [passwordToSave, setPasswordToSave] = useSafeState<Password>(unmountRef, EMPTY);
  const [passwordError, setPasswordError] = useSafeState<Message>(unmountRef, EMPTY);
  const [familyNameToSave, setFamilyNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [familyNameKanaToSave, setFamilyNameKanaToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [familyNameError, setFamilyNameError] = useSafeState<Message>(unmountRef, EMPTY);
  const [firstNameToSave, setFirstNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstNameKanaToSave, setFirstNameKanaToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstNameError, setFirstNameError] = useSafeState<Message>(unmountRef, EMPTY);
  const [dateOfBirthToSave, setDateOfBirthToSave] = useSafeState<Date>(unmountRef, new Date('2000-01-01'));

  const disabledNextButton = useMemo<boolean>(() => {
    return (
      !isTermOfUseAgreed || !email || !passwordToSave || !familyNameToSave || !firstNameToSave || !dateOfBirthToSave
    );
  }, [isTermOfUseAgreed, email, passwordToSave, familyNameToSave, firstNameToSave, dateOfBirthToSave]);

  const constructErrorMessages = useSafeCallback(
    (item: ErrorItemEnum, code: ErrorCodeEnum): void => {
      Object.entries(ERROR_MESSAGES).forEach(([key, value]) => {
        if (code.toString().indexOf(key) === MINUS_ONE) return;

        switch (item) {
          case ErrorItemEnum.PASSWORD:
            setPasswordError(value);
            break;
          case ErrorItemEnum.FAMILY_NAME:
            setFamilyNameError(value);
            break;
          case ErrorItemEnum.FIRST_NAME:
            setFirstNameError(value);
            break;
          default:
            return;
        }
      });
    },
    [setPasswordError, setFamilyNameError, setFirstNameError]
  );

  const handleNextButtonClicked = useSafeCallback((): void => {
    auth
      .createUserWithEmailAndPassword(email, passwordToSave)
      .then(async (credential: firebase.auth.UserCredential): Promise<void> => {
        const request = builder<SaveNewUserRequest>()
          .externalId(credential.user!.uid)
          .familyName(familyNameToSave)
          .familyNameKana(familyNameKanaToSave)
          .firstName(firstNameToSave)
          .firstNameKana(firstNameKanaToSave)
          .email(email)
          .dateOfBirth(dateOfBirthToSave)
          .providerId(ProviderId.EMAIL)
          .frameId(FrameId.NONE)
          .build();

        onNext(request);
      })
      .catch(error => {
        setSaving(false);
        constructErrorMessages(ErrorItemEnum.PASSWORD, error.code);
      });
  }, [
    setSaving,
    email,
    passwordToSave,
    familyNameToSave,
    familyNameKanaToSave,
    firstNameToSave,
    firstNameKanaToSave,
    dateOfBirthToSave,
    onNext,
    constructErrorMessages
  ]);

  return (
    <Component loading={saving} className='input-account-info-with-password'>
      <Container>
        <Scrollable>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <InputWrapper>
            <SingleLineText style={styleForEmail} text={email} />
          </InputWrapper>

          <PasswordWrapper>
            <InputBox type='password' label='パスワード' text={passwordToSave} onChange={setPasswordToSave} />
            {!!passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
          </PasswordWrapper>

          <FullNameWrapper>
            <NameWrapper>
              <InputBox maxLength={15} label='姓' text={familyNameToSave} onChange={setFamilyNameToSave} />
              {!!familyNameError && <ErrorMessage>{familyNameError}</ErrorMessage>}
            </NameWrapper>

            <NameWrapper>
              <InputBox maxLength={15} label='名' text={firstNameToSave} onChange={setFirstNameToSave} />
              {!!firstNameError && <ErrorMessage>{firstNameError}</ErrorMessage>}
            </NameWrapper>
          </FullNameWrapper>

          <FullNameWrapper>
            <NameWrapper>
              <InputBox
                maxLength={15}
                label='姓（ふりがな）'
                text={familyNameKanaToSave}
                onChange={setFamilyNameKanaToSave}
              />
            </NameWrapper>

            <NameWrapper>
              <InputBox
                maxLength={15}
                label='名（ふりがな）'
                text={firstNameKanaToSave}
                onChange={setFirstNameKanaToSave}
              />
            </NameWrapper>
          </FullNameWrapper>

          <InputWrapper>
            <DateBox label='生年月日' value={dateOfBirthToSave} onChange={setDateOfBirthToSave} />
          </InputWrapper>

          <Agreement>
            <CheckBox checked={isTermOfUseAgreed} onChange={() => setIsTermOfUseAgreed(checked => !checked)}>
              <Remarks />
            </CheckBox>
          </Agreement>

          <ButtonWrapper>
            <Button type='primary' disabled={disabledNextButton} onClick={handleNextButtonClicked}>
              <Label>次へ</Label>
            </Button>
          </ButtonWrapper>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default InputAccountInfoWithPassword;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const styleForEmail: CSSProperties = {
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.twenty,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  textAlign: 'center'
};

const PasswordWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const ErrorMessage = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing / 2}px 0px 0px ${theme.mixins.spacing * 2}px;
`;

const FullNameWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const NameWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing / 2}px);
  height: 56px;
`;

const Agreement = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const Label = styled(Typography)`
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { EMPTY, Name, Text } from '@atomica.co/utils';
import { default as React } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  email: Text | undefined;
  onSave(email: Text): void;
  goTo(index: EditProfileIndex): void;
}

const InputEmail: React.FC<P> = React.memo(props => {
  const { email: initEmail = EMPTY, onSave, goTo } = props;

  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [emailToSave, setEmailToSave] = useSafeState<Name>(unmountRef, initEmail);

  const saveEmail = useSafeCallback(async (): Promise<void> => {
    setDisabledSaveButton(true);
    onSave(emailToSave);
  }, [setDisabledSaveButton, onSave, emailToSave]);

  return (
    <Component style={styleForFullExpansion} className='input-email'>
      <HeaderArea>
        <InputProfileHeader label='メールアドレス' onClick={goTo} />
      </HeaderArea>

      <Body>
        <InputWrapper>
          <InputBox showCount shape='rect' text={emailToSave} onChange={setEmailToSave} />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' disabled={disabledSaveButton} onClick={saveEmail}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default InputEmail;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;

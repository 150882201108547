import { UserId } from '@atomica.co/types';
import { UserEntity } from '@atomica.co/yosemal';
import React from 'react';
import FullModal from '../../../components/modal/FullModal';
import { AccountAction } from '../AccountScreen';
import EditProfile from './edit-profile/EditProfile';

interface P {
  isModalOpen: boolean;
  userId: UserId | undefined;
  signInUser: UserEntity | undefined;
  emitAction(action: AccountAction): void;
  onClose(): void;
}

const AccountFullModal: React.FC<P> = React.memo(props => {
  const { isModalOpen, userId, signInUser, emitAction, onClose } = props;

  return (
    <FullModal isOpen={isModalOpen} onClose={onClose}>
      <EditProfile userId={userId} signInUser={signInUser} emitAction={emitAction} />
    </FullModal>
  );
});

export default AccountFullModal;

import { Component, theme, useLongPress } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { Index } from '@atomica.co/utils';
import { UserEntity } from '@atomica.co/yosemal';
import React from 'react';
import styled from 'styled-components';
import SelectableUserCard from '../../../components/card/SelectableUserCard';
import UserCard from '../../../components/card/UserCard';
import { ConnectionAction } from '../ConnectionsScreen';

interface P {
  selectable: boolean;
  selectedConnections: UserEntity[];
  connections: UserEntity[];
  onClickConnection(userId: UserId): void;
  onSelectConnection(user: UserEntity): void;
  emitAction(action: ConnectionAction): void;
}

const ConnectionList: React.FC<P> = React.memo(props => {
  const { selectable, selectedConnections, connections, onClickConnection, onSelectConnection, emitAction } = props;
  const handleLongPress = useLongPress(() => emitAction(ConnectionAction.ENABLE_SELECTION), 600);

  return (
    <Component className='connection-list'>
      {connections.map((connection: UserEntity, index: Index) => (
        <CardArea key={index}>
          {!selectable && (
            <CardWrapper {...handleLongPress} onClick={() => onClickConnection(connection.userId)}>
              <UserCard user={connection} />
            </CardWrapper>
          )}

          {selectable && (
            <CardWrapper>
              <SelectableUserCard
                selected={!!selectedConnections.find(user => user.userId === connection.userId)}
                user={connection}
                onClick={onSelectConnection}
              />
            </CardWrapper>
          )}
        </CardArea>
      ))}
    </Component>
  );
});

export default ConnectionList;

const CardArea = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing * 1.5}px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
`;

import React from 'react';
import ExpandableModal from '../../../components/modal/ExpandableModal';
import ShareAccount from './parts/ShareAccount';

const HEIGHT = 464;

interface P {
  isModalOpen: boolean;
  onClose(): void;
}

const AccountPartialModal: React.FC<P> = React.memo(props => {
  const { isModalOpen, onClose } = props;

  return (
    <ExpandableModal isOpen={isModalOpen} modalHeight={HEIGHT} onClose={onClose}>
      <ShareAccount onClose={onClose} />
    </ExpandableModal>
  );
});

export default AccountPartialModal;

import { Code, URL } from '@atomica.co/utils';
import { DOMAINS } from '@atomica.co/yosemal';
import liff from '@line/liff';
import querystring from 'querystring';
import env from '../env/env';
import { Path } from '../router/Routes';
import { LIFF_ID, LINE_AUTHORIZE_PARAMS, LINE_AUTHORIZE_URL, YOSEMAL_CLIENT_ID, YOSEMAL_CLIENT_SECRET } from './config';

export const constructLineLoginURL = (): URL =>
  LINE_AUTHORIZE_URL +
  `?${querystring.stringify(LINE_AUTHORIZE_PARAMS)}` +
  '&redirect_uri=' +
  `https://${DOMAINS[env]}${Path.SIGN_IN}`;

export const getLineTokenBody = (code: Code) => {
  return {
    grant_type: 'authorization_code',
    code,
    redirect_uri: `https://${DOMAINS[env]}${Path.SIGN_IN}`,
    client_id: YOSEMAL_CLIENT_ID,
    client_secret: YOSEMAL_CLIENT_SECRET
  };
};

export const getLiff = async () => {
  await liff.init({ liffId: LIFF_ID });
  return liff;
};

import { convert, RequestType } from '@atomica.co/utils';
import {
  FetchUserByExternalIdRequest,
  FetchUserByExternalIdResponse,
  FetchUserProfileRequest,
  FetchUserProfileResponse,
  FetchUserRequest,
  FetchUserResponse,
  FETCH_USER,
  FETCH_USER_BY_EXTERNAL_ID,
  FETCH_USER_PROFILE,
  SaveNewUserRequest,
  SaveNewUserResponse,
  SaveUserBeriefHistoryRequest,
  SaveUserBeriefHistoryResponse,
  SaveUserFacebookURLRequest,
  SaveUserFacebookURLResponse,
  SaveUserFrameIdRequest,
  SaveUserFrameIdResponse,
  SaveUserInstagramURLRequest,
  SaveUserInstagramURLResponse,
  SaveUserPhotoRequest,
  SaveUserPhotoResponse,
  SaveUserrSelfIntroductionResponse,
  SaveUserSelfIntroductionRequest,
  SaveUserTwitterURLRequest,
  SaveUserTwitterURLResponse,
  SAVE_NEW_USER,
  SAVE_USER_BERIEF_HISTORY,
  SAVE_USER_FACEBOOK_URL,
  SAVE_USER_FRAME_ID,
  SAVE_USER_INSTAGRAM_URL,
  SAVE_USER_PHOTO,
  SAVE_USER_SELF_INTRODUCTION,
  SAVE_USER_TWITTER_URL,
  toFuncName
} from '@atomica.co/yosemal';
import { functions } from '../firebase';

export default class UserRequest {
  public static saveNewUser = async (request: SaveNewUserRequest): Promise<SaveNewUserResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_NEW_USER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveUserPhoto = async (request: SaveUserPhotoRequest): Promise<SaveUserPhotoResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_PHOTO));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveUserFrameId = async (request: SaveUserFrameIdRequest): Promise<SaveUserFrameIdResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_FRAME_ID));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveSelfIntroduction = async (
    request: SaveUserSelfIntroductionRequest
  ): Promise<SaveUserrSelfIntroductionResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_SELF_INTRODUCTION));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveBeriefHistory = async (
    request: SaveUserBeriefHistoryRequest
  ): Promise<SaveUserBeriefHistoryResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_BERIEF_HISTORY));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveTwitterURL = async (request: SaveUserTwitterURLRequest): Promise<SaveUserTwitterURLResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_TWITTER_URL));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveFacebookURL = async (request: SaveUserFacebookURLRequest): Promise<SaveUserFacebookURLResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_FACEBOOK_URL));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static saveInstagramURL = async (
    request: SaveUserInstagramURLRequest
  ): Promise<SaveUserInstagramURLResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_USER_INSTAGRAM_URL));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchUser = async (request: FetchUserRequest): Promise<FetchUserResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_USER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchUserByExternalId = async (
    request: FetchUserByExternalIdRequest
  ): Promise<FetchUserByExternalIdResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_USER_BY_EXTERNAL_ID));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchProfile = async (request: FetchUserProfileRequest): Promise<FetchUserProfileResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_USER_PROFILE));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}

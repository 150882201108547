import {
  Button,
  CheckButton,
  Component,
  getMobileWidth,
  Scrollable,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, Index, noop, Width } from '@atomica.co/utils';
import { FrameId, SaveNewUserRequest, UserEntity } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import UserPolaroid from '../../../components/polaroid/UserPolaroid';
import { HEADER_HEIGHT } from '../../../constants/common-constants';

interface P {
  request: SaveNewUserRequest | undefined;
  onSelectUserFrame(request: SaveNewUserRequest): void;
}

const SelectUserFrame: React.FC<P> = React.memo(props => {
  const { request, onSelectUserFrame } = props;
  const unmountRef = useUnmountRef();
  const width = useMemo(() => getMobileWidth(), []);
  const [frameIdToSave, setFrameIdToSave] = useSafeState<FrameId | undefined>(
    unmountRef,
    !!request ? request.frameId : FrameId.CHEERS_BLACK
  );

  const user = useMemo<UserEntity>(() => {
    return builder<UserEntity>()
      .familyName(request ? request.familyName : EMPTY)
      .familyNameKana(request ? request.familyNameKana : EMPTY)
      .firstName(request ? request.firstName : EMPTY)
      .firstNameKana(request ? request.firstNameKana : EMPTY)
      .dateOfBirth(request ? request.dateOfBirth : new Date())
      .frameId(request ? request.frameId : FrameId.CHEERS_BLACK)
      .photoURL(request ? request.photoURL : EMPTY)
      .build();
  }, [request]);

  const handleSaveButtonClicked = useSafeCallback((): void => {
    if (!request || !frameIdToSave) return;

    const updatedRequest = builder<SaveNewUserRequest>()
      .externalId(request.externalId)
      .familyName(request.familyName)
      .familyNameKana(request.familyNameKana)
      .firstName(request.firstName)
      .firstNameKana(request.firstNameKana)
      .email(request.email)
      .dateOfBirth(request.dateOfBirth)
      .providerId(request.providerId)
      .photoURL(request.photoURL)
      .frameId(frameIdToSave)
      .build();

    onSelectUserFrame(updatedRequest);
  }, [request, frameIdToSave, onSelectUserFrame]);

  return (
    <Component className='select-user-frame'>
      <Container>
        <Scrollable>
          {Object.values(FrameId).map((id: FrameId, index: Index) => (
            <PolaroidArea key={index} width={width} onClick={() => setFrameIdToSave(id)}>
              <PolaroidWrapper>
                <UserPolaroid size='large' rotate='none' frameId={id} user={user} />
              </PolaroidWrapper>

              <CheckButtonWrapper width={width}>
                <CheckButton checked={id === frameIdToSave} onClick={noop} />
              </CheckButtonWrapper>
            </PolaroidArea>
          ))}

          <SaveButtonWrapper>
            <Button type='primary' onClick={handleSaveButtonClicked}>
              <Label>保存</Label>
            </Button>
          </SaveButtonWrapper>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default SelectUserFrame;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-flow: column;
`;

const PolaroidArea = styled.div<{ width: Width }>`
  width: ${props => props.width}px;
  height: ${props => props.width + theme.mixins.spacing * 10}px;
  position: relative;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  padding: ${theme.mixins.spacing}px;
`;

const CheckButtonWrapper = styled.div<{ width: Width }>`
  width: 40px;
  height: auto;
  position: absolute;
  top: ${props => props.width / 2 + theme.mixins.spacing * 4}px;
  left: ${props => props.width / 2 - theme.mixins.spacing * 2}px;
`;

const SaveButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const Label = styled(Typography)`
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { convert, RequestType } from '@atomica.co/utils';
import {
  DeleteBoardMemberRequest,
  DeleteBoardMemberResponse,
  DELETE_BOARD_MEMBER,
  FetchBoardMembersRequest,
  FetchBoardMembersResponse,
  FetchNonBoardMembersRequest,
  FetchNonBoardMembersResponse,
  FETCH_BOARD_MEMBERS,
  FETCH_NON_BOARD_MEMBERS,
  SaveNewBoardMembersRequest,
  SaveNewBoardMemebersResponse,
  SAVE_NEW_BOARD_MEMBERS,
  toFuncName
} from '@atomica.co/yosemal';
import { functions } from '../firebase';

export default class BoardMemberRequest {
  public static saveNewMembers = async (request: SaveNewBoardMembersRequest): Promise<SaveNewBoardMemebersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(SAVE_NEW_BOARD_MEMBERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchMembers = async (request: FetchBoardMembersRequest): Promise<FetchBoardMembersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_BOARD_MEMBERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static fetchNonMembers = async (
    request: FetchNonBoardMembersRequest
  ): Promise<FetchNonBoardMembersResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_NON_BOARD_MEMBERS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static deleteMember = async (request: DeleteBoardMemberRequest): Promise<DeleteBoardMemberResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(DELETE_BOARD_MEMBER));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}

import { Component, Scrollable, theme } from '@atomica.co/components';
import React from 'react';
import styled from 'styled-components';
import Logo from '../../../../components/logo/Logo';
import { HEADER_HEIGHT } from '../../../../constants/common-constants';
import firebase from '../../../../firebase';
import EmailSignIn from './parts/EmailSignIn';
import GoogleSignIn from './parts/GoogleSignIn';
import Remarks from './parts/Remarks';

interface P {
  onSignIn(res: firebase.auth.UserCredential): void;
  resetPassword(): void;
}

const SignInOptionList: React.FC<P> = React.memo(props => {
  const { onSignIn, resetPassword } = props;

  return (
    <Component className='sign-in-option-list'>
      <Container>
        <Scrollable>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <EmailSignIn onSignIn={onSignIn} resetPassword={resetPassword} />

          <GoogleSignIn />

          {/* <LineSignIn /> */}

          <Remarks />
        </Scrollable>
      </Container>
    </Component>
  );
});

export default SignInOptionList;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

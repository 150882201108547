import { Component, theme, useSafeCallback } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface P {}

const Remarks: React.FC<P> = React.memo(() => {
  const openTermOfUse = useSafeCallback((): void => {
    window.open('https://atomica.site/yosemal/term-of-use', 'newtab');
  }, []);

  const openPrivacyPolicy = useSafeCallback((): void => {
    window.open('https://atomica.site/yosemal/privacy-policy', 'newtab');
  }, []);

  return (
    <Component className='remarks'>
      <MessageWrapper>
        <Message>
          ログインには
          <Link onClick={openTermOfUse}>ご利用規約</Link>
          および
          <Link onClick={openPrivacyPolicy}>プライバシーポリシー</Link>
          への同意が必要です。ご利用いただいた場合には、ご同意いただいたものとして取り扱わせていただきます。
        </Message>
      </MessageWrapper>
    </Component>
  );
});

export default Remarks;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 6}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const Message = styled.div`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Link = styled(Typography)`
  display: inline;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily} !important;
  cursor: pointer;
`;

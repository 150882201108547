import {
  Component,
  getMobileWidth,
  Skeleton,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { EMPTY, Name, URL, Width } from '@atomica.co/utils';
import { FrameId } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FramePos, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../constants/frame-constant';
import PhotoRequest from '../../requests/photo-request';

interface P {
  initPhotoURL?: URL;
  frameId: FrameId;
  name: Name;
  dateOfBirth: Date;
  onUpload(photoURL: URL): void;
}

const UserPhotoUploader: React.FC<P> = React.memo(props => {
  const { initPhotoURL, frameId, name, dateOfBirth, onUpload } = props;
  const width = useMemo(() => getMobileWidth(), []);
  const frame = useMemo<FramePos>(() => FRAME_POSITION_FOR_PORTRAIT_POLAROID[frameId], [frameId]);
  const unmountRef = useUnmountRef();
  const [photoURL, setPhotoURL] = useSafeState<URL>(unmountRef, initPhotoURL);

  const handleProfilePhotoSelected = useSafeCallback(
    async (image): Promise<void> => {
      const uploadedPhotoURL = await PhotoRequest.uploadUserPhoto(image);
      setPhotoURL(uploadedPhotoURL);
      onUpload(uploadedPhotoURL);
    },
    [setPhotoURL, onUpload]
  );

  return (
    <Component className='user-photo-uploader'>
      <Container width={width}>
        <Card>
          <UploadArea>
            <Uploader
              type='file'
              accept='image/*'
              width={width}
              onChange={e => handleProfilePhotoSelected(e.target.files[0])}
            />

            <PhotoWrapper width={width}>
              {!photoURL && (
                <NoPhoto>
                  <AddAPhotoOutlinedIcon fontSize='large' />

                  <Label>プロフィール画像を追加しよう</Label>
                </NoPhoto>
              )}

              {!!photoURL && (
                <Skeleton animation='wave' variant='rect' style={styleForProfilePhoto(width)} src={photoURL} />
              )}
            </PhotoWrapper>
          </UploadArea>

          <StyledName>{name}</StyledName>

          <DateOfBirth>
            {!!dateOfBirth ? `${dateOfBirth.getMonth() + 1}月${dateOfBirth.getDate()}日生まれ` : EMPTY}
          </DateOfBirth>
        </Card>

        <Stamp frame={frame} src={frame.src} />
      </Container>
    </Component>
  );
});

export default UserPhotoUploader;

const Container = styled.div<{ width: Width }>`
  width: 100%;
  height: ${props => props.width + theme.mixins.spacing * 6}px;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

const Card = styled.div`
  width: 100%;
  height: auto;
  border: 0.25px solid rgba(0, 0, 0, 0.4);
  background: ${theme.mixins.background.white};
  box-shadow: ${theme.mixins.shadow.light};
  display: flex;
  flex-flow: column;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const UploadArea = styled.div`
  width: 100%;
  height: auto;
`;

const Uploader = styled.input<{ width: Width }>`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: ${props => props.width - theme.mixins.spacing * 6}px;
  opacity: 0;
  appearance: none;
  position: absolute;
  z-index: 10;
`;

const PhotoWrapper = styled.div<{ width: Width }>`
  width: 100%;
  height: ${props => props.width - theme.mixins.spacing * 6}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f3f3f3 0%, #ccc 100%);
  border: ${theme.mixins.border.lightGray};
  box-shadow: inset ${theme.mixins.shadow.light};
`;

const NoPhoto = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const styleForProfilePhoto = (width: Width): CSSProperties => {
  return {
    width: width - theme.mixins.spacing * 6,
    height: width - theme.mixins.spacing * 6,
    objectFit: 'contain',
    boxShadow: `inset ${theme.mixins.shadow.light}`
  };
};

const Label = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  padding-top: ${theme.mixins.spacing}px;
`;

const Stamp = styled.img<{ frame: FramePos }>`
  width: ${props => props.frame.width};
  margin-top: ${props => props.frame.marginTop};
  margin-left: ${props => props.frame.marginLeft};
  object-fit: contain;
  position: absolute;
  ${theme.mixins.userSelectDisabled};
`;

const StyledName = styled(Typography)`
  min-height: 36px;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twentyFour}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily} !important;
`;

const DateOfBirth = styled(Typography)`
  min-height: 18px;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.twelve}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily} !important;
`;

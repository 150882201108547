import { Component, theme, useLongPress } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { Index, isEven } from '@atomica.co/utils';
import { UserEntity } from '@atomica.co/yosemal';
import React from 'react';
import styled from 'styled-components';
import SelectableUserPolaroid from '../../../components/polaroid/SelectableUserPolaroid';
import UserPolaroid, { Rotate } from '../../../components/polaroid/UserPolaroid';
import { ConnectionAction } from '../ConnectionsScreen';

interface P {
  selectable: boolean;
  selectedConnections: UserEntity[];
  connections: UserEntity[];
  onClickConnection(userId: UserId): void;
  onSelectConnection(user: UserEntity): void;
  emitAction(action: ConnectionAction): void;
}

const ConnectionPolaroids: React.FC<P> = React.memo(props => {
  const { selectable, selectedConnections, connections, onClickConnection, onSelectConnection, emitAction } = props;
  const handleLongPress = useLongPress(() => emitAction(ConnectionAction.ENABLE_SELECTION), 600);

  return (
    <Component className='connection-polaroids'>
      <PolaroidsArea>
        {connections.map((connection: UserEntity, index: Index) => (
          <PolaroidArea key={index} position={isEven(index) ? 'left' : 'right'}>
            {!selectable && (
              <PolaroidWrapper {...handleLongPress} onClick={() => onClickConnection(connection.userId)}>
                <UserPolaroid
                  size='medium'
                  rotate={isEven(index) ? 'left' : 'right'}
                  frameId={connection.frameId}
                  user={connection}
                />
              </PolaroidWrapper>
            )}

            {selectable && (
              <PolaroidWrapper>
                <SelectableUserPolaroid
                  selected={!!selectedConnections.find(user => user.userId === connection.userId)}
                  size='medium'
                  rotate={isEven(index) ? 'left' : 'right'}
                  frameId={connection.frameId}
                  user={connection}
                  onClick={onSelectConnection}
                />
              </PolaroidWrapper>
            )}
          </PolaroidArea>
        ))}
      </PolaroidsArea>
    </Component>
  );
});

export default ConnectionPolaroids;

const PolaroidsArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: ${theme.mixins.spacing}px;
`;

const PolaroidArea = styled.div<{ position: Rotate }>`
  width: auto;
  height: auto;
  padding: ${theme.mixins.spacing * 1}px;
  margin-top: ${props => (props.position === 'right' ? '4px' : '-4px')};
`;

const PolaroidWrapper = styled.div`
  width: auto;
  height: auto;
`;

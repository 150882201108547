import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { EMPTY, Label, Message, Name } from '@atomica.co/utils';
import { default as React } from 'react';
import styled from 'styled-components';
import BoardMessageInfoHeader from '../../board-header/BoardMessageInfoHeader';
import { BoardMessageIndex } from '../BoardMessage';

const MAX_LENGTH = 100;

interface P {
  label: Label;
  message: Message | undefined;
  onClickSaveButton(message: Message): void;
  goTo(index: BoardMessageIndex): void;
}

const InputBoardMessage: React.FC<P> = React.memo(props => {
  const { label, message: initMessage = EMPTY, onClickSaveButton, goTo } = props;

  const unmountRef = useUnmountRef();
  const [messageToSave, setMessageToSave] = useSafeState<Name>(unmountRef, initMessage);

  return (
    <Component style={styleForFullExpansion} className='input-board-message'>
      <BoardMessageInfoHeader label={label} goBack={() => goTo(BoardMessageIndex.BOARD_MESSAGE_MAIN)} />

      <Container>
        <InputWrapper>
          <InputBox
            showCount
            multiline
            shape='rect'
            maxLength={MAX_LENGTH}
            text={messageToSave}
            onChange={setMessageToSave}
          />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' onClick={() => onClickSaveButton(messageToSave)}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Container>
    </Component>
  );
});

export default InputBoardMessage;

const Container = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.yellow};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;

import {
  Component,
  getMobileWidth,
  Icon,
  SingleLineText,
  theme,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, getDayOfWeek, Height, isFuture, Language, noop, toDateStr, Width } from '@atomica.co/utils';
import { BoardEntity, BoardThreadEntity } from '@atomica.co/yosemal';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FramePos as FrameVo, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../constants/frame-constant';
import { BoardAction } from '../../screens/board/BoardScreen';
import settingIcon from './../../assets/icon/icon_setting.png';
import BoardPhoto from './BoardPhoto';
import BoardThreadPolaroid from './BoardThreadPolaroid';

export type Rotate = 'none' | 'right' | 'left';

interface P {
  showIcons?: boolean;
  showDetails?: boolean;
  rotate: Rotate;
  board: BoardEntity | undefined;
  onClick?(action: BoardAction): void;
}

const BoardDetailsPolaroid: React.FC<P> = React.memo(props => {
  const { showIcons = false, showDetails = false, rotate, board, onClick = noop } = props;

  const photoWidth = useMemo<Width>(() => getMobileWidth() - theme.mixins.spacing * 8, []);
  const photoHeight = useMemo<Height>(() => photoWidth, [photoWidth]);
  const cardWidth = useMemo<Width>(() => photoWidth + theme.mixins.spacing * 4, [photoWidth]);
  const cardHeight = useMemo<Height>(() => photoHeight + theme.mixins.spacing * 12, [photoHeight]);
  const frame = useMemo<FrameVo | undefined>(
    () => (!!board && !!board.frameId ? FRAME_POSITION_FOR_PORTRAIT_POLAROID[board.frameId] : undefined),
    [board]
  );

  const unmountRef = useUnmountRef();
  const [showingDetails, setShowingDetails] = useSafeState<boolean>(unmountRef, showDetails);

  return (
    <Component className='board-details-polaroid'>
      <Container width={cardWidth} height={cardHeight} onClick={() => !showDetails && setShowingDetails(show => !show)}>
        <Card rotate={rotate} width={cardWidth} height={cardHeight}>
          <BoardPhoto size='large' width={photoWidth!} height={photoHeight!} board={board} />

          <TitleArea>
            {showIcons && (
              <IconArea>
                <InfoIcon fontSize='medium' />
              </IconArea>
            )}

            <TitleWrapper showIcons={showIcons}>
              <NameWrapper>
                <SingleLineText style={styleForName} text={!!board ? board.name : undefined} />
              </NameWrapper>

              <DateWrapper>
                <SingleLineText
                  style={styleForInnerDate}
                  text={
                    !!board
                      ? `${toDateStr(board.dueDate, Language.JAPANESE)} ${getDayOfWeek(board.dueDate)} ${
                          isFuture(board.dueDate) ? 'まで' : EMPTY
                        }`
                      : undefined
                  }
                />
              </DateWrapper>
            </TitleWrapper>

            {showIcons && (
              <IconArea onClick={() => onClick(BoardAction.OPEN_BOARD_SETTINGS)}>
                <Icon size='small' src={settingIcon} />
              </IconArea>
            )}
          </TitleArea>
        </Card>

        {!!frame && !!board && !!board.photoURL && <Stamp frame={frame} src={frame.src} />}
      </Container>

      {showingDetails && (
        <ThreadListWrapper>
          {!!board && !!board.description && (
            <ThreadWrapper>
              <BoardThreadPolaroid
                rotate='none'
                thread={builder<BoardThreadEntity>()
                  .text(board.description)
                  .createdAt(board.createdAt)
                  .photoURL(board.owner.photoURL)
                  .frameId(board.owner.frameId)
                  .userId(board.owner.userId)
                  .familyName(board.owner.familyName)
                  .firstName(board.owner.firstName)
                  .build()}
              />
            </ThreadWrapper>
          )}

          {!!board && !!board.requestForNewPhoto && (
            <ThreadWrapper>
              <BoardThreadPolaroid
                rotate='none'
                thread={builder<BoardThreadEntity>()
                  .text(board.requestForNewPhoto)
                  .createdAt(board.createdAt)
                  .photoURL(board.owner.photoURL)
                  .frameId(board.owner.frameId)
                  .userId(board.owner.userId)
                  .familyName(board.owner.familyName)
                  .firstName(board.owner.firstName)
                  .build()}
              />
            </ThreadWrapper>
          )}
        </ThreadListWrapper>
      )}
    </Component>
  );
});

export default BoardDetailsPolaroid;

const Container = styled.div<{ width: Width; height: Height }>`
  width: ${props => props.width + theme.mixins.spacing * 2}px;
  height: ${props => props.height + theme.mixins.spacing * 4}px;
  position: relative;
  display: flex;
  align-items: flex-start;
`;

const Card = styled.div<{
  rotate: Rotate;
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background: ${theme.mixins.background.white};
  border: ${theme.mixins.border.lightGray};
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-shadow: ${theme.mixins.shadow.light};
  transform: ${props => {
    switch (props.rotate) {
      case 'right':
        return 'rotate(1.25deg)';
      case 'left':
        return 'rotate(-0.79deg)';
      default:
        return 'unset';
    }
  }};
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
  margin: ${theme.mixins.spacing}px;
`;

const TitleArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: ${theme.mixins.spacing / 2}px 0px;
`;

const IconArea = styled.div`
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const TitleWrapper = styled.div<{ showIcons: boolean }>`
  width: ${props => (props.showIcons ? 'calc(100% - 80px)' : '100%')};
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const NameWrapper = styled.div`
  width: 100%;
  height: 32px;
  padding: ${theme.mixins.spacing / 2}px 0px;
`;

const styleForName: CSSProperties = {
  width: '100%',
  height: 'auto',
  fontSize: theme.mixins.typography.fontSize.twenty,
  fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const DateWrapper = styled.div`
  width: 100%;
  height: 16px;
  text-align: center;
  padding: 0px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.userSelectDisabled};
`;

const styleForInnerDate: CSSProperties = {
  width: '100%',
  height: 'auto',
  color: theme.mixins.typography.fontColor.lightGray,
  fontSize: theme.mixins.typography.fontSize.twelve,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily
};

const ThreadListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 0px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 5}px;
`;

const ThreadWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const Stamp = styled.img<{ frame: FrameVo }>`
  width: ${props => props.frame.width};
  position: absolute;
  top: ${props => props.frame.marginTop};
  left: ${props => props.frame.marginLeft};
  object-fit: contain;
  z-index: 10;
  ${theme.mixins.userSelectDisabled};
`;

import { EMPTY, Name } from '@atomica.co/utils';
import {
  BoardMessageEntity,
  BoardThreadEntity,
  NotificationEntity,
  ProfileEntity,
  SaveNewUserRequest,
  UserEntity
} from '@atomica.co/yosemal';

export const toFullName = (
  entity:
    | UserEntity
    | ProfileEntity
    | SaveNewUserRequest
    | BoardMessageEntity
    | BoardThreadEntity
    | NotificationEntity
    | undefined
): Name => (!!entity ? `${entity.familyName} ${entity.firstName}` : EMPTY);

import {
  CircularLoader,
  Component,
  Scrollable,
  theme,
  useLongPress,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, Count, Index, ZERO } from '@atomica.co/utils';
import { FetchNotificationsRequest, NotificationEntity } from '@atomica.co/yosemal';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import NotificationCard from '../../../components/card/NotificationCard';
import SelectableNotificationCard from '../../../components/card/SelectableNotificationCard';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import NotificationRequest from '../../../requests/notification-request';
import { NotificationAction } from '../NotificationsScreen';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

export interface NotificationsRef {
  refresh(): void;
}

interface P {
  selectable: boolean;
  selectedNotifications: NotificationEntity[];
  userId: UserId | undefined;
  onClickNotification(notification: NotificationEntity): void;
  onSelectNotification(notification: NotificationEntity): void;
  emitAction(action: NotificationAction): void;
}

const NotificationList: React.ForwardRefExoticComponent<P & React.RefAttributes<NotificationsRef>> = React.forwardRef<
  NotificationsRef,
  P
>((props, ref) => {
  const { selectable, selectedNotifications, userId, onClickNotification, onSelectNotification, emitAction } = props;
  const bottomRef = useRef<HTMLDivElement>();
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [notifications, setNotifications] = useSafeState<NotificationEntity[]>(unmountRef, []);
  const handleLongPress = useLongPress(() => emitAction(NotificationAction.ENABLE_SELECTION), 600);

  const loadNotifications = useSafeCallback(async (): Promise<void> => {
    if (!hasMore || !userId) return;

    const request = builder<FetchNotificationsRequest>().userId(userId).limit(LIMIT).offset(count.current).build();

    const response = await NotificationRequest.fetchNotifications(request);
    const notificationsToAdd = response.notifications;

    hasMore.current = notificationsToAdd.length === LIMIT;
    count.current += notificationsToAdd.length;
    setNotifications(notifications =>
      count.current === notificationsToAdd.length
        ? response.notifications
        : [...notifications, ...response.notifications]
    );
    setLoaded(true);
  }, [userId, setLoaded, setNotifications]);

  useEffect(() => {
    loadNotifications();
  }, [loadNotifications]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadNotifications();
      }
    },
    [loadNotifications]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);

    bottomRef.current && observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, [loaded, onScroll]);

  const refresh = useSafeCallback(async (): Promise<void> => {
    hasMore.current = true;
    count.current = ZERO;
    await loadNotifications();
  }, [loadNotifications]);

  useImperativeHandle(ref, () => ({
    refresh: async () => await refresh()
  }));

  return (
    <Component
      loading={!loaded}
      style={{ width: '100%', height: window.innerHeight - HEADER_HEIGHT }}
      className='notification-list'
    >
      <Scrollable>
        <Container>
          {notifications.map((notification: NotificationEntity, index: Index) => (
            <CardArea key={index}>
              {!selectable && (
                <CardWrapper {...handleLongPress} onClick={() => onClickNotification(notification)}>
                  <NotificationCard notification={notification} />
                </CardWrapper>
              )}

              {selectable && (
                <CardWrapper>
                  <SelectableNotificationCard
                    selected={
                      !!selectedNotifications.find(selected => selected.notificationId === notification.notificationId)
                    }
                    notification={notification}
                    onClick={onSelectNotification}
                  />
                </CardWrapper>
              )}
            </CardArea>
          ))}

          {hasMore.current && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}

          <Bottom ref={bottomRef} />
        </Container>
      </Scrollable>
    </Component>
  );
});

export default NotificationList;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px 0px;
`;

const CardArea = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 1.5}px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 240px;
`;

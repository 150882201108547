import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, Name } from '@atomica.co/utils';
import { BoardEntity, SaveBoardNameRequest } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React } from 'react';
import styled from 'styled-components';
import BoardRequest from '../../../../../requests/board-request';
import { BOARD_NAME_LENGTH } from '../../create-board/CreateBoard';

interface P {
  board: BoardEntity | undefined;
  onSaveBoardName(name: Name): void;
  goBack(): void;
}

const InputBoardName: React.FC<P> = React.memo(props => {
  const { board, onSaveBoardName, goBack } = props;
  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [nameToSave, setNameToSave] = useSafeState<Name>(unmountRef, !!board ? board.name : EMPTY);

  const saveBoardName = useSafeCallback(async (): Promise<void> => {
    if (!board) return;
    setDisabledSaveButton(true);

    try {
      const request = builder<SaveBoardNameRequest>().boardId(board.boardId).name(nameToSave).build();

      await BoardRequest.saveBoardName(request);
      onSaveBoardName(nameToSave);
    } catch (e) {
      setDisabledSaveButton(false);
    }
  }, [board, setDisabledSaveButton, nameToSave, onSaveBoardName]);

  return (
    <Component style={styleForFullExpansion} className='input-board-name'>
      <Header>
        <HeaderButton>
          <Button onClick={goBack}>
            <BackIcon />
          </Button>
        </HeaderButton>

        <HeaderLabel>ボード名</HeaderLabel>

        <HeaderButton />
      </Header>

      <Body>
        <InputWrapper>
          <InputBox showCount maxLength={BOARD_NAME_LENGTH} shape='rect' text={nameToSave} onChange={setNameToSave} />
        </InputWrapper>

        <ButtonArea>
          <Button disabled={disabledSaveButton} type='primary' onClick={saveBoardName}>
            <Label>保存</Label>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default InputBoardName;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const HeaderButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const HeaderLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.lightGray};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const Label = styled.div`
  width: 80px;
  height: auto;
`;

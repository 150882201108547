import { URL, uuid } from '@atomica.co/utils';
import { APP_NAME } from '@atomica.co/yosemal';
import compress from 'browser-image-compression';
import { storage } from '../firebase';

const OPTIONS = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 1080
};

const COLLECTION_NAME_PHOTOS = 'photos';

export default class PhotoRequest {
  public static uploadUserPhoto = async (image: File): Promise<URL> => {
    try {
      const compressedImage = await compress(image, OPTIONS);
      const ref = storage.ref(`/${COLLECTION_NAME_PHOTOS}/${APP_NAME}/users/${uuid()}`);
      const snapshot = await ref.put(compressedImage);
      return await snapshot.ref.getDownloadURL();
    } catch (e) {
      throw Error(JSON.stringify(e));
    }
  };

  public static uploadBoardPhoto = async (image: File): Promise<URL> => {
    try {
      const compressedImage = await compress(image, OPTIONS);
      const ref = storage.ref(`/${COLLECTION_NAME_PHOTOS}/${APP_NAME}/boards/${uuid()}`);
      const snapshot = await ref.put(compressedImage);
      return await snapshot.ref.getDownloadURL();
    } catch (e) {
      throw Error(JSON.stringify(e));
    }
  };

  public static uploadMessagePhoto = async (image: File): Promise<URL> => {
    try {
      const compressedImage = await compress(image, OPTIONS);
      const ref = storage.ref(`/${COLLECTION_NAME_PHOTOS}/${APP_NAME}/messages/${uuid()}`);
      const snapshot = await ref.put(compressedImage);
      return await snapshot.ref.getDownloadURL();
    } catch (e) {
      throw Error(JSON.stringify(e));
    }
  };
}

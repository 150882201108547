import { Component, getMobileWidth, SingleLineText, Skeleton, theme } from '@atomica.co/components';
import { EMPTY, Height, Title, URL, Width } from '@atomica.co/utils';
import { FrameId } from '@atomica.co/yosemal';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FramePos, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../constants/frame-constant';

interface P {
  frameId: FrameId | undefined;
  photoURL: URL | undefined;
  title: Title;
}

const CommonPolaroid: React.FC<P> = React.memo(props => {
  const { frameId, photoURL, title } = props;

  const photoWidth = useMemo<Width | undefined>(() => {
    const fullWidth = getMobileWidth();
    return fullWidth / 4 - theme.mixins.spacing * 3;
  }, []);

  const photoHeight = useMemo<Height | undefined>(() => {
    return photoWidth;
  }, [photoWidth]);

  const cardWidth = useMemo<Width | undefined>(() => {
    return photoWidth! + theme.mixins.spacing;
  }, [photoWidth]);

  const cardHeight = useMemo<Height | undefined>(() => {
    return photoHeight! + theme.mixins.spacing * 4;
  }, [photoHeight]);

  const frame = useMemo<FramePos | undefined>(() => {
    return !!frameId ? FRAME_POSITION_FOR_PORTRAIT_POLAROID[frameId] : undefined;
  }, [frameId]);

  return (
    <Component className='common-polaroid'>
      <Container width={cardWidth} height={cardHeight}>
        <Card width={cardWidth} height={cardHeight}>
          <Skeleton
            animation='wave'
            variant='rect'
            style={getStyleForPhoto(photoWidth!, photoHeight!)}
            src={!!photoURL ? photoURL : EMPTY}
          />

          <TextWrapperForName>
            <SingleLineText style={styleForName()} text={title} />
          </TextWrapperForName>
        </Card>

        {!!frame && <Stamp frame={frame} src={frame.src} />}
      </Container>
    </Component>
  );
});

export default CommonPolaroid;

const Container = styled.div<{
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

const Card = styled.div<{
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: 0.25px solid rgba(0, 0, 0, 0.4);
  background: ${theme.mixins.background.white};
  box-shadow: ${theme.mixins.shadow};
  display: flex;
  flex-flow: column;
  position: absolute;
  padding: ${theme.mixins.spacing / 2}px;
`;

const getStyleForPhoto = (width: Width, height: Height): CSSProperties => {
  return {
    width,
    height,
    background: theme.mixins.background.lightGray,
    border: theme.mixins.border.lightGray,
    boxShadow: `inset ${theme.mixins.shadow}`,
    objectFit: 'contain'
  };
};

const Stamp = styled.img<{ frame: FramePos }>`
  width: ${props => props.frame.width};
  margin-top: ${props => props.frame.marginTop};
  margin-left: ${props => props.frame.marginLeft};
  object-fit: contain;
  position: absolute;
  ${theme.mixins.userSelectDisabled};
`;

const TextWrapperForName = styled.div`
  width: 100%;
  height: 24px;
  padding: ${theme.mixins.spacing}px 0px;
`;

const styleForName = (): CSSProperties => {
  return {
    width: '100%',
    height: 'auto',
    color: theme.mixins.typography.fontColor.black,
    fontSize: theme.mixins.typography.fontSize.eight,
    fontWeight: theme.mixins.typography.fontWeight.sevenHundreds,
    fontFamily: theme.mixins.typography.fontFamily,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    userSelect: 'none'
  };
};

import {
  Button,
  Component,
  Scrollable,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { BoardEntity, BoardMemberEntity } from '@atomica.co/yosemal';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React } from 'react';
import styled from 'styled-components';
import BoardDetailsPolaroid from '../../../../../components/polaroid/BoardDetailsPolaroid';
import { CreateBoardIndex } from '../CreateBoard';
import ShowSelectedMembersToInvite from './ShowSelectedMembersToInvite';

interface P {
  board: BoardEntity | undefined;
  members: BoardMemberEntity[];
  onClickSaveButton(): void;
  goTo(index: CreateBoardIndex): void;
}

const ConfirmBoardPreview: React.FC<P> = React.memo(props => {
  const { board, members, onClickSaveButton, goTo } = props;
  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);

  const handleSaveButtonClicked = useSafeCallback((): void => {
    setDisabledSaveButton(true);
    onClickSaveButton();
  }, [setDisabledSaveButton, onClickSaveButton]);

  return (
    <Component style={styleForFullExpansion} className='confirm-board-preview'>
      <Header>
        <ButtonArea>
          <Button onClick={() => goTo(CreateBoardIndex.CREATE_BOARD_MAIN)}>
            <BackIcon />
          </Button>
        </ButtonArea>

        <LabelArea>
          <HeaderLabel>プレビュー</HeaderLabel>
        </LabelArea>

        <ButtonArea>
          <Button type='primary' shape='rect' disabled={disabledSaveButton} onClick={handleSaveButtonClicked}>
            <ButtonLabel>送付</ButtonLabel>
          </Button>
        </ButtonArea>
      </Header>

      <Body>
        <Scrollable showScrollbar>
          <Container>
            <PolaroidWrapper>
              <BoardDetailsPolaroid showDetails rotate='left' board={board} />
            </PolaroidWrapper>

            <CardWrapper>
              <ShowSelectedMembersToInvite selectedMembers={members} />
            </CardWrapper>
          </Container>
        </Scrollable>
      </Body>
    </Component>
  );
});

export default ConfirmBoardPreview;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px 0px;
`;

const ButtonArea = styled.div`
  min-width: 72px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const LabelArea = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px ${theme.mixins.spacing * 2}px;
`;

const HeaderLabel = styled.div`
  width: 100%;
  height: 100%;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen};
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLabel = styled.div`
  width: 48px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.yellow};
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

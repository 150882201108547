import { Component, theme, useSafeCallback } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface P {}

const Remarks: React.FC<P> = React.memo(() => {
  const openTermOfUse = useSafeCallback((): void => {
    window.open('https://atomica.site/yosemal/term-of-use', 'newtab');
  }, []);

  const openPrivacyPolicy = useSafeCallback((): void => {
    window.open('https://atomica.site/yosemal/privacy-policy', 'newtab');
  }, []);

  return (
    <Component className='remarks'>
      <Message>
        <Link
          onClick={e => {
            e.stopPropagation();
            openTermOfUse();
          }}
        >
          ご利用規約
        </Link>
        および
        <Link
          onClick={e => {
            e.stopPropagation();
            openPrivacyPolicy();
          }}
        >
          プライバシーポリシー
        </Link>
        に同意する
      </Message>
    </Component>
  );
});

export default Remarks;

const Message = styled.div`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Link = styled(Typography)`
  display: inline;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  cursor: pointer;
`;

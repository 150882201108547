import {
  Button,
  Component,
  InputBox,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Email, EMPTY, Message, MINUS_ONE, Password } from '@atomica.co/utils';
import { ERROR_MESSAGES } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Process } from '../../../../../enums/common-enum';
import firebase, { auth } from '../../../../../firebase';
import useProcess from '../../../../../redux/hooks/useProcess';

interface P {
  onSignIn(res: firebase.auth.UserCredential): void;
  resetPassword(): void;
}

const EmailSignIn: React.FC<P> = React.memo(props => {
  const { onSignIn, resetPassword } = props;
  const unmountRef = useUnmountRef();
  const { setProcess } = useProcess();
  const [disabledSignInButton, setDisabledSignInButton] = useSafeState<boolean>(unmountRef, true);
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);
  const [password, setPassword] = useSafeState<Password>(unmountRef, EMPTY);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  useEffect(() => {
    setDisabledSignInButton(!email || !password);
  }, [setDisabledSignInButton, email, password]);

  const constructErrorMessage = useSafeCallback(
    (message: Message): void => {
      if (!message) return;

      Object.entries(ERROR_MESSAGES).forEach(([key, value]) => {
        if (message.toString().indexOf(key) === MINUS_ONE) return;
        setErrorMessage(value);
      });
    },
    [setErrorMessage]
  );

  const signInWithEmailAndPassword = useSafeCallback((): void => {
    setDisabledSignInButton(true);
    setProcess(Process.PROCESSING);

    auth
      .signInWithEmailAndPassword(email, password)
      .then(onSignIn)
      .catch(err => {
        setDisabledSignInButton(false);
        setProcess(Process.INITIAL);
        constructErrorMessage(err.code);
      });
  }, [setDisabledSignInButton, setProcess, email, password, onSignIn, constructErrorMessage]);

  return (
    <Component className='email-sign-in'>
      <Content>
        <InputWrapper>
          <InputBox hasError={!!errorMessage} type='email' label='メールアドレス' text={email} onChange={setEmail} />
        </InputWrapper>

        <InputWrapper>
          <InputBox
            hasError={!!errorMessage}
            type='password'
            label='パスワード'
            text={password}
            onChange={setPassword}
          />

          <LinkWrapper>
            <Button onClick={resetPassword}>
              <LinkLabel>パスワードを忘れた場合</LinkLabel>
            </Button>
          </LinkWrapper>
        </InputWrapper>

        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ButtonWrapper>
          <Button type='primary' disabled={disabledSignInButton} onClick={signInWithEmailAndPassword}>
            <IconWrapper>
              <MailIcon fontSize='small' />
            </IconWrapper>

            <Label>メールアドレスでログイン</Label>
          </Button>
        </ButtonWrapper>
      </Content>
    </Component>
  );
});

export default EmailSignIn;

const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LinkLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const ErrorMessage = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px !important;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 4}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing / 2}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

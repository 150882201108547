import { BoxButton, Component, Scrollable, theme, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, EMPTY, Message, noop, URL } from '@atomica.co/utils';
import { BoardEntity, BoardMessageEntity, FrameId, UserEntity } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ArrowForwardButton } from '../../../../components/buttons/ArrowForwardButton';
import BoardMessagePhotoUploader from '../../../../components/polaroid/BoardMessagePhotoUploader';
import BoardMessageHeader from '../../board-header/BoardMessageHeader';
import { BoardMessageIndex } from '../BoardMessage';

interface P {
  photoURL: URL | undefined;
  frameId: FrameId | undefined;
  message: Message;
  board: BoardEntity | undefined;
  user: UserEntity | undefined;
  onClick(index: BoardMessageIndex): void;
  onClickSaveButton(): void;
  onUpload(photoURL: URL): void;
}

const BoardMessageMain: React.FC<P> = React.memo(props => {
  const { photoURL, frameId, message, board, user, onClick, onClickSaveButton, onUpload } = props;
  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, true);

  useEffect(() => {
    const isExistedPhotoProvided = !!board && !board.isRequiredNewPhoto && !!photoURL;
    const isNewPhotoProvided = !!board && board.isRequiredNewPhoto && !!user && photoURL !== user.photoURL;
    const disabledSaveButton = (!isExistedPhotoProvided && !isNewPhotoProvided) || !frameId || !message;
    setDisabledSaveButton(disabledSaveButton);
  }, [board, user, photoURL, frameId, message, setDisabledSaveButton]);

  return (
    <Component className='board-message-main'>
      <BoardMessageHeader
        disabledSaveButton={disabledSaveButton}
        board={board}
        onClickSaveButton={() => {
          setDisabledSaveButton(true);
          onClickSaveButton();
        }}
        goBack={() => onClick(BoardMessageIndex.SHOW_BOARD_PREVIEW)}
      />

      <Scrollable showScrollbar>
        <Container>
          <UploaderWrapper>
            <BoardMessagePhotoUploader
              isRequiredNewPhoto={!!board ? board.isRequiredNewPhoto : false}
              message={builder<BoardMessageEntity>()
                .frameId(frameId!)
                .photoURL(photoURL!)
                .text(message)
                .userId(!!user ? user.userId : undefined!)
                .familyName(!!user ? user.familyName : EMPTY)
                .firstName(!!user ? user.firstName : EMPTY)
                .dateOfBirth(!!user ? user.dateOfBirth : undefined!)
                .build()}
              onUpload={onUpload}
            />
          </UploaderWrapper>

          {!!board && board.isRequiredNewPhoto && (
            <BoxWrapper>
              <BoxButton shape='rect' onClick={noop}>
                <Label>写真のオーダー</Label>
                <Request>{!!board ? board.requestForNewPhoto : EMPTY}</Request>
              </BoxButton>
            </BoxWrapper>
          )}

          <BoxWrapper>
            <ArrowForwardButton
              shape='rect'
              text='フレームを選ぶ'
              onClick={() => onClick(BoardMessageIndex.SELECT_MESSAGE_FRAME)}
            />
          </BoxWrapper>

          <BoxWrapper>
            <ArrowForwardButton
              shape='rect'
              label='あなたのコメント'
              placeholder='ここにコメントを記載しましょう。推奨の文字数は～100文字です。文字数が少ない場合は文字が大きく表示されます。'
              text={message}
              onClick={() => onClick(BoardMessageIndex.INPUT_BOARD_MESSAGE)}
            />
          </BoxWrapper>

          {/* <BoxWrapper>
            <ToggleBox
              checked
              text="完成時に連絡を受け取る"
              onClick={noop}
            />

            <Remarks>
              完成した時に連絡を受け取りたくない場合はオフにしてください
            </Remarks>
          </BoxWrapper> */}
        </Container>
      </Scrollable>
    </Component>
  );
});

export default BoardMessageMain;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;

const UploaderWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const BoxWrapper = styled.div`
  padding: ${theme.mixins.spacing / 2}px ${theme.mixins.spacing * 2}px;
`;

const Label = styled(Typography)`
  width: 100%;
  height: 14px;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.eight}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Request = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
`;

// const Remarks = styled(Typography)`
//   width: 100%;
//   height: auto;
//   color: ${theme.mixins.typography.fontColor.gray};
//   font-size: ${theme.mixins.typography.fontSize.twelve}px;
//   font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
//   font-family: ${theme.mixins.typography.fontFamily};
//   padding: 0px ${theme.mixins.spacing}px;
// `;

import { FooterTabEnum } from '../components/footer/parts/FooterTabs';
import { Path } from '../router/Routes';
import { BoardIndex } from '../screens/board/BoardScreen';
import { SignUpInTabEnum } from '../screens/sign-up-in/tabs/SignUpInTabs';

export const isAuthGuarded = (path: Path): boolean => {
  switch (path) {
    case Path.SIGN_UP:
    case Path.SIGN_IN:
    case Path.SIGN_OUT:
    case Path.REGISTER_ACCOUNT:
      return false;

    case Path.BOARD_LIST:
    case Path.BOARD_MESSAGE:
    case Path.BOARD_DETAILS:
    case Path.BOARD_THREAD:
    case Path.CONNECTIONS:
    case Path.NOTIFICATIONS:
    case Path.ACCOUNT:
      return true;

    default:
      return false;
  }
};

export const toSignUpInTab = (path: Path): SignUpInTabEnum => {
  switch (path) {
    case Path.SIGN_UP:
      return SignUpInTabEnum.SIGN_UP;

    case Path.SIGN_IN:
      return SignUpInTabEnum.SIGN_IN;

    default:
      return SignUpInTabEnum.OUT_OF_TARGET;
  }
};

export const fromSignUpInTab = (tab: SignUpInTabEnum): Path | undefined => {
  switch (tab) {
    case SignUpInTabEnum.SIGN_UP:
      return Path.SIGN_UP;

    case SignUpInTabEnum.SIGN_IN:
      return Path.SIGN_IN;
  }
};

export const toFooterTab = (path: Path): FooterTabEnum => {
  switch (path) {
    case Path.BOARD_LIST:
    case Path.BOARD_DETAILS:
    case Path.BOARD_MESSAGE:
    case Path.BOARD_THREAD:
      return FooterTabEnum.HOME;

    // case Path.WISH:
    //   return FooterTabEnum.WISH;

    case Path.CONNECTIONS:
      return FooterTabEnum.CONNECTIONS;

    case Path.NOTIFICATIONS:
      return FooterTabEnum.NOTIFICATIONS;

    case Path.ACCOUNT:
      return FooterTabEnum.ACCOUNT;

    default:
      return FooterTabEnum.OUT_OF_TARGET;
  }
};

export const toBoardIndex = (path: Path): BoardIndex => {
  switch (path) {
    case Path.BOARD_LIST:
      return BoardIndex.BOARD_LIST;

    case Path.BOARD_MESSAGE:
      return BoardIndex.BOARD_JOIN;

    case Path.BOARD_DETAILS:
      return BoardIndex.BOARD_DETAILS;

    case Path.BOARD_THREAD:
      return BoardIndex.BOARD_REPLY;

    default:
      return BoardIndex.OUT_OF_TARGET;
  }
};

import { CheckButton, Component } from '@atomica.co/components';
import { noop } from '@atomica.co/utils';
import { NotificationEntity } from '@atomica.co/yosemal';
import React from 'react';
import styled from 'styled-components';
import NotificationCard from './NotificationCard';

interface P {
  selected: boolean;
  notification: NotificationEntity;
  onClick(notification: NotificationEntity): void;
}

const SelectableNotificationCard: React.FC<P> = React.memo(props => {
  const { selected, notification, onClick } = props;

  return (
    <Component className='selectable-notification-card'>
      <Container onClick={() => onClick(notification)}>
        <CardWrapper>
          <NotificationCard notification={notification} />
        </CardWrapper>

        <ButtonWrapper>
          <CheckButton size='small' checked={selected} onClick={noop} />
        </ButtonWrapper>
      </Container>
    </Component>
  );
});

export default SelectableNotificationCard;

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
`;

const ButtonWrapper = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;
`;

import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { EMPTY, URL } from '@atomica.co/utils';
import { toCashedURLAction } from '../actions/cashed-url-action';
import store from '../store';

interface P {
  saveCurrentURL: () => void;
  hasCashedURL: () => boolean;
  openCashedURL: () => void;
  removeCashedURL: () => void;
}

function useCashedURL() {
  const unmountRef = useUnmountRef();
  const [cashedURL, setCashedURL] = useSafeState<URL>(unmountRef, store.getState().cashedURL);

  const saveCurrentURL = useSafeCallback((): void => {
    const currentURL = window.location.href;
    setCashedURL(currentURL);
    const action = toCashedURLAction(currentURL);
    store.dispatch(action);
  }, [setCashedURL]);

  const hasCashedURL = useSafeCallback((): boolean => {
    return !!cashedURL;
  }, [cashedURL]);

  const openCashedURL = useSafeCallback((): void => {
    window.location.href = cashedURL;
  }, [cashedURL]);

  const removeCashedURL = useSafeCallback((): void => {
    setCashedURL(EMPTY);
  }, [setCashedURL]);

  return {
    saveCurrentURL,
    hasCashedURL,
    openCashedURL,
    removeCashedURL
  } as P;
}

export default useCashedURL;

import { convert, RequestType } from '@atomica.co/utils';
import {
  CatchFrontendErrorRequest,
  CatchFrontendErrorResponse,
  CATCH_FRONTEND_ERROR,
  toFuncName
} from '@atomica.co/yosemal';
import { functions } from '../firebase';

export default class ErrorRequest {
  public static throwError = async (request: CatchFrontendErrorRequest): Promise<CatchFrontendErrorResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(CATCH_FRONTEND_ERROR));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}

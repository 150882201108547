import { URL } from '@atomica.co/utils';
import { PreviousURLActionEnum } from '../../enums/action-enum';
import { PreviousURLAction } from '../../models/action-model';

export const toPreviousURLAction = (previousURL: URL): PreviousURLAction => {
  return {
    type: PreviousURLActionEnum.SET,
    previousURL
  };
};

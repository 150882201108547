import { Component, FooterArea, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { UserEntity } from '@atomica.co/yosemal';
import React, { useEffect } from 'react';
import useUser from '../../redux/hooks/useUser';
import FooterTabs from './parts/FooterTabs';

interface P {}

const Footer: React.FC<P> = React.memo(() => {
  const { getUser } = useUser();
  const unmountRef = useUnmountRef();
  const [user, setUser] = useSafeState<UserEntity | undefined>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser();
    setUser(user);
  }, [getUser, setUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className='footer'>
      <FooterArea>
        <FooterTabs user={user} />
      </FooterArea>
    </Component>
  );
});

export default Footer;

import { Component, MultiInputBox, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, Email } from '@atomica.co/utils';
import { BoardId, SaveNewBoardMembersRequest } from '@atomica.co/yosemal';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useImperativeHandle } from 'react';
import styled from 'styled-components';
import BoardMemberRequest from '../../../../requests/board-member-request';

export interface InputEmailsRef {
  sendInvitations(): Promise<void>;
  clearEmails(): void;
}

interface P {
  boardId: BoardId | undefined;
  userId: UserId;
}

const InputEmailsToInvite: React.ForwardRefExoticComponent<P & React.RefAttributes<InputEmailsRef>> = React.forwardRef<
  InputEmailsRef,
  P
>((props, ref) => {
  const { boardId, userId } = props;
  const unmountRef = useUnmountRef();
  const [emailsToInvite, setEmailsToInvite] = useSafeState<Email[]>(unmountRef, []);

  const setInitialValues = useSafeCallback((): void => {
    setEmailsToInvite([]);
  }, [setEmailsToInvite]);

  const sendInvitations = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;

    const request = builder<SaveNewBoardMembersRequest>()
      .boardId(boardId)
      .emailsToInvite(emailsToInvite)
      .invitingUserId(userId)
      .build();

    await BoardMemberRequest.saveNewMembers(request);
  }, [boardId, emailsToInvite, userId]);

  useImperativeHandle(ref, () => ({
    sendInvitations: async () => await sendInvitations(),
    clearEmails: setInitialValues
  }));

  return (
    <Component className='invite-emails-to-invite'>
      <InputWrapper>
        <MultiInputBox
          style={styleForInputText}
          placeholder='招待したい方のメールアドレスを入力してください'
          emails={emailsToInvite}
          onChange={setEmailsToInvite}
        />
      </InputWrapper>
    </Component>
  );
});

export default InputEmailsToInvite;

const InputWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing}px);
  height: auto;
  border: 1px solid ${theme.mixins.border.lightGray};
  border-radius: 16px;
  padding: ${theme.mixins.spacing}px;
  margin: ${theme.mixins.spacing}px;
`;

const styleForInputText: CSSProperties = {
  color: theme.mixins.typography.fontColor.gray,
  fontSize: theme.mixins.typography.fontSize.twelve,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily
};

import { Component, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { BeriefHistory, builder, Email, EMPTY, SelfIntroduction, URL } from '@atomica.co/utils';
import { FetchUserProfileRequest, FrameId, ProfileEntity, SaveUserPhotoRequest, UserEntity } from '@atomica.co/yosemal';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import UserRequest from '../../../../requests/user-request';
import { AccountAction } from '../../AccountScreen';
import EditProfileMain from './parts/EditProfileMain';
import InputBeriefHistory from './parts/InputBeriefHistory';
import InputEmail from './parts/InputEmail';
import InputFacebookURL from './parts/InputFacebookURL';
import InputInstagramURL from './parts/InputInstagramURL';
import InputSelfIntroduction from './parts/InputSelfIntroduction';
import InputTwitterURL from './parts/InputTwitterURL';
import SelectUserFrame from './parts/SelectUserFrame';

export enum EditProfileIndex {
  EDIT_PROFILE_MAIN = 'edit_profile_main',
  SELECT_USER_FRAME = 'select_user_frame',
  INPUT_SELF_INTRODUCTION = 'input_self_introduction',
  INPUT_BERIEF_HISTORY = 'input_berief_history',
  INPUT_EMAIL = 'input_email',
  INPUT_TWITTER_URL = 'input_twitter_url',
  INPUT_FACEBOOK_URL = 'input_facebook_url',
  INPUT_INSTAGRAM_URL = 'input_instagram_url'
}

interface P {
  userId: UserId | undefined;
  signInUser: UserEntity | undefined;
  emitAction(action: AccountAction): void;
}

const EditProfile: React.FC<P> = React.memo(props => {
  const { userId, signInUser, emitAction } = props;
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<EditProfileIndex>(unmountRef, EditProfileIndex.EDIT_PROFILE_MAIN);
  const [prevProfile, setPrevProfile] = useSafeState<ProfileEntity | undefined>(unmountRef);

  const [savedPhotoURL, setSavedPhotoURL] = useSafeState<URL>(unmountRef);
  const [savedFrameId, setSavedFrameId] = useSafeState<FrameId | undefined>(unmountRef);
  const [savedSelfIntroduction, setSavedSelfIntroduction] = useSafeState<SelfIntroduction>(unmountRef);
  const [savedBeriefHistory, setSavedBeriefHistory] = useSafeState<BeriefHistory>(unmountRef);
  const [savedEmail, setSavedEmail] = useSafeState<Email>(unmountRef);
  const [savedTwitterURL, setSavedTwitterURL] = useSafeState<URL>(unmountRef);
  const [savedFacebookURL, setSavedFacebookURL] = useSafeState<URL>(unmountRef);
  const [savedInstagramURL, setSavedInstagramURL] = useSafeState<URL>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!userId || !signInUser || userId !== signInUser.userId) {
      return;
    }

    const signInUserId = signInUser.userId;
    const profileRequest = builder<FetchUserProfileRequest>()
      .userIdToDisplay(signInUserId)
      .requestedUserId(signInUserId)
      .build();

    const { profile } = await UserRequest.fetchProfile(profileRequest);
    if (!profile) return;

    setSavedPhotoURL(profile.photoURL);
    setSavedFrameId(profile.frameId);
    setSavedSelfIntroduction(!!profile.selfIntroduction ? profile.selfIntroduction : EMPTY);
    setSavedBeriefHistory(!!profile.beriefHistory ? profile.beriefHistory : EMPTY);
    setSavedEmail(profile.email);
    setSavedTwitterURL(!!profile.twitterURL ? profile.twitterURL : EMPTY);
    setSavedFacebookURL(!!profile.facebookURL ? profile.facebookURL : EMPTY);
    setSavedInstagramURL(!!profile.instagramURL ? profile.instagramURL : EMPTY);
    setPrevProfile(profile);
  }, [
    signInUser,
    userId,
    setSavedPhotoURL,
    setSavedFrameId,
    setSavedSelfIntroduction,
    setSavedBeriefHistory,
    setSavedEmail,
    setSavedTwitterURL,
    setSavedFacebookURL,
    setSavedInstagramURL,
    setPrevProfile
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const saveUserPhoto = useSafeCallback(
    async (photoURL: URL): Promise<void> => {
      if (!userId) return;

      const request = builder<SaveUserPhotoRequest>().userId(userId).photoURL(photoURL).build();

      await UserRequest.saveUserPhoto(request);
      setSavedPhotoURL(photoURL);
    },
    [userId, setSavedPhotoURL]
  );

  return (
    <Component className='edit-profile'>
      <Container>
        {index === EditProfileIndex.EDIT_PROFILE_MAIN && (
          <EditProfileMain
            frameId={savedFrameId}
            selfIntroduction={savedSelfIntroduction}
            beriefHistory={savedBeriefHistory}
            email={savedEmail}
            twitterURL={savedTwitterURL}
            facebookURL={savedFacebookURL}
            instagramURL={savedInstagramURL}
            profile={prevProfile}
            onUploadPhoto={saveUserPhoto}
            onClick={setIndex}
            emitAction={emitAction}
          />
        )}

        {index === EditProfileIndex.SELECT_USER_FRAME && (
          <SelectUserFrame
            frameId={savedFrameId}
            user={builder<UserEntity>()
              .userId(!!prevProfile ? prevProfile.userId : EMPTY)
              .photoURL(savedPhotoURL)
              .frameId(savedFrameId as FrameId)
              .familyName(!!prevProfile ? prevProfile.familyName : EMPTY)
              .firstName(!!prevProfile ? prevProfile.firstName : EMPTY)
              .dateOfBirth(!!prevProfile ? prevProfile.dateOfBirth : new Date())
              .build()}
            onSave={frameId => {
              setSavedFrameId(frameId);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_SELF_INTRODUCTION && (
          <InputSelfIntroduction
            userId={userId}
            selfIntroduction={savedSelfIntroduction}
            onSave={selfIntroduction => {
              setSavedSelfIntroduction(selfIntroduction);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_BERIEF_HISTORY && (
          <InputBeriefHistory
            userId={userId}
            beriefHistory={savedBeriefHistory}
            onSave={beriefHistory => {
              setSavedBeriefHistory(beriefHistory);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_EMAIL && (
          <InputEmail
            email={savedEmail}
            onSave={email => {
              setSavedEmail(email);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_TWITTER_URL && (
          <InputTwitterURL
            userId={userId}
            twitterURL={savedTwitterURL}
            onSave={twitterURL => {
              setSavedTwitterURL(twitterURL);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_FACEBOOK_URL && (
          <InputFacebookURL
            userId={userId}
            facebookURL={savedFacebookURL}
            onSave={facebookURL => {
              setSavedFacebookURL(facebookURL);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === EditProfileIndex.INPUT_INSTAGRAM_URL && (
          <InputInstagramURL
            userId={userId}
            instagramURL={savedInstagramURL}
            onSave={instagramURL => {
              setSavedInstagramURL(instagramURL);
              setIndex(EditProfileIndex.EDIT_PROFILE_MAIN);
            }}
            goTo={setIndex}
          />
        )}
      </Container>
    </Component>
  );
});

export default EditProfile;

const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  background: ${theme.mixins.background.lightGray};
`;

import { Component, Skeleton, theme } from '@atomica.co/components';
import { Count, EMPTY, hasLength, Height, Index, Width } from '@atomica.co/utils';
import { BoardEntity, UserEntity } from '@atomica.co/yosemal';
import { CSSProperties } from '@material-ui/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import UserPolaroid from './UserPolaroid';

type Size = 'large' | 'medium' | 'small';

interface P {
  size: Size;
  width: Width;
  height: Height;
  board: BoardEntity | undefined;
}

const BoardPhoto: React.FC<P> = React.memo(props => {
  const { size, width, height, board } = props;
  const showBoardPhoto = useMemo(() => !board || (!!board && !!board.photoURL), [board]);
  const showMemberPhotos = useMemo(() => !!board && !board.photoURL, [board]);

  return (
    <Component className='board-photo'>
      {showBoardPhoto && (
        <Skeleton
          animation='wave'
          variant='rect'
          style={getStyleForSkelton(width, height)}
          src={!!board ? board.photoURL! : EMPTY}
        />
      )}

      {showMemberPhotos && (
        <PhotosArea width={width} height={height}>
          {hasLength(board!.membersForThumbnail) &&
            board!.membersForThumbnail
              .filter((member: UserEntity) => !!member.userId)
              .map((member: UserEntity, index: Index) => (
                <CardWrapper
                  key={index}
                  index={index}
                  count={board!.membersForThumbnail.length}
                  width={width}
                  height={height}
                >
                  <UserPolaroid
                    size={size === 'large' ? 'xmedium' : 'small'}
                    rotate='none'
                    frameId={member.frameId}
                    user={member}
                  />
                </CardWrapper>
              ))}
        </PhotosArea>
      )}
    </Component>
  );
});

export default BoardPhoto;

const getStyleForSkelton = (width: Width, height: Height): CSSProperties => {
  return {
    width,
    height,
    background: theme.mixins.background.lightGray,
    border: theme.mixins.border.lightGray,
    boxShadow: `inset ${theme.mixins.shadow.light}`,
    objectFit: 'contain'
  };
};

const PhotosArea = styled.div<{ width: Width; height: Height }>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background: linear-gradient(180deg, #f3f3f3 0%, #ccc 100%);
  border: ${theme.mixins.border.lightGray};
  box-shadow: inset ${theme.mixins.shadow.light};
  position: relative;
`;

const CardWrapper = styled.div<{
  count: Count;
  index: Index;
  width: Width;
  height: Height;
}>`
  position: absolute;
  ${props => {
    switch (props.index) {
      case 0:
        return `top: -4px; left: -2px;`;
      case 1:
        switch (props.count) {
          case 2:
            return `bottom: 4px; right: 0px;`;
          case 3:
            return `bottom: -2px; left: ${props.width / 5}px;`;
          case 4:
            return `bottom: -2px; left: -6px;`;
          default:
            return `bottom: -2px; left: -6px;`;
        }
      case 2:
        switch (props.count) {
          case 3:
            return `top: 4px; right: -2px;`;
          case 4:
            return `bottom: 4px; right: 8px;`;
          default:
            return `bottom: -2px; right: -2px;`;
        }
      case 3:
        switch (props.count) {
          case 4:
            return `top: -6px; right: -2px;`;
          default:
            return `top: ${props.height / 8}px; left: ${props.width / 4}px;`;
        }
      case 4:
        return `top: -6px; right: -4px;`;
    }
  }};
`;

import {
  Button,
  Component,
  Scrollable,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, EMPTY, URL } from '@atomica.co/utils';
import { FrameId, SaveNewUserRequest } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ArrowForwardButton } from '../../../components/buttons/ArrowForwardButton';
import UserPhotoUploader from '../../../components/polaroid/UserPhotoUploader';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import { toFullName } from '../../../utils/user-util';

interface P {
  request?: SaveNewUserRequest;
  onUploadUserPhoto(request: SaveNewUserRequest): void;
  onSelectUserFrame(): void;
  onClickSaveButton(): void;
}

const SaveAccountInfo: React.FC<P> = React.memo(props => {
  const { request, onUploadUserPhoto, onSelectUserFrame, onClickSaveButton } = props;
  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, true);

  useEffect(() => {
    const disabledSaveButton = !request || !request.email || !request.photoURL || !request.frameId;
    setDisabledSaveButton(disabledSaveButton);
  }, [setDisabledSaveButton, request]);

  const handleUserPhotoUploaded = useSafeCallback(
    (photoURL: URL): void => {
      if (!request) return;

      const updatedRequest = builder<SaveNewUserRequest>()
        .externalId(request.externalId)
        .familyName(request.familyName)
        .familyNameKana(request.familyNameKana)
        .firstName(request.firstName)
        .firstNameKana(request.firstNameKana)
        .email(request.email)
        .dateOfBirth(request.dateOfBirth)
        .providerId(request.providerId)
        .photoURL(photoURL)
        .frameId(request.frameId)
        .build();

      onUploadUserPhoto(updatedRequest);
    },
    [request, onUploadUserPhoto]
  );

  const handleSaveButtonClicked = useSafeCallback((): void => {
    setDisabledSaveButton(true);
    onClickSaveButton();
  }, [setDisabledSaveButton, onClickSaveButton]);

  return (
    <Component className='save-account-info'>
      <Container>
        <Scrollable>
          <UploaderWrapper>
            <UserPhotoUploader
              frameId={!!request && request.frameId ? request.frameId : FrameId.CHEERS_BLACK}
              name={!!request ? toFullName(request) : EMPTY}
              dateOfBirth={!!request ? request.dateOfBirth : new Date()}
              onUpload={handleUserPhotoUploaded}
            />
          </UploaderWrapper>

          <ArrowButtonWrapper>
            <ArrowForwardButton shape='rect' text='フレームを選択する' onClick={onSelectUserFrame} />
          </ArrowButtonWrapper>

          <CircleButtonWrapper>
            <Button disabled={disabledSaveButton} type='primary' onClick={handleSaveButtonClicked}>
              <Label>完成！</Label>
            </Button>
          </CircleButtonWrapper>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default SaveAccountInfo;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-flow: column;
`;

const UploaderWrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;

const ArrowButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const CircleButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const Label = styled(Typography)`
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

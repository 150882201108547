import { getMobileWidth, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Height, URL, ZERO } from '@atomica.co/utils';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import FixedModal from '../../../components/modal/FixedModal';

interface P {
  isModalOpen: boolean;
  photoURL?: URL;
  onClose(): void;
}

const BoardFixedModal: React.FC<P> = React.memo(props => {
  const { isModalOpen, photoURL, onClose } = props;
  const ref = useRef<HTMLDivElement>();
  const unmountRef = useUnmountRef();
  const [height, setHeight] = useSafeState<Height>(unmountRef);

  const initialize = useSafeCallback((): void => {
    if (!photoURL) return;
    const img = new Image();
    img.src = photoURL;
    const height = ref.current ? ref.current.getBoundingClientRect().height + theme.mixins.spacing : ZERO;
    img.onload = () => setHeight(height);
  }, [photoURL, setHeight]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <FixedModal isOpen={isModalOpen} height={height} onClose={onClose}>
      <Photo ref={ref} src={photoURL} />
    </FixedModal>
  );
});

export default BoardFixedModal;

const Photo = styled.img`
  width: ${getMobileWidth() - theme.mixins.spacing * 2}px;
  height: auto;
`;

import { Button, CommentBox, Component, Scrollable, theme } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import UserPolaroid from '../../../components/polaroid/UserPolaroid';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import { MOJACO } from '../../../constants/mojaco-constants';
import mojaco_greeting from './../../../assets/mojaco/mojaco_greeting_large.png';

const MESSAGE = '入力ありがとう！\n僕はモジャ公といいます！\nまずはプロフィール写真を設定しよう！';

interface P {
  onNext(): void;
}

const ShowSamplePolaroid: React.FC<P> = React.memo(props => {
  const { onNext } = props;

  return (
    <Component className='show-sample-polaroid'>
      <Container>
        <Scrollable>
          <BoxWrapper>
            <CommentBox photoURL={mojaco_greeting}>
              <Greeting>{MESSAGE}</Greeting>
            </CommentBox>
          </BoxWrapper>

          <PolaroidWrapper>
            <UserPolaroid size='large' rotate='none' frameId={MOJACO.frameId} user={MOJACO} />
          </PolaroidWrapper>

          <ButtonWrapper>
            <Button type='primary' onClick={onNext}>
              <Label>OK！</Label>
            </Button>
          </ButtonWrapper>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default ShowSamplePolaroid;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-flow: column;
`;

const BoxWrapper = styled.div`
  padding: ${theme.mixins.spacing}px;
`;

const Greeting = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  white-space: pre-wrap;
`;

const PolaroidWrapper = styled.div`
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const Label = styled(Typography)`
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

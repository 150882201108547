import { useSafeCallback } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { BoardId } from '@atomica.co/yosemal';
import React from 'react';
import FullModal from '../../../components/modal/FullModal';
import { BoardAction, BoardIndex } from '../BoardScreen';
import BoardSettings from './board-settings/BoardSettings';
import CreateBoard from './create-board/CreateBoard';

interface P {
  isModalOpen: boolean;
  action: BoardAction;
  userId: UserId;
  boardId: BoardId | undefined;
  emitAction(action: BoardAction): void;
  onClose(index: BoardIndex): void;
}

const BoardFullModal: React.FC<P> = React.memo(props => {
  const { isModalOpen, action, userId, boardId, emitAction, onClose } = props;

  const handleModalClosed = useSafeCallback(() => {
    switch (action) {
      case BoardAction.CREATE_BOARD:
        onClose(BoardIndex.BOARD_LIST);
        return;

      case BoardAction.OPEN_BOARD_SETTINGS:
        onClose(BoardIndex.BOARD_DETAILS);
        return;

      default:
        throw new Error(`${action} is out of target.`);
    }
  }, [action, onClose]);

  return (
    <FullModal isOpen={isModalOpen} onClose={handleModalClosed}>
      {action === BoardAction.CREATE_BOARD && (
        <CreateBoard
          userId={userId}
          onSaveBoard={() => {
            emitAction(BoardAction.REFRESH_BOARD_LIST);
            onClose(BoardIndex.BOARD_LIST);
          }}
          onClose={() => onClose(BoardIndex.BOARD_LIST)}
        />
      )}

      {action === BoardAction.OPEN_BOARD_SETTINGS && (
        <BoardSettings
          userId={userId}
          boardId={boardId}
          onClose={() => {
            emitAction(BoardAction.REFRESH_BOARD_DETAILS);
            onClose(BoardIndex.BOARD_DETAILS);
          }}
        />
      )}
    </FullModal>
  );
});

export default BoardFullModal;

import { FrameId, UserEntity } from '@atomica.co/yosemal';
import mojaco from './../assets/mojaco/mojaco_greeting_medium.png';

export const MOJACO: UserEntity = {
  familyName: '嶋田',
  firstName: 'もじゃこう',
  dateOfBirth: new Date('2000-01-01'),
  frameId: FrameId.THANKYOU_BLACK,
  photoURL: mojaco
} as UserEntity;

import {
  Button,
  CheckBox,
  Component,
  DateBox,
  InputBox,
  Scrollable,
  SingleLineText,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { ProviderId } from '@atomica.co/types';
import { builder, Email, EMPTY, Name } from '@atomica.co/utils';
import { FrameId, SaveNewUserRequest } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Logo from '../../../components/logo/Logo';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import firebase from '../../../firebase';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import Remarks from './Remarks';

interface P {
  firebase: firebase.User | null;
  onNext(request: SaveNewUserRequest): void;
}

const InputAccountInfoWithoutPassword: React.FC<P> = React.memo(props => {
  const { firebase, onNext } = props;
  const { openPath } = usePath();
  const unmountRef = useUnmountRef();
  const [isTermOfUseAgreed, setIsTermOfUseAgreed] = useSafeState<boolean>(unmountRef, false);
  const [emailToSave, setEmailToSave] = useSafeState<Email>(unmountRef, EMPTY);
  const [familyNameToSave, setFamilyNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [familyNameKanaToSave, setFamilyNameKanaToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstNameToSave, setFirstNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstNameKanaToSave, setFirstNameKanaToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [dateOfBirthToSave, setDateOfBirthToSave] = useSafeState<Date>(unmountRef, new Date('2000-01-01'));

  const initialize = useSafeCallback(async () => {
    const firebaseSignedInByGoogle = !!firebase
      ? firebase.providerData.find(data => !!data && data.providerId === 'google.com')
      : undefined;

    const email =
      !!firebaseSignedInByGoogle && !!firebaseSignedInByGoogle.email ? firebaseSignedInByGoogle.email : undefined;

    if (!email) {
      openPath(Path.SIGN_OUT);
      return;
    }

    setEmailToSave(email);
  }, [firebase, openPath, setEmailToSave]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const disabledNextButton = useMemo<boolean>(() => {
    return !isTermOfUseAgreed || !emailToSave || !familyNameToSave || !firstNameToSave || !dateOfBirthToSave;
  }, [isTermOfUseAgreed, emailToSave, familyNameToSave, firstNameToSave, dateOfBirthToSave]);

  const handleNextButtonClicked = useSafeCallback(async (): Promise<void> => {
    if (!firebase) return;

    const user = builder<SaveNewUserRequest>()
      .externalId(firebase.uid)
      .familyName(familyNameToSave)
      .familyNameKana(familyNameKanaToSave)
      .firstName(firstNameToSave)
      .firstNameKana(firstNameKanaToSave)
      .email(emailToSave)
      .dateOfBirth(dateOfBirthToSave)
      .providerId(ProviderId.GOOGLE)
      .frameId(FrameId.NONE)
      .build();

    onNext(user);
  }, [
    firebase,
    familyNameToSave,
    familyNameKanaToSave,
    firstNameToSave,
    firstNameKanaToSave,
    emailToSave,
    dateOfBirthToSave,
    onNext
  ]);

  return (
    <Component className='input-account-info-without-password'>
      <Container>
        <Scrollable>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <EmailWrapper>
            <SingleLineText style={styleForEmail} text={emailToSave} />
          </EmailWrapper>

          <FullNameWrapper>
            <NameWrapper>
              <InputBox maxLength={15} label='姓' text={familyNameToSave} onChange={setFamilyNameToSave} />
            </NameWrapper>

            <NameWrapper>
              <InputBox maxLength={15} label='名' text={firstNameToSave} onChange={setFirstNameToSave} />
            </NameWrapper>
          </FullNameWrapper>

          <FullNameWrapper>
            <NameWrapper>
              <InputBox
                maxLength={15}
                label='姓（ふりがな）'
                text={familyNameKanaToSave}
                onChange={setFamilyNameKanaToSave}
              />
            </NameWrapper>

            <NameWrapper>
              <InputBox
                maxLength={15}
                label='名（ふりがな）'
                text={firstNameKanaToSave}
                onChange={setFirstNameKanaToSave}
              />
            </NameWrapper>
          </FullNameWrapper>

          <InputWrapper>
            <DateBox label='生年月日' value={dateOfBirthToSave} onChange={setDateOfBirthToSave} />
          </InputWrapper>

          <Agreement>
            <CheckBox checked={isTermOfUseAgreed} onChange={() => setIsTermOfUseAgreed(checked => !checked)}>
              <Remarks />
            </CheckBox>
          </Agreement>

          <ButtonWrapper>
            <Button type='primary' disabled={disabledNextButton} onClick={handleNextButtonClicked}>
              <Label>次へ</Label>
            </Button>
          </ButtonWrapper>
        </Scrollable>
      </Container>
    </Component>
  );
});

export default InputAccountInfoWithoutPassword;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const EmailWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const styleForEmail: CSSProperties = {
  color: theme.mixins.typography.fontColor.black,
  fontSize: theme.mixins.typography.fontSize.twenty,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily,
  textAlign: 'center'
};

const FullNameWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const NameWrapper = styled.div`
  width: calc(50% - ${theme.mixins.spacing / 2}px);
  height: 56px;
`;

const Agreement = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const Label = styled(Typography)`
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

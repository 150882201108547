import { UserId } from '@atomica.co/types';
import { Height, ZERO } from '@atomica.co/utils';
import { BoardId } from '@atomica.co/yosemal';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import ExpandableModal from '../../../components/modal/ExpandableModal';
import { BoardAction } from '../BoardScreen';
import InviteNewMembers from './invite-members/InviteNewMembers';
import ShareBoard from './share-board/ShareBoard';

const toHeight = (action: BoardAction): Height => {
  switch (action) {
    case BoardAction.INVITE_MEMBERS:
      return window.innerHeight * 0.9;

    case BoardAction.SHOW_QR:
      return 464;

    default:
      return ZERO;
  }
};

interface P {
  boardId: BoardId | undefined;
  userId: UserId;
  isModalOpen: boolean;
  action: BoardAction;
  onClose(): void;
}

const BoardPartialModal: React.FC<P> = React.memo(props => {
  const { boardId, userId, isModalOpen, action, onClose } = props;
  const modalHeight = useMemo(() => toHeight(action), [action]);

  return (
    <ExpandableModal isOpen={isModalOpen} modalHeight={modalHeight} onClose={onClose}>
      <Container height={modalHeight}>
        {action === BoardAction.INVITE_MEMBERS && (
          <InviteNewMembers boardId={boardId} userId={userId} onClose={onClose} />
        )}

        {action === BoardAction.SHOW_QR && <ShareBoard boardId={boardId} onClose={onClose} />}
      </Container>
    </ExpandableModal>
  );
});

export default BoardPartialModal;

const Container = styled.div<{ height: Height }>`
  width: 100%;
  height: ${props => props.height}px;
`;

import {
  CheckButton,
  Component,
  getMobileWidth,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Index, noop, Width } from '@atomica.co/utils';
import { BoardEntity, FrameId } from '@atomica.co/yosemal';
import { default as React, useMemo } from 'react';
import styled from 'styled-components';
import BoardPolaroid from './BoardPolaroid';

interface P {
  selectedFrameId: FrameId | undefined;
  board: BoardEntity | undefined;
  onChange(frameId: FrameId): void;
}

const BoardFrameSelecter: React.FC<P> = React.memo(props => {
  const { selectedFrameId: initSelectedFrameId, board, onChange } = props;
  const unmountRef = useUnmountRef();
  const width = useMemo(() => getMobileWidth(), []);
  const [selectedFrameId, setSelectedFrameId] = useSafeState<FrameId | undefined>(unmountRef, initSelectedFrameId);

  const handleFrameSelected = useSafeCallback(
    (frameId: FrameId): void => {
      setSelectedFrameId(frameId);
      onChange(frameId);
    },
    [setSelectedFrameId, onChange]
  );

  return (
    <Component className='board-frame-selecter'>
      {Object.values(FrameId).map((frameId: FrameId, index: Index) => (
        <PolaroidWrapper key={index} width={width} onClick={() => handleFrameSelected(frameId)}>
          <BoardPolaroid board={{ ...board, frameId } as BoardEntity} />

          <ButtonWrapper width={width}>
            <CheckButton checked={frameId === selectedFrameId} onClick={noop} />
          </ButtonWrapper>
        </PolaroidWrapper>
      ))}
    </Component>
  );
});

export default BoardFrameSelecter;

const PolaroidWrapper = styled.div<{ width: Width }>`
  width: ${props => props.width}px;
  height: ${props => props.width + theme.mixins.spacing * 2}px;
  position: relative;
  padding: ${theme.mixins.spacing * 2}px;
`;

const ButtonWrapper = styled.div<{ width: Width }>`
  width: 40px;
  height: auto;
  position: absolute;
  top: ${props => props.width / 2}px;
  left: ${props => props.width / 2 - theme.mixins.spacing * 4}px;
`;

import { Component, Scrollable, theme } from '@atomica.co/components';
import { Email } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';
import Logo from '../../../../components/logo/Logo';
import { HEADER_HEIGHT } from '../../../../constants/common-constants';
import EmailSignUp from './parts/EmailSignUp';
import GoogleSignUp from './parts/GoogleSignUp';

export enum SignUpEnum {
  INPUT_EMAIL,
  EMAIL_SENT
}

interface P {
  onSendEmail(email: Email): void;
}

const SignUpOptionList: React.FC<P> = React.memo(props => {
  const { onSendEmail } = props;

  return (
    <Component className='sign-up-option-list'>
      <Container>
        <Scrollable>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <EmailSignUp onSendEmail={onSendEmail} />

          <GoogleSignUp />

          {/* <LineSignUp /> */}
        </Scrollable>
      </Container>
    </Component>
  );
});

export default SignUpOptionList;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

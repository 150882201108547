import { Component } from '@atomica.co/components';
import { Email } from '@atomica.co/utils';
import { SaveNewUserRequest } from '@atomica.co/yosemal';
import React from 'react';
import firebase from '../../../firebase';
import InputAccountInfoWithoutPassword from './InputAccountInfoWithoutPassword';
import InputAccountInfoWithPassword from './InputAccountInfoWithPassword';

interface P {
  email: Email;
  firebase: firebase.User | null;
  onNext(request: SaveNewUserRequest): void;
}

const InputAccountSwitcher: React.FC<P> = React.memo(props => {
  const { email, firebase, onNext } = props;

  return (
    <Component className='input-account-switcher'>
      {!!email && <InputAccountInfoWithPassword email={email} onNext={onNext} />}

      {!email && <InputAccountInfoWithoutPassword firebase={firebase} onNext={onNext} />}
    </Component>
  );
});

export default InputAccountSwitcher;

import { Button, Component, Icon, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, embedIdInPath, URL } from '@atomica.co/utils';
import { BoardEntity, BoardId, DOMAINS, FetchBoardRequest } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import QRCodeReact from 'qrcode.react';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import env from '../../../../env/env';
import BoardRequest from '../../../../requests/board-request';
import { Path, PATH_IDS } from '../../../../router/Routes';
import share from './../../../../assets/icon/icon_share.png';

const MESSAGE = '知り合いがこのQRコードをスキャンすると\nこの寄せ書きに参加できます。';

interface P {
  boardId: BoardId | undefined;
  onClose(): void;
}

const ShareBoard: React.FC<P> = React.memo(props => {
  const { boardId, onClose } = props;
  const unmountRef = useUnmountRef();
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);

  const urlToShare = useMemo<URL | undefined>(() => {
    return !!board
      ? `https://${DOMAINS[env]}${embedIdInPath(Path.BOARD_MESSAGE, PATH_IDS, [board.boardId])}`
      : undefined;
  }, [board]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;

    const request = builder<FetchBoardRequest>().boardId(boardId).build();

    const response = await BoardRequest.fetchBoard(request);
    setBoard(response.board);
  }, [boardId, setBoard]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleShareButtonClicked = useSafeCallback((): void => {
    if (!board) return;

    navigator.share({
      title: board.name,
      text: board.description,
      url: urlToShare
    });
  }, [board, urlToShare]);

  return (
    <Component className='share-board'>
      <Header>
        <ButtonArea>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </ButtonArea>
      </Header>

      <QRCodeWrapper>{!!urlToShare && <QRCodeReact size={160} value={urlToShare} />}</QRCodeWrapper>

      <Message>{MESSAGE}</Message>

      <ButtonsWrapper>
        {!!navigator.share && (
          <Button onClick={handleShareButtonClicked}>
            <IconWrapper>
              <Icon size='small' src={share} />
              <Label>送る</Label>
            </IconWrapper>
          </Button>
        )}
      </ButtonsWrapper>
    </Component>
  );
});

export default ShareBoard;

const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px 0px;
`;

const ButtonArea = styled.div`
  width: 40px;
  height: 40px;
`;

const QRCodeWrapper = styled.div`
  width: 100%;
  height: 176px;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const Message = styled.div`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  white-space: pre;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const Label = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding-top: ${theme.mixins.spacing / 2}px;
`;

import {
  CircularLoader,
  Component,
  Scrollable,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, Count, Index, ZERO } from '@atomica.co/utils';
import { FetchCommonConnectingsRequest, UserEntity } from '@atomica.co/yosemal';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import UserCard from '../../../components/card/UserCard';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import ConnectionRequest from '../../../requests/connection-request';
import ConnectionListHeader from '../account-header/ConnectionListHeader';
import { AccountAction } from '../AccountScreen';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  userId: UserId | undefined;
  signInUser: UserEntity | undefined;
  onClickConnection(userId: UserId): void;
  emitAction(action: AccountAction): void;
}

const CommonConnectionList: React.FC<P> = React.memo(props => {
  const { userId, signInUser, onClickConnection, emitAction } = props;
  const bottomRef = useRef<HTMLDivElement>();
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [connections, setConnections] = useSafeState<UserEntity[]>(unmountRef, []);

  const loadConnections = useSafeCallback(async (): Promise<void> => {
    if (!hasMore || !userId || !signInUser) return;

    const request = builder<FetchCommonConnectingsRequest>()
      .userId(userId)
      .userIdToCompare(signInUser.userId)
      .limit(LIMIT)
      .offset(count.current)
      .build();

    const response = await ConnectionRequest.fetchCommonConnectings(request);
    const connectionsToAdd = response.connections;

    hasMore.current = connectionsToAdd.length === LIMIT;
    count.current += connectionsToAdd.length;
    setConnections(connections =>
      count.current === connectionsToAdd.length ? response.connections : [...connections, ...response.connections]
    );
    setLoaded(true);
  }, [userId, signInUser, setConnections, setLoaded]);

  useEffect(() => {
    loadConnections();
  }, [loadConnections]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadConnections();
      }
    },
    [loadConnections]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);

    bottomRef.current && observer.observe(bottomRef.current);
    return () => observer.disconnect();
  }, [loaded, onScroll]);

  return (
    <Component className='common-connection-list' style={{ width: '100%', height: window.innerHeight - HEADER_HEIGHT }}>
      <HeaderArea>
        <ConnectionListHeader label='共通の知り合い' emitAction={emitAction} />
      </HeaderArea>

      <Scrollable>
        <Container>
          {connections.map((connection: UserEntity, index: Index) => (
            <CardWrapper key={index} onClick={() => onClickConnection(connection.userId)}>
              <UserCard user={connection} />
            </CardWrapper>
          ))}

          {hasMore.current && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}

          <Bottom ref={bottomRef} />
        </Container>
      </Scrollable>
    </Component>
  );
});

export default CommonConnectionList;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing}px 0px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing * 1.5}px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 240px;
`;

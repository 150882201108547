import { Button, theme, useSafeCallback } from '@atomica.co/components';
import { builder } from '@atomica.co/utils';
import { CatchFrontendErrorRequest } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import mojaco from '../../assets/mojaco/mojaco_sorry.png';
import usePath from '../../redux/hooks/usePath';
import ErrorRequest from '../../requests/error-request';
import { Path } from '../../router/Routes';
import Screen from '../screen/Screen';

const Error: React.FC<FallbackProps> = React.memo(props => {
  const { error } = props;
  const { openPath } = usePath();

  const throwError = useSafeCallback(async (): Promise<void> => {
    const stack = !!error.stack ? error.stack : error.message;
    const request = builder<CatchFrontendErrorRequest>().stack(stack).build();
    await ErrorRequest.throwError(request);
  }, [error]);

  useEffect(() => {
    throwError();
  }, [throwError]);

  const openHomeScreen = useSafeCallback((): void => {
    openPath(Path.BOARD_LIST);
  }, [openPath]);

  return (
    <Screen className='error'>
      <Container>
        <MessagesWrapper>
          <Message>エラーが発生しました…！</Message>
          <Message>しばらくしてから</Message>
          <Message>もう一度お試しください。</Message>
        </MessagesWrapper>

        <Mojaco src={mojaco} />

        <ButtonWrapper>
          <Button type='secondary' onClick={openHomeScreen}>
            <Label>戻る</Label>
          </Button>
        </ButtonWrapper>
      </Container>
    </Screen>
  );
});

export default Error;

const Container = styled.div`
  width: 100%;
  height: auto;
`;

const MessagesWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

const Message = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
`;

const Mojaco = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px;
`;

// const Button = styled(MuiButton)`
//   width: 104px;
//   height: 48px;
//   border-radius: 24px;
//   color: ${theme.mixins.typography.fontColor.white};
//   background: ${theme.mixins.background.lightGray} !important;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
//   display: flex;
//   justify-content: flex-start;
//   padding: 0px ${theme.mixins.spacing * 3}px;
// `;

const Label = styled(Typography)`
  width: 96px;
  height: 32px;
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { Component, Scrollable, theme } from '@atomica.co/components';
import { BeriefHistory, Email, SelfIntroduction, URL } from '@atomica.co/utils';
import { FrameId, ProfileEntity } from '@atomica.co/yosemal';
import React from 'react';
import styled from 'styled-components';
import { ArrowForwardButton } from '../../../../../components/buttons/ArrowForwardButton';
import UserPhotoUploader from '../../../../../components/polaroid/UserPhotoUploader';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import { toFullName } from '../../../../../utils/user-util';
import EditProfileHeader from '../../../account-header/EditProfileHeader';
import { AccountAction } from '../../../AccountScreen';
import { EditProfileIndex } from '../EditProfile';
import addPhoto from './../../../../../assets/icon/icon_photo_add.png';

interface P {
  frameId: FrameId | undefined;
  selfIntroduction: SelfIntroduction;
  beriefHistory: BeriefHistory;
  email: Email;
  twitterURL: URL;
  facebookURL: URL;
  instagramURL: URL;
  profile: ProfileEntity | undefined;
  onUploadPhoto(photoURL: URL): void;
  onClick(index: EditProfileIndex): void;
  emitAction(action: AccountAction.BACK_TO_PROFILE);
}

const EditProfileMain: React.FC<P> = React.memo(props => {
  const {
    frameId,
    selfIntroduction,
    beriefHistory,
    twitterURL,
    facebookURL,
    instagramURL,
    profile,
    onUploadPhoto: onChangePhoto,
    onClick,
    emitAction
  } = props;

  return (
    <Component className='edit-profile-main'>
      <HeaderArea>
        <EditProfileHeader emitAction={emitAction} />
      </HeaderArea>

      <Container>
        <Scrollable>
          <PolaroidWrapper>
            {!!profile && !!frameId && (
              <UserPhotoUploader
                initPhotoURL={profile.photoURL}
                frameId={frameId}
                name={toFullName(profile)}
                dateOfBirth={profile.dateOfBirth}
                onUpload={onChangePhoto}
              />
            )}

            <AddPhotoButton>
              <AddPhotoIcon src={addPhoto} />
            </AddPhotoButton>
          </PolaroidWrapper>

          <LowerWrapper>
            <ArrowForwardButton
              shape='rect'
              text='フレームを選択する'
              onClick={() => onClick(EditProfileIndex.SELECT_USER_FRAME)}
            />
          </LowerWrapper>

          <UpperWrapper>
            <ArrowForwardButton
              shape='rect'
              label='自己紹介'
              text={selfIntroduction}
              onClick={() => onClick(EditProfileIndex.INPUT_SELF_INTRODUCTION)}
            />
          </UpperWrapper>

          <LowerWrapper>
            <ArrowForwardButton
              shape='rect'
              label='略歴'
              text={beriefHistory}
              onClick={() => onClick(EditProfileIndex.INPUT_BERIEF_HISTORY)}
            />
          </LowerWrapper>

          {/* <UpperWrapper>
            <ArrowButton
              shape='rect'
              label='メールアドレス'
              text={email}
              onClick={() => onClick(EditAccountInfoIndex.INPUT_EMAIL)}
            />
          </UpperWrapper> */}

          <UpperWrapper>
            <ArrowForwardButton
              shape='rect'
              label='Twiiter / @以降'
              text={twitterURL}
              onClick={() => onClick(EditProfileIndex.INPUT_TWITTER_URL)}
            />
          </UpperWrapper>

          <MiddleWrapper>
            <ArrowForwardButton
              shape='rect'
              label='Facebook / プロフィールのリンク'
              text={facebookURL}
              onClick={() => onClick(EditProfileIndex.INPUT_FACEBOOK_URL)}
            />
          </MiddleWrapper>

          <LowerWrapper>
            <ArrowForwardButton
              shape='rect'
              label='Instagram / プロフィールのリンク'
              text={instagramURL}
              onClick={() => onClick(EditProfileIndex.INPUT_INSTAGRAM_URL)}
            />
          </LowerWrapper>

          <FooterArea />
        </Scrollable>
      </Container>
    </Component>
  );
});

export default EditProfileMain;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Container = styled.div`
  width: 100%;
  height: ${window.innerHeight - HEADER_HEIGHT}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 4}px;
`;

const AddPhotoButton = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: ${theme.mixins.background.black};
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  bottom: 64px;
`;

const AddPhotoIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const UpperWrapper = styled.div`
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px ${theme.mixins.spacing / 4}px;
`;

const MiddleWrapper = styled.div`
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing}px;
`;

const LowerWrapper = styled.div`
  padding: ${theme.mixins.spacing / 4}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
`;

const FooterArea = styled.div`
  width: 100%;
  height: 240px;
`;

import { Modal, theme } from '@atomica.co/components';
import { Height } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';

interface P {
  isOpen: boolean;
  height?: Height;
  children: React.ReactNode;
  onClose(): void;
}

const FixedModal: React.FC<P> = React.memo(props => {
  const { isOpen, height, children, onClose } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Frame height={height}>{children}</Frame>
    </Modal>
  );
});

export default FixedModal;

const Frame = styled.div<{ height: Height }>`
  width: auto;
  height: ${props => props.height}px;
  background: ${theme.mixins.background.white};
  border-radius: 4px;
  padding: ${theme.mixins.spacing / 2}px;
  ${theme.mixins.userSelectDisabled};
`;

import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { getParams, getPath, getQueryParams, PathStr } from '@atomica.co/utils';
import { useEffect, useRef } from 'react';
import { Params, QueryParams } from '../../models/path-model';
import { Path, PATH_IDS } from '../../router/Routes';
import { toPreviousURLAction } from '../actions/previous-url-action';
import store from '../store';

const EVENT_NAME = 'pathchanged';

const IGNORE_DIRECTORIES: PathStr[] = ['connections', 'notifications'];

interface P {
  path: Path;
  params: Params;
  queryParams: QueryParams;
  openPath: (path: Path) => void;
  openPathInNewTab: (path: Path) => void;
  replacePath: (path: Path) => void;
  goBack: () => void;
}

function usePath() {
  const unmountRef = useUnmountRef();
  const prevPath = useRef<PathStr>(store.getState().previousURL);
  const currentPath = useRef<PathStr>(window.location.pathname);
  const [path, setPath] = useSafeState<Path>(
    unmountRef,
    getPath<Path>(Object.values(Path), PATH_IDS, currentPath.current)
  );
  const [params, setParams] = useSafeState<Params>(
    unmountRef,
    getParams<Params>(Object.values(Path), PATH_IDS, window.location.pathname)
  );
  const [queryParams, setQueryParams] = useSafeState<QueryParams>(
    unmountRef,
    getQueryParams<QueryParams>(window.location.search)
  );

  const updatePathInfo = useSafeCallback((): void => {
    const nextPathName = window.location.pathname;
    if (nextPathName === currentPath.current) return;

    const action = toPreviousURLAction(currentPath.current);
    store.dispatch(action);

    const paths = Object.values(Path);
    currentPath.current = nextPathName;

    const nextPath = getPath<Path>(paths, PATH_IDS, nextPathName);
    !!nextPath && setPath(nextPath);

    const nextParams = getParams<Params>(paths, PATH_IDS, window.location.pathname, IGNORE_DIRECTORIES);
    setParams(nextParams);

    const nextQueryParams = getQueryParams<QueryParams>(window.location.search);
    setQueryParams(nextQueryParams);
  }, [setPath, setParams, setQueryParams]);

  useEffect(() => {
    window.addEventListener(EVENT_NAME, updatePathInfo);
    return () => window.removeEventListener(EVENT_NAME, updatePathInfo);
  }, [updatePathInfo]);

  const openPath = useSafeCallback((path: Path): void => {
    if (!path) return;
    const action = toPreviousURLAction(currentPath.current);
    store.dispatch(action);
    window.location.href = `${window.location.origin}${path}`;
  }, []);

  const openPathInNewTab = useSafeCallback((path: Path): void => {
    if (!path) return;
    window.open(`${window.location.origin}${path}`, 'newtab');
  }, []);

  const replacePath = useSafeCallback((path: Path): void => {
    if (!path) return;
    window.history.replaceState('', '', path);
    window.dispatchEvent(new Event(EVENT_NAME));
  }, []);

  const goBack = useSafeCallback((): void => {
    !!prevPath.current && openPath(prevPath.current as Path);
  }, [openPath]);

  return {
    path,
    params,
    queryParams,
    openPath,
    openPathInNewTab,
    replacePath,
    goBack
  } as P;
}

export default usePath;

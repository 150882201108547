import { Component, Icon, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, EMPTY } from '@atomica.co/utils';
import { BoardEntity, BoardId, FetchBoardRequest } from '@atomica.co/yosemal';
import { CSSProperties } from '@material-ui/styles';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import BoardRequest from '../../../requests/board-request';
import { BoardAction } from '../BoardScreen';
import arrow_left from './../../../assets/icon/icon_arrow_left.png';

interface P {
  boardId: BoardId | undefined;
  emitAction(action: BoardAction): void;
}

const BoardThreadHeader: React.FC<P> = React.memo(props => {
  const { boardId, emitAction } = props;
  const unmountRef = useUnmountRef();
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;

    const request = builder<FetchBoardRequest>().boardId(boardId).build();
    const response = await BoardRequest.fetchBoard(request);

    setBoard(response.board);
  }, [boardId, setBoard]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Component className='board-thread-header'>
      <Header
        label={!!board ? board.name : EMPTY}
        leftChild={
          <IconsWrapper>
            <IconWrapper onClick={() => emitAction(BoardAction.BACK_TO_DETAILS)}>
              <Icon style={styleForIcon} src={arrow_left} />
            </IconWrapper>
          </IconsWrapper>
        }
        rightChild={
          <IconsWrapper>
            <IconWrapper />
          </IconsWrapper>
        }
      />
    </Component>
  );
});

export default BoardThreadHeader;

const IconsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  width: 24,
  height: 24
};

import React from 'react';
import { Path } from '../../../router/Routes';
import RegisterScreen from '../../register/RegisterScreen';
import SignOutScreen from '../../sign-out/SignOutScreen';
import SignUpInScreen from '../../sign-up-in/SignUpInScreen';

interface P {
  path: Path;
}

const NoGuardedScreens: React.FC<P> = React.memo(props => {
  const { path } = props;

  return (
    <>
      {(path === Path.SIGN_UP || path === Path.SIGN_IN) && <SignUpInScreen />}

      {path === Path.SIGN_OUT && <SignOutScreen />}

      {path === Path.REGISTER_ACCOUNT && <RegisterScreen />}
    </>
  );
});

export default NoGuardedScreens;

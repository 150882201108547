import { EMPTY, URL } from '@atomica.co/utils';
import { PreviousURLActionEnum } from '../../enums/action-enum';
import { PreviousURLAction } from '../../models/action-model';

const previousURLReducer = (previousURL: URL, action: PreviousURLAction): URL => {
  if (previousURL === undefined) return EMPTY;

  switch (action.type) {
    case PreviousURLActionEnum.SET:
      return action.previousURL;

    case PreviousURLActionEnum.PURGE:
      return EMPTY;

    default:
      return previousURL;
  }
};

export default previousURLReducer;

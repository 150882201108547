export enum ProcessActionEnum {
  SET = 'set_process',
  PURGE = 'persist/PURGE'
}

export enum PreviousURLActionEnum {
  SET = 'set_previous_url',
  PURGE = 'persist/PURGE'
}

export enum CashedURLActionEnum {
  SET = 'set_cashed_url',
  PURGE = 'persist/PURGE'
}

export enum UserActionEnum {
  SET = 'set_user',
  PURGE = 'persist/PURGE'
}

import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, embedIdInPath } from '@atomica.co/utils';
import { DeleteConnectingsRequest, UserEntity } from '@atomica.co/yosemal';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { HEADER_HEIGHT } from '../../constants/common-constants';
import { analytics } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import ConnectionRequest from '../../requests/connection-request';
import { Path, PATH_IDS } from '../../router/Routes';
import ConnectionFullModal from './connection-full-modal/ConnectionFullModal';
import ConnectionHeader from './connection-header/ConnectionHeader';
import SelectableConnectionHeader from './connection-header/SelectableConnectionHeader';
import ConnectionSwitcher, { ConnectionsRef } from './connection-switcher/ConnectionSwitcher';

export enum ConnectionIndex {
  CONNECTION_LIST,
  CONNECTION_CARDS,
  OUT_OF_TARGET
}

export enum ConnectionAction {
  ENABLE_SELECTION = 'enable_selection',
  DISABLE_SELECTION = 'disable_selection',
  CREATE_BOARD = 'create_board',
  REMOVE_CONNECTIONS = 'remove_connections',
  CLOSE_MODAL = 'close_modal'
}

interface P {}

const ConnectionsScreen: React.FC<P> = React.memo(() => {
  const { replacePath } = usePath();
  const { getUser } = useUser();
  const connectionsRef = useRef<ConnectionsRef>(null);
  const unmountRef = useUnmountRef();
  const [selectable, setSelectable] = useSafeState<boolean>(unmountRef, false);
  const [isFullModalOpen, setIsFullModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [index, setIndex] = useSafeState<ConnectionIndex>(unmountRef, ConnectionIndex.CONNECTION_CARDS);
  const [user, setUser] = useSafeState<UserEntity | undefined>(unmountRef);
  const [selected, setSelected] = useSafeState<UserEntity[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser();
    setUser(user);
  }, [getUser, setUser]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen('知り合い画面');
  }, [initialize]);

  const removeConnectings = useSafeCallback(
    async (selectedUsers: UserEntity[]): Promise<void> => {
      if (!user) return;

      const request = builder<DeleteConnectingsRequest>()
        .connectingIds(selectedUsers.map(user => user.userId))
        .connecterId(user.userId)
        .build();

      await ConnectionRequest.deleteConnectings(request);
      !!connectionsRef.current && (await connectionsRef.current.refresh());
      setSelectable(false);
    },
    [user, setSelectable]
  );

  const handleActionEmitted = useSafeCallback(
    async (action: ConnectionAction): Promise<void> => {
      switch (action) {
        case ConnectionAction.ENABLE_SELECTION:
          setSelectable(true);
          return;

        case ConnectionAction.DISABLE_SELECTION:
          setSelectable(false);
          setSelected([]);
          return;

        case ConnectionAction.CREATE_BOARD:
          setIsFullModalOpen(true);
          return;

        case ConnectionAction.REMOVE_CONNECTIONS:
          setSelected(selectedUsers => {
            removeConnectings(selectedUsers);
            return [];
          });
          return;

        case ConnectionAction.CLOSE_MODAL:
          setSelectable(false);
          setSelected([]);
          setIsFullModalOpen(false);
          return;

        default:
          throw new Error(`${action} is out of target.`);
      }
    },
    [setSelectable, setSelected, setIsFullModalOpen, removeConnectings]
  );

  const openAccountScreen = useSafeCallback(
    (userId: UserId): void => {
      replacePath(embedIdInPath(Path.ACCOUNT, PATH_IDS, [userId]));
    },
    [replacePath]
  );

  const handleConnectionClicked = useSafeCallback(
    (clickedUser: UserEntity): void => {
      setSelected(users => {
        const isSelected = !!users.find(user => user.userId === clickedUser.userId);
        return isSelected ? users.filter(user => user.userId !== clickedUser.userId) : [...users, clickedUser];
      });
    },
    [setSelected]
  );

  return (
    <Screen className='connections-screen'>
      <HeaderArea>
        {!selectable && <ConnectionHeader index={index} onChange={setIndex} />}

        {selectable && (
          <SelectableConnectionHeader
            selected={selected}
            onClickCloseButton={() => handleActionEmitted(ConnectionAction.DISABLE_SELECTION)}
            onClickCreateButton={() => handleActionEmitted(ConnectionAction.CREATE_BOARD)}
            onClickDeleteButton={() => handleActionEmitted(ConnectionAction.REMOVE_CONNECTIONS)}
          />
        )}
      </HeaderArea>

      <ConnectionSwitcher
        ref={connectionsRef}
        selectable={selectable}
        index={index}
        selectedConnections={selected}
        userId={!!user ? user.userId : undefined}
        onClickConnection={openAccountScreen}
        onSelectConnection={handleConnectionClicked}
        emitAction={handleActionEmitted}
      />

      {!!user && (
        <ConnectionFullModal
          isModalOpen={isFullModalOpen}
          selectedConnections={selected}
          userId={user.userId}
          emitAction={handleActionEmitted}
        />
      )}
    </Screen>
  );
});

export default ConnectionsScreen;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

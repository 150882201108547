import { Component, Icon, theme } from '@atomica.co/components';
import { CSSProperties } from '@material-ui/styles';
import React from 'react';
import styled from 'styled-components';
import Header from '../../../components/header/Header';
import { BoardAction } from '../BoardScreen';
import add_account from './../../../assets/icon/icon_account_add.png';
import arrow_left from './../../../assets/icon/icon_arrow_left.png';
import qr from './../../../assets/icon/icon_qr.png';

interface P {
  emitAction(action: BoardAction): void;
}

const BoardDetailsHeader: React.FC<P> = React.memo(props => {
  const { emitAction } = props;

  return (
    <Component className='board-details-header'>
      <Header
        leftChild={
          <IconsWrapper>
            <IconWrapper onClick={() => emitAction(BoardAction.BACK_TO_HOME)}>
              <Icon style={styleForIcon} src={arrow_left} />
            </IconWrapper>
          </IconsWrapper>
        }
        rightChild={
          <IconsWrapper>
            {/* <IconWrapper
              onClick={() => onClick(BoardHeaderAction.WISH)}
            >
              <Icon
                style={styleForIcon}
                src={add_comment}
              />
            </IconWrapper> */}

            <IconWrapper onClick={() => emitAction(BoardAction.INVITE_MEMBERS)}>
              <Icon style={styleForIcon} src={add_account} />
            </IconWrapper>

            <IconWrapper onClick={() => emitAction(BoardAction.SHOW_QR)}>
              <Icon style={styleForIcon} src={qr} />
            </IconWrapper>
          </IconsWrapper>
        }
      />
    </Component>
  );
});

export default BoardDetailsHeader;

const IconsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: auto;
  padding: ${theme.mixins.spacing}px;
`;

const styleForIcon: CSSProperties = {
  width: 24,
  height: 24
};

import { ASIA_NORTHEAST1, hasLength } from '@atomica.co/utils';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';
import { getFirebaseConfig } from './config';

if (!hasLength(firebase.apps)) {
  const config = getFirebaseConfig();
  firebase.initializeApp(config);
}

export default firebase;

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const storage = firebase.storage();

export const functions = firebase.app().functions(ASIA_NORTHEAST1);

export const performance = firebase.performance();

export const analytics = firebase.analytics();

export const database = firebase.database();

import { Component, styleForFullExpansion, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder } from '@atomica.co/utils';
import { BoardId, SaveNewBoardMembersRequest, UserEntity } from '@atomica.co/yosemal';
import React, { useImperativeHandle } from 'react';
import BoardMemberRequest from '../../../../requests/board-member-request';
import ConnectionList from './ConnectionList';

export interface SelectUsersRef {
  sendInvitations(): Promise<void>;
  clearSelectedConnections(): void;
}

interface P {
  boardId: BoardId | undefined;
  userId: UserId;
}

const SelectConnectionsToInvite: React.ForwardRefExoticComponent<P & React.RefAttributes<SelectUsersRef>> =
  React.forwardRef<SelectUsersRef, P>((props, ref) => {
    const { boardId, userId } = props;
    const unmountRef = useUnmountRef();
    const [selectedUserIds, setSelectedUserIds] = useSafeState<UserId[]>(unmountRef, []);

    const setInitialValues = useSafeCallback((): void => {
      setSelectedUserIds([]);
    }, [setSelectedUserIds]);

    const handleConnectionClicked = useSafeCallback(
      (newlyClickedConnection: UserEntity): void => {
        setSelectedUserIds(previouslySelectedUserIds => {
          const isSelected = !!previouslySelectedUserIds.find(
            previouslySelectedUserId => previouslySelectedUserId === newlyClickedConnection.userId
          );

          return isSelected
            ? previouslySelectedUserIds.filter(
                previouslySelectedUserId => previouslySelectedUserId !== newlyClickedConnection.userId
              )
            : [...previouslySelectedUserIds, newlyClickedConnection.userId];
        });
      },
      [setSelectedUserIds]
    );

    const sendInvitations = useSafeCallback(async (): Promise<void> => {
      if (!boardId || !selectedUserIds.length) return;

      const request = builder<SaveNewBoardMembersRequest>()
        .boardId(boardId)
        .userIdsToInvite(selectedUserIds)
        .invitingUserId(userId)
        .build();

      await BoardMemberRequest.saveNewMembers(request);
    }, [boardId, selectedUserIds, userId]);

    useImperativeHandle(ref, () => ({
      sendInvitations: async () => await sendInvitations(),
      clearSelectedConnections: setInitialValues
    }));

    return (
      <Component className='select-connections-to-invite' style={styleForFullExpansion}>
        <ConnectionList
          boardId={boardId}
          selectedUserIds={selectedUserIds}
          userId={userId}
          onClickConnection={handleConnectionClicked}
        />
      </Component>
    );
  });

export default SelectConnectionsToInvite;

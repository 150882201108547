import { AnimatedModal, getMobileWidth, theme } from '@atomica.co/components';
import { Height } from '@atomica.co/utils';
import React from 'react';
import styled from 'styled-components';

interface P {
  isOpen: boolean;
  modalHeight: Height;
  children: React.ReactNode;
  onClose(): void;
}

const ExpandableModal: React.FC<P> = React.memo(props => {
  const { isOpen, modalHeight, children, onClose } = props;

  return (
    <AnimatedModal isOpen={isOpen} height={modalHeight} onClose={onClose}>
      <Container>
        <Frame height={modalHeight}>{children}</Frame>
      </Container>
    </AnimatedModal>
  );
});

export default ExpandableModal;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  ${theme.mixins.userSelectDisabled};
`;

const Frame = styled.div<{ height: Height }>`
  width: ${getMobileWidth()}px;
  height: ${props => props.height}px;
  background: ${theme.mixins.background.white};
  border-radius: 24px 24px 0px 0px;
`;

import { convert, RequestType } from '@atomica.co/utils';
import {
  DeleteNotificationsRequest,
  DeleteNotificationsResponse,
  DELETE_NOTIFICATIONS,
  FetchNotificationsRequest,
  FetchNotificationsResponse,
  FETCH_NOTIFICATIONS,
  toFuncName
} from '@atomica.co/yosemal';
import { functions } from '../firebase';

export default class NotificationRequest {
  public static fetchNotifications = async (
    request: FetchNotificationsRequest
  ): Promise<FetchNotificationsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(FETCH_NOTIFICATIONS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };

  public static deleteNotifications = async (
    request: DeleteNotificationsRequest
  ): Promise<DeleteNotificationsResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(DELETE_NOTIFICATIONS));

      func(request)
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}

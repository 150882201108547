import { USER_ID } from '@atomica.co/types';
import { ID } from '@atomica.co/utils';
import { BOARD_ID, BOARD_MESSAGE_ID } from '@atomica.co/yosemal';

export const PATH_IDS: ID[] = [USER_ID, BOARD_ID, BOARD_MESSAGE_ID];

export enum Path {
  /** sign-up */
  SIGN_UP = '/signup',

  /** sign-in */
  SIGN_IN = '/signin',

  /** sign-out */
  SIGN_OUT = '/signout',

  /** board */
  BOARD_LIST = '/',
  BOARD_DETAILS = '/board/:boardId',
  BOARD_MESSAGE = '/board/:boardId/message/new',
  BOARD_THREAD = '/board/:boardId/message/:boardMessageId/thread/new',

  /** connections */
  CONNECTIONS = '/connections',

  /** notifications */
  NOTIFICATIONS = '/notifications',

  /** account */
  REGISTER_ACCOUNT = '/account/new',
  ACCOUNT = '/:userId'
}

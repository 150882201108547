import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, embedIdInPath } from '@atomica.co/utils';
import {
  Confirmation,
  CONFIRMED,
  DeleteNotificationsRequest,
  NotificationAction as NotificationDetailsScreen,
  NotificationEntity,
  toNewOverallNotificationsPath,
  UserEntity
} from '@atomica.co/yosemal';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import { HEADER_HEIGHT } from '../../constants/common-constants';
import { analytics, database } from '../../firebase';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import NotificationRequest from '../../requests/notification-request';
import { Path, PATH_IDS } from '../../router/Routes';
import NotificationHeader from './notification-header/NotificationHeader';
import SelectableNotificationHeader from './notification-header/SelectableNotificationHeader';
import NotificationList, { NotificationsRef } from './notification-list/NotificationList';

export enum NotificationAction {
  ENABLE_SELECTION = 'enable_selection',
  DISABLE_SELECTION = 'disable_selection',
  REMOVE_NOTIFICATIONS = 'remove_notifications'
}

interface P {}

const NotificationsScreen: React.FC<P> = React.memo(() => {
  const { path, replacePath } = usePath();
  const { getUser } = useUser();
  const notificationsRef = useRef<NotificationsRef>(null);
  const unmountRef = useUnmountRef();
  const [selectable, setSelectable] = useSafeState<boolean>(unmountRef, false);
  const [user, setUser] = useSafeState<UserEntity | undefined>(unmountRef);
  const [selectedNotifications, setSelectedNotifications] = useSafeState<NotificationEntity[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser();
    setUser(user);
  }, [getUser, setUser]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen('通知画面');
  }, [initialize]);

  const refreshIfNewNotificationsExisted = useSafeCallback(async (): Promise<void> => {
    if (!user) return;

    const ref = database.ref(toNewOverallNotificationsPath(user.userId));
    const snapshot = await ref.get();
    const confirmation: Confirmation | undefined = snapshot.val();
    const isUnconfirmed = !!confirmation && confirmation.isConfirmed === false;
    if (!isUnconfirmed) return;

    !!notificationsRef.current && notificationsRef.current.refresh();
    !!user && database.ref(toNewOverallNotificationsPath(user.userId)).set(CONFIRMED);
  }, [user]);

  useEffect(() => {
    if (path !== Path.NOTIFICATIONS) return;
    refreshIfNewNotificationsExisted();
  }, [path, refreshIfNewNotificationsExisted]);

  const removeNotifications = useSafeCallback(
    async (selected: NotificationEntity[]): Promise<void> => {
      if (!user) return;

      const request = builder<DeleteNotificationsRequest>()
        .notificationIds(selected.map(notification => notification.notificationId))
        .build();

      await NotificationRequest.deleteNotifications(request);
      !!notificationsRef.current && (await notificationsRef.current.refresh());
      setSelectable(false);
    },
    [user, setSelectable]
  );

  const handleActionEmitted = useSafeCallback(
    async (action: NotificationAction): Promise<void> => {
      switch (action) {
        case NotificationAction.ENABLE_SELECTION:
          setSelectable(true);
          return;

        case NotificationAction.DISABLE_SELECTION:
          setSelectable(false);
          setSelectedNotifications([]);
          return;

        case NotificationAction.REMOVE_NOTIFICATIONS:
          setSelectedNotifications(selectedUsers => {
            removeNotifications(selectedUsers);
            return [];
          });
          return;

        default:
          throw new Error(`${action} is out of target.`);
      }
    },
    [setSelectable, setSelectedNotifications, removeNotifications]
  );

  const openBoardScreen = useSafeCallback(
    (notification: NotificationEntity): void => {
      switch (notification.action) {
        case NotificationDetailsScreen.INVITE_MEMBER:
          replacePath(embedIdInPath(Path.BOARD_MESSAGE, PATH_IDS, [notification.boardId!]));
          return;

        case NotificationDetailsScreen.RECEIVE_MESSAGE:
          replacePath(embedIdInPath(Path.BOARD_DETAILS, PATH_IDS, [notification.boardId!]));
          return;

        case NotificationDetailsScreen.RECEIVE_THREAD:
          replacePath(
            embedIdInPath(Path.BOARD_THREAD, PATH_IDS, [notification.boardId!, notification.boardMessageId!])
          );
          return;
        default:
          throw new Error(`${notification.action} is out of target.`);
      }
    },
    [replacePath]
  );

  const handleNotificationClicked = useSafeCallback(
    (newlyClickedNotification: NotificationEntity): void => {
      setSelectedNotifications(previouslySelectedNotifications => {
        const isSelected = !!previouslySelectedNotifications.find(
          previouslySelectedNotification =>
            previouslySelectedNotification.notificationId === newlyClickedNotification.notificationId
        );

        return isSelected
          ? previouslySelectedNotifications.filter(
              previouslySelectedNotification =>
                previouslySelectedNotification.notificationId !== newlyClickedNotification.notificationId
            )
          : [...previouslySelectedNotifications, newlyClickedNotification];
      });
    },
    [setSelectedNotifications]
  );

  return (
    <Screen className='notifications-screen'>
      <HeaderArea>
        {!selectable && <NotificationHeader />}

        {selectable && (
          <SelectableNotificationHeader
            selectedNotifications={selectedNotifications}
            onClickCloseButton={() => handleActionEmitted(NotificationAction.DISABLE_SELECTION)}
            onClickDeleteButton={() => handleActionEmitted(NotificationAction.REMOVE_NOTIFICATIONS)}
          />
        )}
      </HeaderArea>

      <NotificationList
        ref={notificationsRef}
        selectable={selectable}
        selectedNotifications={selectedNotifications}
        userId={!!user ? user.userId : undefined}
        onClickNotification={openBoardScreen}
        onSelectNotification={handleNotificationClicked}
        emitAction={handleActionEmitted}
      />
    </Screen>
  );
});

export default NotificationsScreen;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

import {
  Button,
  Component,
  Scrollable,
  styleForFullExpansion,
  theme,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, URL } from '@atomica.co/utils';
import { BoardEntity, FrameId } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React } from 'react';
import styled from 'styled-components';
import BoardFrameSelecter from '../../../../../components/polaroid/BoardFrameSelecter';
import { CreateBoardIndex } from '../CreateBoard';

interface P {
  photoURL: URL | undefined;
  selectedFrameId: FrameId | undefined;
  onClickSaveButton(frameId: FrameId): void;
  goTo(index: CreateBoardIndex): void;
}

const SelectBoardFrame: React.FC<P> = React.memo(props => {
  const { photoURL, selectedFrameId: initSelectedFrameId, onClickSaveButton, goTo } = props;
  const unmountRef = useUnmountRef();
  const [selectedFrameId, setSelectedFrameId] = useSafeState<FrameId>(unmountRef, initSelectedFrameId);

  return (
    <Component style={styleForFullExpansion} className='select-board-frame'>
      <Header>
        <HeaderButton>
          <Button onClick={() => goTo(CreateBoardIndex.CREATE_BOARD_MAIN)}>
            <BackIcon />
          </Button>
        </HeaderButton>

        <HeaderLabel>フレームを選ぶ</HeaderLabel>

        <HeaderButton />
      </Header>

      <Body>
        <Scrollable>
          <BoardFrameSelecter
            selectedFrameId={selectedFrameId}
            board={builder<BoardEntity>().photoURL(photoURL!).build()}
            onChange={setSelectedFrameId}
          />

          <ButtonArea>
            <Button type='primary' onClick={() => onClickSaveButton(selectedFrameId)}>
              <Label>保存</Label>
            </Button>
          </ButtonArea>
        </Scrollable>
      </Body>
    </Component>
  );
});

export default SelectBoardFrame;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const HeaderButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const HeaderLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.yellow};
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;

const Label = styled.div`
  width: 80px;
  height: auto;
`;

import { Code, Token } from '@atomica.co/utils';
import { toFuncName, VerifyUserRequest, VerifyUserResponse, VERIFY_USER } from '@atomica.co/yosemal';
import axios from 'axios';
import qs from 'qs';
import { functions } from '../firebase';
import { getLineTokenBody } from '../line';
import { LINE_TOKEN_CONFIG, LINE_TOKEN_URL } from '../line/config';

export default class AuthRequest {
  public static fetchIdToken = async (code: Code): Promise<Token> => {
    const res = await axios.post(LINE_TOKEN_URL, qs.stringify(getLineTokenBody(code)), LINE_TOKEN_CONFIG);

    return res.data.id_token;
  };

  public static verifyUser = async (request: VerifyUserRequest): Promise<VerifyUserResponse> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(VERIFY_USER));

      func(request)
        .then((res): void => resolve(res.data))
        .catch(error => reject(error));
    });
  };
}

import {
  Button,
  Component,
  MultiInputBox,
  styleForFullExpansion,
  theme,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { Email } from '@atomica.co/utils';
import { Typography } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React } from 'react';
import styled from 'styled-components';
import { CreateBoardIndex } from '../CreateBoard';

interface P {
  input: Email[];
  onClickSaveButton(emails: Email[]): void;
  goTo(index: CreateBoardIndex): void;
}

const InputEmailsToInvite: React.FC<P> = React.memo(props => {
  const { input: initInput, onClickSaveButton, goTo } = props;

  const unmountRef = useUnmountRef();
  const [emails, setEmails] = useSafeState<Email[]>(unmountRef, initInput);

  return (
    <Component style={styleForFullExpansion} className='input-emails-to-invite'>
      <Header>
        <ButtonArea>
          <Button onClick={() => goTo(CreateBoardIndex.CREATE_BOARD_MAIN)}>
            <BackIcon />
          </Button>
        </ButtonArea>

        <LabelArea>メールアドレスで招待</LabelArea>

        <ButtonArea>
          <Button type='primary' shape='rect' onClick={() => onClickSaveButton(emails)}>
            <ButtonLabel>OK</ButtonLabel>
          </Button>
        </ButtonArea>
      </Header>

      <Body>
        <InputWrapper>
          <MultiInputBox
            style={styleForInputText}
            placeholder='招待したい方のメールアドレスを入力してください'
            emails={emails}
            onChange={setEmails}
          />
        </InputWrapper>
      </Body>
    </Component>
  );
});

export default InputEmailsToInvite;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px 0px;
`;

const ButtonArea = styled.div`
  min-width: 72px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const LabelArea = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const ButtonLabel = styled.div`
  width: 48px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.yellow};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 2}px;
`;

const styleForInputText: CSSProperties = {
  color: theme.mixins.typography.fontColor.gray,
  fontSize: theme.mixins.typography.fontSize.twelve,
  fontWeight: theme.mixins.typography.fontWeight.fourHundreds,
  fontFamily: theme.mixins.typography.fontFamily
};

import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, EMPTY, Name, Text } from '@atomica.co/utils';
import { SaveUserBeriefHistoryRequest } from '@atomica.co/yosemal';
import { default as React } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import UserRequest from '../../../../../requests/user-request';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  userId: UserId | undefined;
  beriefHistory: Text | undefined;
  onSave(beriefHistory: Text): void;
  goTo(index: EditProfileIndex): void;
}

const InputBeriefHistory: React.FC<P> = React.memo(props => {
  const { userId, beriefHistory: initBeriefHistory = EMPTY, onSave, goTo } = props;

  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [beriefHistoryToSave, setBeriefHistoryToSave] = useSafeState<Name>(unmountRef, initBeriefHistory);

  const saveBeriefHistory = useSafeCallback(async (): Promise<void> => {
    if (!userId) return;
    setDisabledSaveButton(true);

    const request = builder<SaveUserBeriefHistoryRequest>().userId(userId).beriefHistory(beriefHistoryToSave).build();

    await UserRequest.saveBeriefHistory(request);
    onSave(beriefHistoryToSave);
  }, [setDisabledSaveButton, userId, beriefHistoryToSave, onSave]);

  return (
    <Component style={styleForFullExpansion} className='input-berief-history'>
      <HeaderArea>
        <InputProfileHeader label='略歴' onClick={goTo} />
      </HeaderArea>

      <Body>
        <InputWrapper>
          <InputBox showCount multiline shape='rect' text={beriefHistoryToSave} onChange={setBeriefHistoryToSave} />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' disabled={disabledSaveButton} onClick={saveBeriefHistory}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default InputBeriefHistory;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;

import { EMPTY, URL } from '@atomica.co/utils';
import { FrameId } from '@atomica.co/yosemal';
import frame_cheers_black from './../assets/frame/frame_cheers_black.png';
import frame_cheers_white from './../assets/frame/frame_cheers_white.png';
import frame_hello_black from './../assets/frame/frame_hello_black.png';
import frame_hello_white from './../assets/frame/frame_hello_white.png';
import frame_lovely_black from './../assets/frame/frame_lovely_black.png';
import frame_lovely_white from './../assets/frame/frame_lovely_white.png';
import frame_thankyou_black from './../assets/frame/frame_thankyou_black.png';
import frame_thankyou_white from './../assets/frame/frame_thankyou_white.png';

export interface FramePos {
  width: string;
  marginTop: string;
  marginLeft: string;
  src: URL;
}

export const FRAME_POSITION_FOR_PORTRAIT_POLAROID: { [frameId: string]: FramePos } = {
  [FrameId.NONE]: {
    width: '0%',
    marginTop: '0%',
    marginLeft: '0%',
    src: EMPTY
  },
  [FrameId.CHEERS_BLACK]: {
    width: '90%',
    marginTop: '5%',
    marginLeft: '5%',
    src: frame_cheers_black
  },
  [FrameId.CHEERS_WHITE]: {
    width: '90%',
    marginTop: '5%',
    marginLeft: '5%',
    src: frame_cheers_white
  },
  [FrameId.HELLO_BLACK]: {
    width: '80%',
    marginTop: '5%',
    marginLeft: '10%',
    src: frame_hello_black
  },
  [FrameId.HELLO_WHITE]: {
    width: '80%',
    marginTop: '5%',
    marginLeft: '10%',
    src: frame_hello_white
  },
  [FrameId.LOVELY_BLACK]: {
    width: '50%',
    marginTop: '0%',
    marginLeft: '0%',
    src: frame_lovely_black
  },
  [FrameId.LOVELY_WHITE]: {
    width: '50%',
    marginTop: '0%',
    marginLeft: '0%',
    src: frame_lovely_white
  },
  [FrameId.THANKYOU_BLACK]: {
    width: '50%',
    marginTop: '5%',
    marginLeft: '45%',
    src: frame_thankyou_black
  },
  [FrameId.THANKYOU_WHITE]: {
    width: '50%',
    marginTop: '5%',
    marginLeft: '45%',
    src: frame_thankyou_white
  }
};

export const FRAME_POSITION_FOR_LANDSCAPE_POLAROID: { [frameId: string]: FramePos } = {
  [FrameId.NONE]: {
    width: '0%',
    marginTop: '0%',
    marginLeft: '0%',
    src: EMPTY
  },
  [FrameId.CHEERS_BLACK]: {
    width: '45%',
    marginTop: '2.5%',
    marginLeft: '2.5%',
    src: frame_cheers_black
  },
  [FrameId.CHEERS_WHITE]: {
    width: '45%',
    marginTop: '2.5%',
    marginLeft: '2.5%',
    src: frame_cheers_white
  },
  [FrameId.HELLO_BLACK]: {
    width: '40%',
    marginTop: '2.5%',
    marginLeft: '5%',
    src: frame_hello_black
  },
  [FrameId.HELLO_WHITE]: {
    width: '40%',
    marginTop: '2.5%',
    marginLeft: '5%',
    src: frame_hello_white
  },
  [FrameId.LOVELY_BLACK]: {
    width: '25%',
    marginTop: '0%',
    marginLeft: '0%',
    src: frame_lovely_black
  },
  [FrameId.LOVELY_WHITE]: {
    width: '25%',
    marginTop: '0%',
    marginLeft: '0%',
    src: frame_lovely_white
  },
  [FrameId.THANKYOU_BLACK]: {
    width: '25%',
    marginTop: '2.5%',
    marginLeft: '22.5%',
    src: frame_thankyou_black
  },
  [FrameId.THANKYOU_WHITE]: {
    width: '25%',
    marginTop: '2.5%',
    marginLeft: '22.5%',
    src: frame_thankyou_white
  }
};

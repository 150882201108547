import { Component } from '@atomica.co/components';
import { BoardId } from '@atomica.co/yosemal';
import React from 'react';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import { BoardAction } from '../BoardScreen';
import BoardDetailsHeader from './BoardDetailsHeader';
import BoardListHeader from './BoardListHeader';
import BoardThreadHeader from './BoardThreadHeader';

interface P {
  boardId: BoardId | undefined;
  emitAction(action: BoardAction): void;
}

const BoardHeader: React.FC<P> = React.memo(props => {
  const { boardId, emitAction } = props;
  const { path } = usePath();

  return (
    <Component className='board-header'>
      {path === Path.BOARD_LIST && <BoardListHeader />}

      {path === Path.BOARD_DETAILS && <BoardDetailsHeader emitAction={emitAction} />}

      {path === Path.BOARD_THREAD && <BoardThreadHeader boardId={boardId} emitAction={emitAction} />}
    </Component>
  );
});

export default BoardHeader;

import { Component, getMobileWidth, theme } from '@atomica.co/components';
import { Height, Width } from '@atomica.co/utils';
import { BoardEntity } from '@atomica.co/yosemal';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FramePos, FRAME_POSITION_FOR_PORTRAIT_POLAROID } from '../../constants/frame-constant';
import BoardPhoto from './BoardPhoto';

export type Rotate = 'right' | 'left';

interface P {
  board: BoardEntity | undefined;
}

const BoardPolaroid: React.FC<P> = React.memo(props => {
  const { board } = props;
  const photoWidth = useMemo<Width>(() => getMobileWidth() - theme.mixins.spacing * 8, []);
  const photoHeight = useMemo<Height>(() => photoWidth, [photoWidth]);
  const cardWidth = useMemo<Width>(() => photoWidth + theme.mixins.spacing * 4, [photoWidth]);
  const cardHeight = useMemo<Height>(() => photoHeight + theme.mixins.spacing * 6, [photoHeight]);
  const frame = useMemo<FramePos | undefined>(
    () => (!!board && !!board.frameId ? FRAME_POSITION_FOR_PORTRAIT_POLAROID[board.frameId] : undefined),
    [board]
  );

  return (
    <Component className='board-polaroid'>
      <Container height={cardHeight}>
        <Card width={cardWidth} height={cardHeight}>
          <BoardPhoto size='large' width={photoWidth} height={photoHeight} board={board} />
        </Card>

        {!!frame && <Stamp frame={frame} src={frame.src} />}
      </Container>
    </Component>
  );
});

export default BoardPolaroid;

const Container = styled.div<{ height: Height }>`
  width: 100%;
  height: ${props => props.height}px;
  position: relative;
`;

const Card = styled.div<{
  width: Width;
  height: Height;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background: ${theme.mixins.background.white};
  border: ${theme.mixins.border.lightGray};
  box-shadow: ${theme.mixins.shadow.light};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Stamp = styled.img<{ frame: FramePos }>`
  width: ${props => props.frame.width};
  position: absolute;
  top: ${props => props.frame.marginTop};
  left: ${props => props.frame.marginLeft};
  object-fit: contain;
  z-index: 10;
  ${theme.mixins.userSelectDisabled};
`;

import {
  Button,
  Component,
  Scrollable,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder } from '@atomica.co/utils';
import { BoardEntity, FrameId, SaveBoardPhotoRequest } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { default as React } from 'react';
import styled from 'styled-components';
import BoardFrameSelecter from '../../../../../components/polaroid/BoardFrameSelecter';
import BoardRequest from '../../../../../requests/board-request';

interface P {
  board: BoardEntity | undefined;
  onSaveBoardFrame(frameId: FrameId): void;
  goBack(): void;
}

const SelectBoardFrame: React.FC<P> = React.memo(props => {
  const { board, onSaveBoardFrame, goBack } = props;
  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [frameIdToSave, setFrameIdToSave] = useSafeState<FrameId | undefined>(
    unmountRef,
    !!board ? board.frameId : FrameId.CHEERS_BLACK
  );

  const saveBoardPhoto = useSafeCallback(async (): Promise<void> => {
    if (!board) return;
    setDisabledSaveButton(true);

    try {
      const request = builder<SaveBoardPhotoRequest>()
        .boardId(board.boardId)
        .photoURL(board.photoURL!)
        .frameId(frameIdToSave!)
        .build();

      await BoardRequest.saveBoardPhoto(request);
      onSaveBoardFrame(frameIdToSave!);
    } catch (e) {
      setDisabledSaveButton(false);
    }
  }, [board, setDisabledSaveButton, frameIdToSave, onSaveBoardFrame]);

  return (
    <Component style={styleForFullExpansion} className='select-board-frame'>
      <Header>
        <HeaderButton>
          <Button onClick={goBack}>
            <BackIcon />
          </Button>
        </HeaderButton>

        <HeaderLabel>フレームを選ぶ</HeaderLabel>

        <HeaderButton />
      </Header>

      <Body>
        <Scrollable>
          <BoardFrameSelecter selectedFrameId={frameIdToSave} board={board} onChange={setFrameIdToSave} />

          <ButtonArea>
            <Button disabled={disabledSaveButton} type='primary' onClick={saveBoardPhoto}>
              <Label>保存</Label>
            </Button>
          </ButtonArea>
        </Scrollable>
      </Body>
    </Component>
  );
});

export default SelectBoardFrame;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.mixins.spacing}px;
`;

const HeaderButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const BackIcon = styled(ArrowBackIosOutlinedIcon)`
  color: ${theme.mixins.typography.fontColor.lightGray};
`;

const HeaderLabel = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds} !important;
  font-family: ${theme.mixins.typography.fontFamily};
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${theme.mixins.background.lightGray};
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
  margin-bottom: ${theme.mixins.spacing * 20}px;
`;

const Label = styled.div`
  width: 80px;
  height: auto;
`;

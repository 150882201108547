import {
  Button,
  Component,
  getMobileWidth,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, noop, URL, Width } from '@atomica.co/utils';
import { BoardEntity, SaveBoardPhotoRequest } from '@atomica.co/yosemal';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import BoardRequest from '../../requests/board-request';
import PhotoRequest from '../../requests/photo-request';
import addPhoto from './../../assets/icon/icon_photo_add.png';
import BoardPolaroid from './BoardPolaroid';

interface P {
  isOwner: boolean | undefined;
  board: BoardEntity | undefined;
  onChange(photoURL: URL): void;
}

const BoardPhotoChanger: React.FC<P> = React.memo(props => {
  const { isOwner, board, onChange } = props;
  const width = useMemo(() => getMobileWidth(), []);
  const unmountRef = useUnmountRef();
  const [photoURL, setPhotoURL] = useSafeState<URL | undefined>(unmountRef);

  useEffect(() => {
    setPhotoURL(!!board ? board.photoURL : undefined);
  }, [setPhotoURL, board]);

  const handleBoardPhotoSelected = useSafeCallback(
    async (image): Promise<void> => {
      if (!board) return;
      const uploadedPhotoURL = await PhotoRequest.uploadBoardPhoto(image);

      const request = builder<SaveBoardPhotoRequest>()
        .boardId(board.boardId)
        .photoURL(uploadedPhotoURL)
        .frameId(board.frameId!)
        .build();

      await BoardRequest.saveBoardPhoto(request);
      setPhotoURL(uploadedPhotoURL);
      onChange(uploadedPhotoURL);
    },
    [board, setPhotoURL, onChange]
  );

  return (
    <Component className='board-photo-changer'>
      <UploadArea>
        {isOwner && (
          <Uploader
            type='file'
            accept='image/*'
            width={width}
            onChange={e => handleBoardPhotoSelected(e.target.files[0])}
          />
        )}

        <BoardPolaroid board={!!board ? ({ ...board, photoURL } as BoardEntity) : undefined} />

        {isOwner && (
          <ButtonWrapper>
            <Button onClick={noop}>
              <UploadIcon src={addPhoto} />
            </Button>
          </ButtonWrapper>
        )}
      </UploadArea>
    </Component>
  );
});

export default BoardPhotoChanger;

const UploadArea = styled.div`
  width: 100%;
  height: ${getMobileWidth() + theme.mixins.spacing / 4}px;
  position: relative;
  padding: ${theme.mixins.spacing * 2}px;
`;

const Uploader = styled.input<{ width: Width }>`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: ${props => props.width - theme.mixins.spacing * 2}px;
  opacity: 0;
  appearance: none;
  position: absolute;
  z-index: 10;
`;

const ButtonWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${theme.mixins.background.black};
  opacity: 0.6;
  position: absolute;
  right: 24px;
  bottom: 32px;
`;

const UploadIcon = styled.img``;

import { CheckButton, Component } from '@atomica.co/components';
import { noop } from '@atomica.co/utils';
import { FrameId, UserEntity } from '@atomica.co/yosemal';
import React from 'react';
import styled from 'styled-components';
import UserPolaroid, { Rotate, Size } from './UserPolaroid';

interface P {
  selected: boolean;
  size: Size;
  rotate?: Rotate;
  frameId: FrameId | undefined;
  user: UserEntity | undefined;
  onClick(user: UserEntity): void;
}

const SelectableUserPolaroid: React.FC<P> = React.memo(props => {
  const { selected, size, rotate = 'none', frameId, user, onClick } = props;

  return (
    <Component className='selectable-user-polaroid'>
      <Container onClick={() => !!user && onClick(user)}>
        <Card>
          <UserPolaroid size={size} rotate={rotate} frameId={frameId} user={user} />
        </Card>

        <ButtonWrapper>
          <CheckButton size='xsmall' checked={selected} onClick={noop} />
        </ButtonWrapper>
      </Container>
    </Component>
  );
});

export default SelectableUserPolaroid;

const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  position: relative;
`;

const Card = styled.div``;

const ButtonWrapper = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -6px;
  top: -8px;
`;

import { useSafeCallback } from '@atomica.co/components';
import { builder } from '@atomica.co/utils';
import { FetchUserByExternalIdRequest, UserEntity } from '@atomica.co/yosemal';
import firebase, { analytics, auth } from '../../firebase';
import UserRequest from '../../requests/user-request';

interface P {
  getFirebase: () => Promise<firebase.User | null>;
  existFirebase: () => Promise<boolean>;
  getUser: () => Promise<UserEntity | undefined>;
}

function useUser() {
  const getFirebase = useSafeCallback(async (): Promise<firebase.User | null> => {
    return await new Promise(resolve => {
      const unsubscribe = auth.onAuthStateChanged(firebase => {
        resolve(firebase);
        unsubscribe();
      });
    });
  }, []);

  const existFirebase = useSafeCallback(async (): Promise<boolean> => {
    const firebase = await getFirebase();
    return !!firebase && !!firebase.uid;
  }, [getFirebase]);

  const getUser = useSafeCallback(async (): Promise<UserEntity | undefined> => {
    const firebase = await getFirebase();
    if (!firebase) return undefined;

    const request = builder<FetchUserByExternalIdRequest>().externalId(firebase.uid).build();
    const response = await UserRequest.fetchUserByExternalId(request);
    const user = response.user;
    if (!user) return undefined;

    analytics.setUserId(user.userId);
    return user;
  }, [getFirebase]);

  return {
    existFirebase,
    getFirebase,
    getUser
  } as P;
}

export default useUser;

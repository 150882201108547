import {
  Button,
  Component,
  InputBox,
  styleForFullExpansion,
  theme,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, EMPTY, Name, SLASH, Text } from '@atomica.co/utils';
import { SaveUserInstagramURLRequest } from '@atomica.co/yosemal';
import { default as React } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../constants/common-constants';
import UserRequest from '../../../../../requests/user-request';
import InputProfileHeader from '../../../account-header/InputProfileHeader';
import { EditProfileIndex } from '../EditProfile';

interface P {
  userId: UserId | undefined;
  instagramURL: Text | undefined;
  onSave(instagramURL: Text): void;
  goTo(index: EditProfileIndex): void;
}

const InputInstagramURL: React.FC<P> = React.memo(props => {
  const { userId, instagramURL: initInstagramURL = EMPTY, onSave, goTo } = props;
  const unmountRef = useUnmountRef();
  const [disabledSaveButton, setDisabledSaveButton] = useSafeState<boolean>(unmountRef, false);
  const [instagramURLToSave, setInstagramURLToSave] = useSafeState<Name>(unmountRef, initInstagramURL);

  const saveInstagramURL = useSafeCallback(async (): Promise<void> => {
    if (!userId) return;
    setDisabledSaveButton(true);

    const dirs = instagramURLToSave.split(SLASH);
    const instagramId = !!dirs[dirs.length - 1] ? dirs[dirs.length - 1] : dirs[dirs.length - 2];

    const request = builder<SaveUserInstagramURLRequest>().userId(userId).instagramURL(instagramId).build();
    await UserRequest.saveInstagramURL(request);

    onSave(instagramId);
  }, [setDisabledSaveButton, userId, instagramURLToSave, onSave]);

  return (
    <Component style={styleForFullExpansion} className='input-instagram-url'>
      <HeaderArea>
        <InputProfileHeader label='Instagram / プロフィールのリンク' onClick={goTo} />
      </HeaderArea>

      <Body>
        <InputWrapper>
          <InputBox showCount shape='rect' text={instagramURLToSave} onChange={setInstagramURLToSave} />
        </InputWrapper>

        <ButtonArea>
          <Button type='primary' disabled={disabledSaveButton} onClick={saveInstagramURL}>
            <StyledLabel>保存</StyledLabel>
          </Button>
        </ButtonArea>
      </Body>
    </Component>
  );
});

export default InputInstagramURL;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 2}px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const StyledLabel = styled.div`
  width: 80px;
  height: auto;
`;

import { Component, FooterBox, useSafeCallback } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, EMPTY, Text } from '@atomica.co/utils';
import { BoardMessageId, BoardThreadStatus, SaveBoardThreadRequest } from '@atomica.co/yosemal';
import React from 'react';
import BoardThreadRequest from '../../../requests/board-thread-request';
import { BoardAction } from '../BoardScreen';

interface P {
  boardMessageId: BoardMessageId | undefined;
  userId: UserId;
  emitAction(action: BoardAction): void;
}

const BoardFooterInput: React.FC<P> = React.memo(props => {
  const { boardMessageId, userId, emitAction } = props;

  const saveNewThread = useSafeCallback(
    async (text: Text): Promise<void> => {
      if (!boardMessageId) return;

      const request = builder<SaveBoardThreadRequest>()
        .boardMessageId(boardMessageId)
        .status(BoardThreadStatus.SUBMITTED)
        .text(text)
        .userId(userId)
        .build();

      await BoardThreadRequest.saveNewThread(request);
      emitAction(BoardAction.REFRESH_BOARD_THREADS);
    },
    [boardMessageId, userId, emitAction]
  );

  return (
    <Component className='board-footer-input'>
      <FooterBox placeholder='メッセージへの返信' initText={EMPTY} onSubmit={saveNewThread} />
    </Component>
  );
});

export default BoardFooterInput;

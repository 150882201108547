import {
  Button,
  CircularLoader,
  Component,
  Icon,
  Pullable,
  Scrollable,
  theme,
  Tips,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { builder, Count, Index, isEven, ZERO } from '@atomica.co/utils';
import { BoardEntity, FetchBoardsRequest, UserEntity } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import styled from 'styled-components';
import BoardListPolaroid, { Rotate } from '../../../components/polaroid/BoardListPolaroid';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import usePath from '../../../redux/hooks/usePath';
import BoardRequest from '../../../requests/board-request';
import { Path } from '../../../router/Routes';
import { BoardAction } from '../BoardScreen';
import yosemal from './../../../assets/yosemal/yosemal_icon.png';

const LIMIT = 10;

const INITIAL_BOARDS: any[] = [undefined, undefined, undefined, undefined];

// const TIPS = '新しい出会いがあったら気軽にKNOTしてみよう。\n心が動いたら気軽にみんなで寄せ書いてみよう。\nKNOTごとに気軽にウィッシュをポストできるよ。';
const TIPS = '新しい出会いがあったら気軽にKNOTしてみよう。\n心が動いたら気軽にみんなで寄せ書いてみよう。';

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

export interface BoardListRef {
  refresh(): void;
}

interface P {
  user: UserEntity | undefined;
  onClickBoard(board: BoardEntity): void;
  emitAction(action: BoardAction): void;
}

const BoardList: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardListRef>> = React.forwardRef<
  BoardListRef,
  P
>((props, ref) => {
  const { user, onClickBoard, emitAction } = props;
  const { path } = usePath();
  const isOpen = useMemo(() => path === Path.BOARD_LIST, [path]);
  const bottomRef = useRef<HTMLDivElement>();
  const disabledLoadingBoards = useRef<boolean>(false);
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [boards, setBoards] = useSafeState<BoardEntity[]>(unmountRef, []);

  const loadBoards = useSafeCallback(async (): Promise<void> => {
    if (disabledLoadingBoards.current || !user || !hasMore) return;
    disabledLoadingBoards.current = true;

    const request = builder<FetchBoardsRequest>().userId(user.userId).limit(LIMIT).offset(count.current).build();

    const response = await BoardRequest.fetchBoards(request);
    const boardsToAdd = response.boards;

    hasMore.current = boardsToAdd.length === LIMIT;
    count.current += boardsToAdd.length;
    setBoards(boards => (count.current === boardsToAdd.length ? response.boards : [...boards, ...response.boards]));

    disabledLoadingBoards.current = false;
    setLoaded(true);
  }, [user, setBoards, setLoaded]);

  useEffect(() => {
    if (!isOpen) return;
    loadBoards();
  }, [isOpen, loadBoards]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      if (!isOpen) return;

      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadBoards();
      }
    },
    [isOpen, loadBoards]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    bottomRef.current && observer.observe(bottomRef.current);

    return () => observer.disconnect();
  }, [loaded, onScroll]);

  const refresh = useSafeCallback(async (): Promise<void> => {
    if (!isOpen) return;
    hasMore.current = true;
    count.current = ZERO;
    await loadBoards();
  }, [isOpen, loadBoards]);

  useImperativeHandle(ref, () => ({
    refresh: () => refresh()
  }));

  return (
    <Component className='board-list'>
      <HeaderArea />

      <Container>
        <Pullable onRefresh={refresh}>
          <Scrollable showScrollbar>
            <ButtonWrapper>
              <Button type='default' onClick={() => emitAction(BoardAction.CREATE_BOARD)}>
                <IconWrapper>
                  <Icon size='small' src={yosemal} />
                </IconWrapper>
                <Label>寄せ書きする</Label>
              </Button>
            </ButtonWrapper>

            <PolaroidArea>
              {(loaded ? boards : INITIAL_BOARDS).map((board: BoardEntity, index: Index) => (
                <PolaroidWrapper key={index} position={isEven(index) ? 'left' : 'right'}>
                  <BoardListPolaroid
                    rotate={isEven(index) ? 'left' : 'right'}
                    user={user}
                    board={board}
                    onClickBoard={() => onClickBoard(board)}
                  />
                </PolaroidWrapper>
              ))}
            </PolaroidArea>

            {hasMore.current && (
              <LoaderWrapper>
                <CircularLoader />
              </LoaderWrapper>
            )}

            <TipsWrapper>
              <Tips message={TIPS} />
            </TipsWrapper>

            <Bottom ref={bottomRef} />
          </Scrollable>
        </Pullable>
      </Container>
    </Component>
  );
});

export default BoardList;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing * 2}px;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Typography)`
  width: 112px;
  height: 32px;
  color: ${theme.mixins.typography.fontColor.black};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PolaroidArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: ${theme.mixins.spacing * 8}px;
`;

const PolaroidWrapper = styled.div<{ position: Rotate }>`
  width: auto;
  height: auto;
  margin-top: ${props => (props.position === 'right' ? '0px' : '-64px')};
`;

const TipsWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 5}px ${theme.mixins.spacing}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 72px;
`;

import { CircularLoader, Component, theme, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, Count, embedIdInPath, Index, isEven, URL, ZERO } from '@atomica.co/utils';
import {
  BoardId,
  BoardMessageEntity,
  BoardMessageId,
  CONFIRMED,
  FetchBoardMessagesRequest,
  toNewThreadNotificationsPath,
  UserEntity
} from '@atomica.co/yosemal';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import BoardMessagePolaroid, { ClickArea } from '../../../../components/polaroid/BoardMessagePolaroid';
import { database } from '../../../../firebase';
import usePath from '../../../../redux/hooks/usePath';
import BoardMessageRequest from '../../../../requests/board-message-request';
import { Path, PATH_IDS } from '../../../../router/Routes';
import BoardThreadList from './BoardThreadList';

const LIMIT = 10;

const INITIAL_MESSAGES: any[] = [undefined, undefined];

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

export interface BoardMessageListRef {
  refresh(): void;
}

interface P {
  boardId: BoardId;
  user: UserEntity | undefined;
  onClickPhoto(photoURL: URL): void;
  onClickReply(boardMessageId: BoardMessageId): void;
}

const BoardMessageList: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardMessageListRef>> =
  React.forwardRef<BoardMessageListRef, P>((props, ref) => {
    const { boardId, user, onClickPhoto, onClickReply } = props;
    const bottomRef = useRef<HTMLDivElement>();
    const hasMore = useRef<boolean>(true);
    const count = useRef<Count>(ZERO);
    const { replacePath } = usePath();
    const unmountRef = useUnmountRef();
    const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
    const [clickedBoardMessageIds, setClickedBoardMessageIds] = useSafeState<BoardMessageId[]>(unmountRef, []);
    const [messages, setMessages] = useSafeState<BoardMessageEntity[]>(unmountRef, []);

    const loadMessages = useSafeCallback(async (): Promise<void> => {
      if (!hasMore) return;

      const request = builder<FetchBoardMessagesRequest>().boardId(boardId).limit(LIMIT).offset(count.current).build();

      const response = await BoardMessageRequest.fetchMessages(request);
      const messagesToAdd = response.messages;

      hasMore.current = messagesToAdd.length === LIMIT;
      count.current += messagesToAdd.length;
      setMessages(messages => [...messages, ...response.messages]);
      setLoaded(true);
    }, [boardId, setLoaded, setMessages]);

    useEffect(() => {
      loadMessages();
    }, [loadMessages]);

    const onScroll = useSafeCallback(
      (entries: IntersectionObserverEntry[]): void => {
        for (const entry of entries) {
          if (!entry.isIntersecting) return;
          loadMessages();
        }
      },
      [loadMessages]
    );

    useEffect(() => {
      if (!loaded) return;

      const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);

      bottomRef.current && observer.observe(bottomRef.current);
      return () => observer.disconnect();
    }, [loaded, onScroll]);

    const handleMessageClicked = useSafeCallback(
      (newlyClickedBoardMessageId: BoardMessageId): void => {
        setClickedBoardMessageIds(previouslyBoardMessageIds => {
          const hasClicked = !!previouslyBoardMessageIds.find(
            previouslyClickedBoardMessageId => previouslyClickedBoardMessageId === newlyClickedBoardMessageId
          );

          return hasClicked
            ? previouslyBoardMessageIds.filter(
                previouslyClickedBoardMessageId => previouslyClickedBoardMessageId !== newlyClickedBoardMessageId
              )
            : [...previouslyBoardMessageIds, newlyClickedBoardMessageId];
        });
      },
      [setClickedBoardMessageIds]
    );

    const handlePolaroidClicked = useSafeCallback(
      (area: ClickArea, id: UserId | BoardMessageId) => {
        switch (area) {
          case ClickArea.USER:
            replacePath(embedIdInPath(Path.ACCOUNT, PATH_IDS, [id]));
            return;

          case ClickArea.USER_PHOTO:
            const message = messages.find(m => m.boardMessageId === id);
            !!message && onClickPhoto(message.photoURL);
            return;

          case ClickArea.MESSAGE:
          case ClickArea.THREAD_ICON:
            handleMessageClicked(id);
            !!user && database.ref(toNewThreadNotificationsPath(user.userId, id)).set(CONFIRMED);
            return;

          case ClickArea.REPLY_ICON:
            onClickReply(id);
            !!user && database.ref(toNewThreadNotificationsPath(user.userId, id)).set(CONFIRMED);
            return;
        }
      },
      [replacePath, messages, onClickPhoto, handleMessageClicked, onClickReply, user]
    );

    const refreshMessages = useSafeCallback(async (): Promise<void> => {
      const request = builder<FetchBoardMessagesRequest>().boardId(boardId).limit(count.current).offset(ZERO).build();

      const response = await BoardMessageRequest.fetchMessages(request);
      setMessages(response.messages);
    }, [boardId, setMessages]);

    useImperativeHandle(ref, () => ({
      refresh: () => refreshMessages()
    }));

    return (
      <Component className='board-message-list'>
        <MessageListWrapper>
          {(loaded ? messages : INITIAL_MESSAGES).map((message: BoardMessageEntity, index: Index) => (
            <MessageWrapper key={index}>
              <BoardMessagePolaroid
                showThread
                rotate={isEven(index) ? 'right' : 'left'}
                user={user}
                message={message}
                onClick={handlePolaroidClicked}
              />

              {!!message && !!clickedBoardMessageIds.find(o => o === message.boardMessageId) && (
                <ThreadListWrapper>
                  <BoardThreadList totalCount={message.threadCount} boardMessageId={message.boardMessageId} />
                </ThreadListWrapper>
              )}
            </MessageWrapper>
          ))}
        </MessageListWrapper>

        {hasMore.current && (
          <LoaderWrapper>
            <CircularLoader />
          </LoaderWrapper>
        )}

        <Bottom ref={bottomRef} />
      </Component>
    );
  });

export default BoardMessageList;

const MessageListWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const MessageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ThreadListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 0px ${theme.mixins.spacing * 4}px ${theme.mixins.spacing * 6}px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 72px;
`;

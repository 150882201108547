import { Button, Component, Icon, Scrollable, theme, Tips, useSafeCallback } from '@atomica.co/components';
import { BoardEntity } from '@atomica.co/yosemal';
import { Typography } from '@material-ui/core';
import QRCodeReact from 'qrcode.react';
import React from 'react';
import styled from 'styled-components';
import BoardDetailsPolaroid from '../../../../components/polaroid/BoardDetailsPolaroid';
import BoardMessagePreviewHeader from '../../board-header/BoardMessagePreviewHeader';
import { BoardAction } from '../../BoardScreen';
import { BoardMessageIndex } from '../BoardMessage';
import share from './../../../../assets/icon/icon_share.png';
import ShowBoardMembers, { BoardMembersRef } from './ShowBoardMembers';

const TIPS = '寄せ書きを書くと\nみんなの寄せ書きを見ることができるよ！';

interface P {
  board: BoardEntity | undefined;
  onClickWriteButton(index: BoardMessageIndex): void;
  emitAction(action: BoardAction): void;
  goBack(): void;
}

const ShowBoardPreview: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardMembersRef>> = React.forwardRef<
  BoardMembersRef,
  P
>((props, ref) => {
  const { board, onClickWriteButton, emitAction, goBack } = props;

  const handleShareButtonClicked = useSafeCallback((): void => {
    if (!board) return;

    navigator.share({
      title: board.name,
      text: board.description,
      url: window.location.href
    });
  }, [board]);

  return (
    <Component className='show-board-preview'>
      <BoardMessagePreviewHeader board={board} onClickWriteButton={onClickWriteButton} goBack={goBack} />

      <Scrollable showScrollbar>
        <Container>
          <PolaroidWrapper>
            <BoardDetailsPolaroid showIcons showDetails rotate='left' board={board} onClick={emitAction} />
          </PolaroidWrapper>

          <QRCodeWrapper>
            <QRCodeReact size={160} bgColor={theme.mixins.background.yellow} value={window.location.href} />
          </QRCodeWrapper>

          <ButtonWrapper>
            {!!navigator.share && (
              <Button onClick={handleShareButtonClicked}>
                <IconWrapper>
                  <Icon size='small' src={share} />
                  <Label>送る</Label>
                </IconWrapper>
              </Button>
            )}
          </ButtonWrapper>

          <TipsWrapper>
            <Tips message={TIPS} />
          </TipsWrapper>

          <CardListWrapper>
            <ShowBoardMembers ref={ref} boardId={!!board ? board.boardId : undefined} />
          </CardListWrapper>
        </Container>
      </Scrollable>
    </Component>
  );
});

export default ShowBoardPreview;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: ${theme.mixins.spacing}px;
`;

const PolaroidWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

const QRCodeWrapper = styled.div`
  width: 100%;
  height: 176px;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const Label = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding-top: ${theme.mixins.spacing / 2}px;
`;

const TipsWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 6}px;
`;

const CardListWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`;

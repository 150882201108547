import { theme, TransitionGroup, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { EMAIL_CONST } from '@atomica.co/utils';
import { SaveNewUserRequest } from '@atomica.co/yosemal';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../components/header/Header';
import Screen from '../../components/screen/Screen';
import { HEADER_HEIGHT } from '../../constants/common-constants';
import firebase, { analytics } from '../../firebase';
import useCashedURL from '../../redux/hooks/useCashedURL';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import UserRequest from '../../requests/user-request';
import { Path } from '../../router/Routes';
import InputAccountSwitcher from './input-account-info/InputAccountSwitcher';
import SaveAccountInfo from './save-account-info/SaveAccountInfo';
import SelectUserFrame from './select-user-frame/SelectUserFrame';
import ShowSamplePolaroid from './show-sample-polaroid/ShowSamplePolaroid';

export enum RegisterIndex {
  INPUT_ACCOUNT_INFO,
  SHOW_SAMPLE_PREVIEW,
  SAVE_ACCOUNT_INFO,
  SELECT_USER_FRAME
}

interface P {}

const RegisterScreen: React.FC<P> = React.memo(() => {
  const { queryParams, openPath } = usePath();
  const { getFirebase } = useUser();
  const { hasCashedURL, openCashedURL } = useCashedURL();
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [index, setIndex] = useSafeState<RegisterIndex>(unmountRef, RegisterIndex.INPUT_ACCOUNT_INFO);
  const [firebase, setFirebase] = useSafeState<firebase.User | null>(unmountRef);
  const [request, setRequest] = useSafeState<SaveNewUserRequest>(unmountRef);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const firebase = await getFirebase();
    setFirebase(firebase);
    setLoaded(true);
  }, [getFirebase, setFirebase, setLoaded]);

  useEffect(() => {
    initialize();
    analytics.setCurrentScreen('アカウント登録画面');
  }, [initialize]);

  const openShowSamplePreviewScreen = useSafeCallback((): void => {
    setIndex(RegisterIndex.SHOW_SAMPLE_PREVIEW);
  }, [setIndex]);

  const openSaveAccountInfoScreen = useSafeCallback((): void => {
    setIndex(RegisterIndex.SAVE_ACCOUNT_INFO);
  }, [setIndex]);

  const openSelectUserFrameScreen = useSafeCallback((): void => {
    setIndex(RegisterIndex.SELECT_USER_FRAME);
  }, [setIndex]);

  const saveNewUser = useSafeCallback(async (): Promise<void> => {
    await UserRequest.saveNewUser(request);
    hasCashedURL() ? openCashedURL() : openPath(Path.BOARD_LIST);
  }, [request, hasCashedURL, openCashedURL, openPath]);

  return (
    <Screen loading={!loaded} className='register-screen'>
      <Header label='プロフィールを設定しよう！' />

      <TransitionWrapper>
        <TransitionGroup
          index={index}
          components={[
            <InputAccountSwitcher
              email={queryParams[EMAIL_CONST]!}
              firebase={firebase}
              onNext={request => {
                setRequest(request);
                openShowSamplePreviewScreen();
              }}
            />,
            <ShowSamplePolaroid onNext={openSaveAccountInfoScreen} />,
            <SaveAccountInfo
              request={request}
              onUploadUserPhoto={setRequest}
              onSelectUserFrame={openSelectUserFrameScreen}
              onClickSaveButton={saveNewUser}
            />,
            <SelectUserFrame
              request={request}
              onSelectUserFrame={request => {
                setRequest(request);
                openSaveAccountInfoScreen();
              }}
            />
          ]}
        />
      </TransitionWrapper>
    </Screen>
  );
});

export default RegisterScreen;

const TransitionWrapper = styled.div`
  margin-top: ${HEADER_HEIGHT}px;
  ${theme.mixins.userSelectDisabled};
`;

import {
  CircularLoader,
  Component,
  Scrollable,
  styleForFullExpansion,
  theme,
  Tips,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, Count, hasLength, Index, ZERO } from '@atomica.co/utils';
import { BoardId, FetchNonBoardMembersRequest, UserEntity } from '@atomica.co/yosemal';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SelectableUserCard from '../../../../components/card/SelectableUserCard';
import BoardMemberRequest from '../../../../requests/board-member-request';

const NO_CONECTIONS_MESSAGE = '新しく人と繋がると\n寄せ書きに招待できるようになります。';

const LIMIT = 10;

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

interface P {
  boardId: BoardId | undefined;
  userId: UserId;
  selectedUserIds: UserId[];
  onClickConnection(user: UserEntity): void;
}

const ConnectionList: React.FC<P> = React.memo(props => {
  const { boardId, userId, selectedUserIds, onClickConnection } = props;
  const ref = useRef<HTMLDivElement>();
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [connections, setConnections] = useSafeState<UserEntity[]>(unmountRef, []);

  const loadConnections = useSafeCallback(async (): Promise<void> => {
    if (!hasMore || !boardId) return;

    const request = builder<FetchNonBoardMembersRequest>()
      .boardId(boardId)
      .userId(userId)
      .limit(LIMIT)
      .offset(count.current)
      .build();

    const response = await BoardMemberRequest.fetchNonMembers(request);
    const connectionsToAdd = response.connections;

    hasMore.current = connectionsToAdd.length === LIMIT;
    count.current += connectionsToAdd.length;
    setConnections(connections => [...connections, ...response.connections]);
    setLoaded(true);
  }, [boardId, userId, setLoaded, setConnections]);

  useEffect(() => {
    loadConnections();
  }, [loadConnections]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadConnections();
      }
    },
    [loadConnections]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    ref.current && observer.observe(ref.current);

    return () => observer.disconnect();
  }, [loaded, onScroll]);

  return (
    <Component loading={!loaded} style={styleForFullExpansion} className='connection-list'>
      {hasLength(connections) && (
        <Scrollable>
          {connections.map((connection: UserEntity, index: Index) => (
            <CardWrapper key={index}>
              <SelectableUserCard
                selected={!!selectedUserIds.find(id => id === connection.userId)}
                user={connection}
                onClick={onClickConnection}
              />
            </CardWrapper>
          ))}

          {hasMore.current && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}

          <Bottom ref={ref} />
        </Scrollable>
      )}

      {!hasLength(connections) && (
        <TipsWrapper>
          <Tips message={NO_CONECTIONS_MESSAGE} />
        </TipsWrapper>
      )}
    </Component>
  );
});

export default ConnectionList;

const CardWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing / 2}px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 240px;
`;

const TipsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { UserId } from '@atomica.co/types';
import { UserEntity } from '@atomica.co/yosemal';
import React from 'react';
import FullModal from '../../../components/modal/FullModal';
import CreateBoard from '../../board/board-full-modal/create-board/CreateBoard';
import { ConnectionAction } from '../ConnectionsScreen';

interface P {
  isModalOpen: boolean;
  selectedConnections: UserEntity[];
  userId: UserId;
  emitAction(action: ConnectionAction): void;
}

const ConnectionFullModal: React.FC<P> = React.memo(props => {
  const { isModalOpen, selectedConnections, userId, emitAction } = props;

  return (
    <FullModal isOpen={isModalOpen} onClose={() => emitAction(ConnectionAction.CLOSE_MODAL)}>
      <CreateBoard
        connectionsToInvite={selectedConnections}
        userId={userId}
        onSaveBoard={() => emitAction(ConnectionAction.CLOSE_MODAL)}
        onClose={() => emitAction(ConnectionAction.CLOSE_MODAL)}
      />
    </FullModal>
  );
});

export default ConnectionFullModal;

import { Button, Component, Icon, theme, useSafeCallback } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import QRCodeReact from 'qrcode.react';
import React from 'react';
import styled from 'styled-components';
import share from './../../../../assets/icon/icon_share.png';

const MESSAGE = '他の人がこのQRコードをスキャンすると\n知り合いに追加されます。';

interface P {
  onClose(): void;
}

const ShareAccount: React.FC<P> = React.memo(props => {
  const { onClose } = props;

  const handleShareButtonClicked = useSafeCallback((): void => {
    navigator.share({ url: window.location.href });
  }, []);

  return (
    <Component className='share-account'>
      <Header>
        <ButtonArea>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </ButtonArea>
      </Header>

      <QRCodeWrapper>
        <QRCodeReact size={160} value={window.location.href} />
      </QRCodeWrapper>

      <Message>{MESSAGE}</Message>

      <ButtonsWrapper>
        {!!navigator.share && (
          <Button onClick={handleShareButtonClicked}>
            <IconWrapper>
              <Icon size='small' src={share} />
              <LabelWrapper>送る</LabelWrapper>
            </IconWrapper>
          </Button>
        )}
      </ButtonsWrapper>
    </Component>
  );
});

export default ShareAccount;

const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing}px 0px;
`;

const ButtonArea = styled.div`
  width: 40px;
  height: 40px;
`;

const QRCodeWrapper = styled.div`
  width: 100%;
  height: 176px;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px;
`;

const Message = styled.div`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  white-space: pre;
  padding: ${theme.mixins.spacing * 2}px ${theme.mixins.spacing}px;
`;
const ButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const LabelWrapper = styled(Typography)`
  color: ${theme.mixins.typography.fontColor.lightGray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding-top: ${theme.mixins.spacing / 2}px;
`;

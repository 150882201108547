import { Component, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { builder, embedIdInPath, EMPTY, Message, Text, URL } from '@atomica.co/utils';
import {
  BoardEntity,
  BoardId,
  BoardMessageEntity,
  BoardMessageStatus,
  FetchBoardRequest,
  FrameId,
  SaveBoardMessageRequest,
  UserEntity
} from '@atomica.co/yosemal';
import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import usePath from '../../../redux/hooks/usePath';
import BoardMessageRequest from '../../../requests/board-message-request';
import BoardRequest from '../../../requests/board-request';
import { Path, PATH_IDS } from '../../../router/Routes';
import { BoardAction } from '../BoardScreen';
import BoardMessageMain from './parts/BoardMessageMain';
import BoardMessageWritten from './parts/BoardMessageWritten';
import InputBoardMessage from './parts/InputBoardMessage';
import SelectMessageFrame from './parts/SelectMessageFrame';
import { BoardMembersRef } from './parts/ShowBoardMembers';
import ShowBoardPreview from './parts/ShowBoardPreview';

export interface BoardMessageRef {
  refresh(): void;
}

export enum BoardMessageIndex {
  SHOW_BOARD_PREVIEW = 'show_board_preview',
  BOARD_MESSAGE_MAIN = 'board_message_main',
  SELECT_MESSAGE_FRAME = 'select_message_frame',
  INPUT_BOARD_MESSAGE = 'input_board_message',
  BOARD_MESSAGE_WRITTEN = 'board_message_written'
}

interface P {
  boardId: BoardId | undefined;
  user: UserEntity | undefined;
  emitAction(action: BoardAction): void;
  goBack(): void;
}

const BoardMessage: React.ForwardRefExoticComponent<P & React.RefAttributes<BoardMessageRef>> = React.forwardRef<
  BoardMessageRef,
  P
>((props, ref) => {
  const { boardId, user, emitAction, goBack } = props;
  const { path, openPath } = usePath();
  const isOpen = useMemo(() => path === Path.BOARD_MESSAGE, [path]);
  const boardMembersRef = useRef<BoardMembersRef>(null);
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<BoardMessageIndex>(unmountRef, BoardMessageIndex.SHOW_BOARD_PREVIEW);
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);

  /** The props of SaveBoardMessageRequest to save */
  const [photoURLToSave, setPhotoURLToSave] = useSafeState<URL>(unmountRef, !!user ? user.photoURL : undefined);
  const [frameIdToSave, setFrameIdToSave] = useSafeState<FrameId>(unmountRef, !!user ? user.frameId : undefined);
  const [messageToSave, setMessageToSave] = useSafeState<Message>(unmountRef, EMPTY);

  const loadBoard = useSafeCallback(async (): Promise<void> => {
    if (!boardId || !user) return;

    const request = builder<FetchBoardRequest>().boardId(boardId).userId(user.userId).build();
    const response = await BoardRequest.fetchBoard(request);
    const board = response.board;

    if (!!board && !board.isDraft) {
      openPath(embedIdInPath(Path.BOARD_DETAILS, PATH_IDS, [boardId]));
      return;
    }

    setBoard(response.board);
  }, [boardId, user, openPath, setBoard]);

  const setDefaultPhotoAndFrame = useSafeCallback(() => {
    if (!user) return;
    setPhotoURLToSave(user.photoURL);
    setFrameIdToSave(user.frameId);
  }, [setPhotoURLToSave, setFrameIdToSave, user]);

  useEffect(() => {
    if (!isOpen) return;
    loadBoard();
    setDefaultPhotoAndFrame();
  }, [isOpen, loadBoard, setDefaultPhotoAndFrame]);

  const saveMessage = useSafeCallback(async (): Promise<void> => {
    if (!board || !user) return;

    const request = builder<SaveBoardMessageRequest>()
      .boardId(board.boardId)
      .status(BoardMessageStatus.SUBMITTED)
      .frameId(frameIdToSave)
      .photoURL(photoURLToSave)
      .text(messageToSave)
      .userId(user.userId)
      .build();

    await BoardMessageRequest.saveNewMessage(request);
    setIndex(BoardMessageIndex.BOARD_MESSAGE_WRITTEN);
  }, [board, user, frameIdToSave, photoURLToSave, messageToSave, setIndex]);

  const refresh = useSafeCallback(async (): Promise<void> => {
    if (!isOpen) return;
    await loadBoard();
    !!boardMembersRef.current && boardMembersRef.current.refresh();
  }, [isOpen, loadBoard]);

  useImperativeHandle(ref, () => ({
    refresh: () => refresh()
  }));

  return (
    <Component className='board-message'>
      <Container>
        {index === BoardMessageIndex.SHOW_BOARD_PREVIEW && (
          <ShowBoardPreview
            ref={boardMembersRef}
            board={board}
            onClickWriteButton={setIndex}
            emitAction={emitAction}
            goBack={goBack}
          />
        )}

        {index === BoardMessageIndex.BOARD_MESSAGE_MAIN && (
          <BoardMessageMain
            photoURL={photoURLToSave}
            frameId={frameIdToSave}
            message={messageToSave}
            board={board}
            user={user}
            onClick={setIndex}
            onUpload={setPhotoURLToSave}
            onClickSaveButton={saveMessage}
          />
        )}

        {index === BoardMessageIndex.SELECT_MESSAGE_FRAME && (
          <SelectMessageFrame
            photoURL={photoURLToSave}
            frameId={frameIdToSave}
            onClickSaveButton={(frameId: FrameId) => {
              setFrameIdToSave(frameId);
              setIndex(BoardMessageIndex.BOARD_MESSAGE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === BoardMessageIndex.INPUT_BOARD_MESSAGE && (
          <InputBoardMessage
            label='あなたのコメント'
            message={messageToSave}
            onClickSaveButton={(text: Text) => {
              setMessageToSave(text);
              setIndex(BoardMessageIndex.BOARD_MESSAGE_MAIN);
            }}
            goTo={setIndex}
          />
        )}

        {index === BoardMessageIndex.BOARD_MESSAGE_WRITTEN && (
          <BoardMessageWritten
            boardId={boardId}
            newlyWrittenMessage={builder<BoardMessageEntity>()
              .frameId(frameIdToSave!)
              .photoURL(photoURLToSave!)
              .text(messageToSave)
              .userId(!!user ? user.userId : undefined!)
              .familyName(!!user ? user.familyName : EMPTY)
              .firstName(!!user ? user.firstName : EMPTY)
              .dateOfBirth(!!user ? user.dateOfBirth : undefined!)
              .build()}
          />
        )}
      </Container>
    </Component>
  );
});

export default BoardMessage;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  margin-top: ${HEADER_HEIGHT}px;
`;

import { Component, styleForFullExpansion, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, Description, Name, Request, URL } from '@atomica.co/utils';
import { BoardEntity, BoardId, FetchBoardRequest, FrameId } from '@atomica.co/yosemal';
import { default as React, useEffect, useMemo } from 'react';
import BoardRequest from '../../../../requests/board-request';
import BoardSettingsMain from './parts/BoardSettingsMain';
import InputBoardDescription from './parts/InputBoardDescription';
import InputBoardName from './parts/InputBoardName';
import InputRequestForNewPhoto from './parts/InputRequestForNewPhoto';
import LeaveBoard from './parts/LeaveBoard';
import SelectBoardFrame from './parts/SelectBoardFrame';
import ShowBoardMembers from './parts/ShowBoardMembers';

export enum BoardSettingsIndex {
  BOARD_SETTINGS_MAIN = 'board_settings_main',
  SELECT_BOARD_FRAME = 'select_board_frame',
  INPUT_BOARD_NAME = 'input_board_name',
  INPUT_BOARD_DESCRIPTION = 'input_board_description',
  INPUT_REQUEST_FOR_NEW_PHOTO = 'input_request_for_new_photo',
  SHOW_BOARD_MEMBERS = 'show_board_members',
  LEAVE_BOARD = 'leave_board'
}

interface P {
  userId: UserId;
  boardId: BoardId | undefined;
  onClose(): void;
}

const BoardSettings: React.FC<P> = React.memo(props => {
  const { userId, boardId, onClose } = props;
  const unmountRef = useUnmountRef();
  const [index, setIndex] = useSafeState<BoardSettingsIndex>(unmountRef, BoardSettingsIndex.BOARD_SETTINGS_MAIN);
  const [dueDate, setDueDate] = useSafeState<Date | undefined>(unmountRef); // FIXME
  const [board, setBoard] = useSafeState<BoardEntity | undefined>(unmountRef);

  const isOwner = useMemo((): boolean | undefined => {
    return !!board ? board.owner.userId === userId : undefined;
  }, [board, userId]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!boardId) return;

    const request = builder<FetchBoardRequest>().boardId(boardId).build();
    const response = await BoardRequest.fetchBoard(request);

    setDueDate(!!response.board ? response.board.dueDate : undefined);
    setBoard(response.board);
  }, [boardId, setDueDate, setBoard]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const goBackToMain = useSafeCallback((): void => {
    setIndex(BoardSettingsIndex.BOARD_SETTINGS_MAIN);
  }, [setIndex]);

  const handleBoardNameChanged = useSafeCallback(
    (name: Name): void => {
      setBoard(board => Object.assign(board, { name }));
      setIndex(BoardSettingsIndex.BOARD_SETTINGS_MAIN);
    },
    [setBoard, setIndex]
  );

  const handleBoardDescriptionChanged = useSafeCallback(
    (description: Description): void => {
      setBoard(board => Object.assign(board, { description }));
      setIndex(BoardSettingsIndex.BOARD_SETTINGS_MAIN);
    },
    [setBoard, setIndex]
  );

  const handleBoardPhotoChanged = useSafeCallback(
    (photoURL: URL): void => {
      setBoard(board => Object.assign(board, { photoURL }));
    },
    [setBoard]
  );

  const handleDueDateChanged = useSafeCallback(
    (dueDate: Date): void => {
      setDueDate(dueDate);
    },
    [setDueDate]
  );

  const handleIsRequiredNewPhotoChanged = useSafeCallback(
    (isRequiredNewPhoto: boolean): void => {
      setBoard(board => Object.assign(board, { isRequiredNewPhoto }));
    },
    [setBoard]
  );

  const handleRequestForNewPhotoChanged = useSafeCallback(
    (requestForNewPhoto: Request): void => {
      setBoard(board => Object.assign(board, { requestForNewPhoto }));
      setIndex(BoardSettingsIndex.BOARD_SETTINGS_MAIN);
    },
    [setBoard, setIndex]
  );

  const handleBoardFrameChanged = useSafeCallback(
    (frameId: FrameId): void => {
      setBoard(board => Object.assign(board, { frameId }));
      setIndex(BoardSettingsIndex.BOARD_SETTINGS_MAIN);
    },
    [setBoard, setIndex]
  );

  return (
    <Component style={styleForFullExpansion} className='board-settings'>
      {index === BoardSettingsIndex.BOARD_SETTINGS_MAIN && (
        <BoardSettingsMain
          isOwner={isOwner}
          userId={userId}
          dueDate={dueDate}
          board={board}
          onClick={setIndex}
          onSaveBoardPhoto={handleBoardPhotoChanged}
          onSaveDueDate={handleDueDateChanged}
          onSaveIsRequiredNewPhoto={handleIsRequiredNewPhotoChanged}
          onClose={onClose}
        />
      )}

      {index === BoardSettingsIndex.SELECT_BOARD_FRAME && (
        <SelectBoardFrame board={board} onSaveBoardFrame={handleBoardFrameChanged} goBack={goBackToMain} />
      )}

      {index === BoardSettingsIndex.INPUT_BOARD_NAME && (
        <InputBoardName board={board} onSaveBoardName={handleBoardNameChanged} goBack={goBackToMain} />
      )}

      {index === BoardSettingsIndex.INPUT_BOARD_DESCRIPTION && (
        <InputBoardDescription
          board={board}
          onSaveBoardDescription={handleBoardDescriptionChanged}
          goBack={goBackToMain}
        />
      )}

      {index === BoardSettingsIndex.INPUT_REQUEST_FOR_NEW_PHOTO && (
        <InputRequestForNewPhoto
          board={board}
          onSaveRequestForNewPhoto={handleRequestForNewPhotoChanged}
          goBack={goBackToMain}
        />
      )}

      {index === BoardSettingsIndex.SHOW_BOARD_MEMBERS && <ShowBoardMembers board={board} goBack={goBackToMain} />}

      {index === BoardSettingsIndex.LEAVE_BOARD && <LeaveBoard userId={userId} board={board} goBack={goBackToMain} />}
    </Component>
  );
});

export default BoardSettings;

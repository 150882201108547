import {
  CircularLoader,
  Component,
  Scrollable,
  theme,
  Tips,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, Count, Index, isEven, ZERO } from '@atomica.co/utils';
import { BoardEntity, BoardId, FetchCommonBoardsRequest, UserEntity } from '@atomica.co/yosemal';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import BoardListPolaroid, { Rotate } from '../../../components/polaroid/BoardListPolaroid';
import { HEADER_HEIGHT } from '../../../constants/common-constants';
import BoardRequest from '../../../requests/board-request';
import BoardListHeader from '../account-header/BoardListHeader';
import { AccountAction } from '../AccountScreen';

const LIMIT = 10;

const INITIAL_BOARDS: any[] = [undefined, undefined, undefined, undefined];

// const TIPS = '新しい出会いがあったら気軽にKNOTしてみよう。\n心が動いたら気軽にみんなで寄せ書いてみよう。\nKNOTごとに気軽にウィッシュをポストできるよ。';
const TIPS = '新しい出会いがあったら気軽にKNOTしてみよう。\n心が動いたら気軽にみんなで寄せ書いてみよう。';

const OPTIONS: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px 0px 300px 0px'
};

export interface BoardListRef {
  refresh(): void;
}

interface P {
  userId: UserId | undefined;
  signInUser: UserEntity | undefined;
  onClickBoard(boardId: BoardId): void;
  emitAction(action: AccountAction): void;
}

const CommonBoardList: React.FC<P> = React.memo(props => {
  const { userId, signInUser, onClickBoard, emitAction } = props;
  const bottomRef = useRef<HTMLDivElement>();
  const hasMore = useRef<boolean>(true);
  const count = useRef<Count>(ZERO);
  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [boards, setBoards] = useSafeState<BoardEntity[]>(unmountRef, []);

  const loadBoards = useSafeCallback(async (): Promise<void> => {
    if (!userId || !signInUser || !hasMore) return;

    const request = builder<FetchCommonBoardsRequest>()
      .userId(userId)
      .userIdToCompare(signInUser.userId)
      .limit(LIMIT)
      .offset(count.current)
      .build();

    const response = await BoardRequest.fetchCommonBoards(request);
    const boardsToAdd = response.boards;

    hasMore.current = boardsToAdd.length === LIMIT;
    count.current += boardsToAdd.length;
    setBoards(boards => (count.current === boardsToAdd.length ? response.boards : [...boards, ...response.boards]));
    setLoaded(true);
  }, [userId, signInUser, setBoards, setLoaded]);

  useEffect(() => {
    loadBoards();
  }, [loadBoards]);

  const onScroll = useSafeCallback(
    (entries: IntersectionObserverEntry[]): void => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;
        loadBoards();
      }
    },
    [loadBoards]
  );

  useEffect(() => {
    if (!loaded) return;

    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => onScroll(entries), OPTIONS);
    bottomRef.current && observer.observe(bottomRef.current);

    return () => observer.disconnect();
  }, [loaded, onScroll]);

  return (
    <Component className='board-list'>
      <HeaderArea>
        <BoardListHeader label='共通の寄せ書き' emitAction={emitAction} />
      </HeaderArea>

      <Container>
        <Scrollable showScrollbar>
          <PolaroidArea>
            {(loaded ? boards : INITIAL_BOARDS).map((board: BoardEntity, index: Index) => (
              <PolaroidWrapper key={index} position={isEven(index) ? 'left' : 'right'}>
                <BoardListPolaroid
                  rotate={isEven(index) ? 'left' : 'right'}
                  board={board}
                  onClickBoard={onClickBoard}
                />
              </PolaroidWrapper>
            ))}
          </PolaroidArea>

          {hasMore.current && (
            <LoaderWrapper>
              <CircularLoader />
            </LoaderWrapper>
          )}

          <TipsWrapper>
            <Tips message={TIPS} />
          </TipsWrapper>

          <Bottom ref={bottomRef} />
        </Scrollable>
      </Container>
    </Component>
  );
});

export default CommonBoardList;

const HeaderArea = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT}px;
`;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
`;

const PolaroidArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: ${theme.mixins.spacing * 10}px;
`;

const PolaroidWrapper = styled.div<{ position: Rotate }>`
  width: auto;
  height: auto;
  margin-top: ${props => (props.position === 'right' ? '0px' : '-64px')};
`;

const TipsWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 5}px ${theme.mixins.spacing}px;
  margin-bottom: ${theme.mixins.spacing * 10}px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bottom = styled.div`
  width: 100%;
  height: 72px;
`;

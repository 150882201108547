import React from 'react';
import styled from 'styled-components';
import AuthGuard from '../../../auth/AuthGuard';
import Footer from '../../../components/footer/Footer';
import usePath from '../../../redux/hooks/usePath';
import { Path } from '../../../router/Routes';
import AccountScreen from '../../account/AccountScreen';
import HomeScreen from '../../board/BoardScreen';
import ConnectionsScreen from '../../connections/ConnectionsScreen';
import NotificationsScreen from '../../notifications/NotificationsScreen';

interface P {}

const GuardedScreens: React.FC<P> = React.memo(() => {
  const { path } = usePath();

  return (
    <>
      <ScreenWrapper
        display={(
          path === Path.BOARD_LIST ||
          path === Path.BOARD_MESSAGE ||
          path === Path.BOARD_DETAILS ||
          path === Path.BOARD_THREAD
        ).toString()}
      >
        <HomeScreen />
      </ScreenWrapper>

      <ScreenWrapper display={(path === Path.CONNECTIONS).toString()}>
        <ConnectionsScreen />
      </ScreenWrapper>

      <ScreenWrapper display={(path === Path.NOTIFICATIONS).toString()}>
        <NotificationsScreen />
      </ScreenWrapper>

      <ScreenWrapper display={(path === Path.ACCOUNT).toString()}>
        <AccountScreen />
      </ScreenWrapper>

      <Footer />
    </>
  );
});

export default AuthGuard(GuardedScreens);

const ScreenWrapper = styled.div<{ display: string }>`
  width: 100%;
  height: auto;
  display: ${props => (props.display === 'true' ? 'block' : 'none')};
`;

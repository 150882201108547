import { Component, styleForFullExpansion, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { UserId } from '@atomica.co/types';
import { builder, Description, Email, EMPTY, Name, Request, URL } from '@atomica.co/utils';
import { BoardEntity, BoardMemberEntity, FrameId, SaveNewBoardRequest, UserEntity } from '@atomica.co/yosemal';
import { default as React, useEffect } from 'react';
import useUser from '../../../../redux/hooks/useUser';
import BoardRequest from '../../../../requests/board-request';
import ConfirmBoardPreview from './parts/ConfirmBoardPreview';
import CreateBoardMain from './parts/CreateBoardMain';
import InputBoardInfo from './parts/InputBoardInfo';
import InputEmailsToInvite from './parts/InputEmailsToInvite';
import SelectBoardFrame from './parts/SelectBoardFrame';
import SelectConnectionsToInvite from './parts/SelectConnectionsToInvite';

export const BOARD_NAME_LENGTH = 30;

export enum CreateBoardIndex {
  CREATE_BOARD_MAIN = 'create_board_main',
  SELECT_BOARD_FRAME = 'select_board_frame',
  INPUT_BOARD_NAME = 'input_board_name',
  INPUT_BOARD_DESCRIPTION = 'input_board_description',
  INPUT_REQUEST_FOR_NEW_PHOTO = 'input_request_for_new_photo',
  SELECT_CONNECTIONS_TO_INVITE = 'select_connections_to_invite',
  INPUT_EMAILS_TO_INVITE = 'input_emails_to_invite',
  CONFIRM_BOARD_PREVIEW = 'confirm_board_preview'
}

interface P {
  connectionsToInvite?: UserEntity[];
  userId: UserId;
  onSaveBoard(): void;
  onClose(): void;
}

const CreateBoard: React.FC<P> = React.memo(props => {
  const { connectionsToInvite = [], userId, onSaveBoard, onClose } = props;

  const { getUser } = useUser();
  const unmountRef = useUnmountRef();
  const [user, setUser] = useSafeState<UserEntity | undefined>(unmountRef);
  const [index, setIndex] = useSafeState<CreateBoardIndex>(unmountRef, CreateBoardIndex.CREATE_BOARD_MAIN);

  const [nameToSave, setNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [descriptionToSave, setDescriptionToSave] = useSafeState<Description | undefined>(unmountRef);
  const [photoURLToSave, setPhotoURLToSave] = useSafeState<URL | undefined>(unmountRef);
  const [frameIdToSave, setFrameIdToSave] = useSafeState<FrameId | undefined>(unmountRef, FrameId.NONE);
  const [isRequiredNewPhotoToSave, setIsRequiredNewPhotoToSave] = useSafeState<boolean>(unmountRef, false);
  const [requestForNewPhotoToSave, setRequestForNewPhotoToSave] = useSafeState<Request | undefined>(unmountRef);
  const [dueDateToSave, setDueDateToSave] = useSafeState<Date>(unmountRef, new Date());
  const [enableReminderTwentyFourHoursBeforeToSave, setEnableReminderTwentyFourHoursBeforeToSave] =
    useSafeState<boolean>(unmountRef, true);
  const [enableReminderOneWeekBeforeToSave, setEnableReminderOneWeekBeforeToSave] = useSafeState<boolean>(
    unmountRef,
    true
  );
  const [allowToInviteByMembersToSave, setAllowToInviteByMembersToSave] = useSafeState<boolean>(unmountRef, true);
  const [usersToInviteToSave, setInvitedUsersToSave] = useSafeState<UserEntity[]>(unmountRef, []);
  const [emailsToInviteToSave, setInvitedEmailsToSave] = useSafeState<Email[]>(unmountRef, []);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const user = await getUser();
    setUser(user);
    !!connectionsToInvite.length && setInvitedUsersToSave(connectionsToInvite);
  }, [getUser, setUser, connectionsToInvite, setInvitedUsersToSave]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const reset = useSafeCallback(async (): Promise<void> => {
    setIndex(CreateBoardIndex.CREATE_BOARD_MAIN);
    setNameToSave(EMPTY);
    setDescriptionToSave(undefined);
    setPhotoURLToSave(undefined);
    setFrameIdToSave(undefined);
    setIsRequiredNewPhotoToSave(false);
    setRequestForNewPhotoToSave(undefined);
    setDueDateToSave(new Date());
    setEnableReminderTwentyFourHoursBeforeToSave(true);
    setEnableReminderOneWeekBeforeToSave(true);
    setAllowToInviteByMembersToSave(true);
    setInvitedUsersToSave([]);
    setInvitedEmailsToSave([]);
  }, [
    setIndex,
    setNameToSave,
    setDescriptionToSave,
    setPhotoURLToSave,
    setFrameIdToSave,
    setIsRequiredNewPhotoToSave,
    setRequestForNewPhotoToSave,
    setDueDateToSave,
    setEnableReminderTwentyFourHoursBeforeToSave,
    setEnableReminderOneWeekBeforeToSave,
    setAllowToInviteByMembersToSave,
    setInvitedUsersToSave,
    setInvitedEmailsToSave
  ]);

  const saveBoard = useSafeCallback(async (): Promise<void> => {
    const request = builder<SaveNewBoardRequest>()
      .name(nameToSave)
      .description(descriptionToSave!)
      .photoURL(photoURLToSave!)
      .frameId(frameIdToSave!)
      .isRequiredNewPhoto(isRequiredNewPhotoToSave)
      .requestForNewPhoto(requestForNewPhotoToSave!)
      .dueDate(dueDateToSave)
      .enableReminderTwentyFourHoursBefore(enableReminderTwentyFourHoursBeforeToSave)
      .enableReminderOneWeekBefore(enableReminderOneWeekBeforeToSave)
      .allowToInviteByMembers(allowToInviteByMembersToSave)
      .ownerUserId(userId)
      .userIdsToInvite(usersToInviteToSave.map(user => user.userId))
      .emailsToInvite(emailsToInviteToSave)
      .build();

    await BoardRequest.saveNewBoard(request);

    reset();
    onSaveBoard();
  }, [
    nameToSave,
    descriptionToSave,
    photoURLToSave,
    frameIdToSave,
    isRequiredNewPhotoToSave,
    requestForNewPhotoToSave,
    dueDateToSave,
    enableReminderTwentyFourHoursBeforeToSave,
    enableReminderOneWeekBeforeToSave,
    allowToInviteByMembersToSave,
    userId,
    usersToInviteToSave,
    emailsToInviteToSave,
    reset,
    onSaveBoard
  ]);

  const handleCloseButtonClicked = useSafeCallback((): void => {
    reset();
    onClose();
  }, [reset, onClose]);

  const handleSelectedFrameUpdated = useSafeCallback(
    (frameId: FrameId): void => {
      setFrameIdToSave(frameId);
      setIndex(CreateBoardIndex.CREATE_BOARD_MAIN);
    },
    [setFrameIdToSave, setIndex]
  );

  const handleBoardNameUpdated = useSafeCallback(
    (name: Name): void => {
      setNameToSave(name);
      setIndex(CreateBoardIndex.CREATE_BOARD_MAIN);
    },
    [setNameToSave, setIndex]
  );

  const handleBoardDescriptionUpdated = useSafeCallback(
    (description: Description): void => {
      setDescriptionToSave(description);
      setIndex(CreateBoardIndex.CREATE_BOARD_MAIN);
    },
    [setDescriptionToSave, setIndex]
  );

  const handleRequestForNewPhotoUpdated = useSafeCallback(
    (request: Request): void => {
      setRequestForNewPhotoToSave(request);
      setIndex(CreateBoardIndex.CREATE_BOARD_MAIN);
    },
    [setRequestForNewPhotoToSave, setIndex]
  );

  const handleSelectedConnectionsUpdated = useSafeCallback(
    (users: UserEntity[]): void => {
      setInvitedUsersToSave(users);
      setIndex(CreateBoardIndex.CREATE_BOARD_MAIN);
    },
    [setInvitedUsersToSave, setIndex]
  );

  const handleInputEmailsUpdated = useSafeCallback(
    (emails: Email[]): void => {
      setInvitedEmailsToSave(emails);
      setIndex(CreateBoardIndex.CREATE_BOARD_MAIN);
    },
    [setInvitedEmailsToSave, setIndex]
  );

  return (
    <Component style={styleForFullExpansion} className='create-board'>
      {index === CreateBoardIndex.CREATE_BOARD_MAIN && (
        <CreateBoardMain
          name={nameToSave}
          description={descriptionToSave}
          photoURL={photoURLToSave}
          frameId={frameIdToSave}
          isRequiredNewPhoto={isRequiredNewPhotoToSave}
          requestForNewPhoto={requestForNewPhotoToSave}
          dueDate={dueDateToSave}
          enableReminderTwentyFourHoursBefore={enableReminderTwentyFourHoursBeforeToSave}
          enableReminderOneWeekBefore={enableReminderOneWeekBeforeToSave}
          allowToInviteByMembers={allowToInviteByMembersToSave}
          invitedUsers={usersToInviteToSave}
          invitedEmails={emailsToInviteToSave}
          onChangePhoto={setPhotoURLToSave}
          onChangeIsRequiredNewPhoto={setIsRequiredNewPhotoToSave}
          onChangeDueDate={setDueDateToSave}
          onChangeEnableReminderTwentyFourHoursBefore={setEnableReminderTwentyFourHoursBeforeToSave}
          onChangeEnableReminderOneWeekBefore={setEnableReminderOneWeekBeforeToSave}
          onChangeAllowToInviteByMembers={setAllowToInviteByMembersToSave}
          goTo={setIndex}
          onClose={handleCloseButtonClicked}
        />
      )}

      {index === CreateBoardIndex.SELECT_BOARD_FRAME && (
        <SelectBoardFrame
          photoURL={photoURLToSave}
          selectedFrameId={frameIdToSave}
          onClickSaveButton={handleSelectedFrameUpdated}
          goTo={setIndex}
        />
      )}

      {index === CreateBoardIndex.INPUT_BOARD_NAME && (
        <InputBoardInfo
          maxLength={BOARD_NAME_LENGTH}
          label='ボード名'
          text={nameToSave}
          onClickSaveButton={handleBoardNameUpdated}
          goTo={setIndex}
        />
      )}

      {index === CreateBoardIndex.INPUT_BOARD_DESCRIPTION && (
        <InputBoardInfo
          multiline
          label='説明'
          text={descriptionToSave}
          onClickSaveButton={handleBoardDescriptionUpdated}
          goTo={setIndex}
        />
      )}

      {index === CreateBoardIndex.INPUT_REQUEST_FOR_NEW_PHOTO && (
        <InputBoardInfo
          multiline
          label='希望する写真'
          text={requestForNewPhotoToSave}
          onClickSaveButton={handleRequestForNewPhotoUpdated}
          goTo={setIndex}
        />
      )}

      {index === CreateBoardIndex.SELECT_CONNECTIONS_TO_INVITE && (
        <SelectConnectionsToInvite
          userId={userId}
          selectedConnections={usersToInviteToSave}
          onClickSaveButton={handleSelectedConnectionsUpdated}
          goTo={setIndex}
        />
      )}

      {index === CreateBoardIndex.INPUT_EMAILS_TO_INVITE && (
        <InputEmailsToInvite
          input={emailsToInviteToSave}
          onClickSaveButton={handleInputEmailsUpdated}
          goTo={setIndex}
        />
      )}

      {index === CreateBoardIndex.CONFIRM_BOARD_PREVIEW && (
        <ConfirmBoardPreview
          board={builder<BoardEntity>()
            .name(nameToSave)
            .description(descriptionToSave!)
            .photoURL(photoURLToSave!)
            .frameId(frameIdToSave!)
            .dueDate(new Date())
            .isRequiredNewPhoto(isRequiredNewPhotoToSave)
            .requestForNewPhoto(requestForNewPhotoToSave!)
            .owner(user!)
            .membersForThumbnail([user!, ...usersToInviteToSave])
            .createdAt(new Date())
            .build()}
          members={[
            builder<BoardMemberEntity>()
              .userId(user!.userId)
              .familyName(user!.familyName)
              .firstName(user!.firstName)
              .selfIntroduction(user!.selfIntroduction!)
              .photoURL(user!.photoURL)
              .build(),
            ...usersToInviteToSave.map(user =>
              builder<BoardMemberEntity>()
                .userId(user.userId)
                .familyName(user.familyName)
                .firstName(user.firstName)
                .selfIntroduction(user.selfIntroduction!)
                .photoURL(user.photoURL)
                .build()
            ),
            ...emailsToInviteToSave.map(email => builder<BoardMemberEntity>().email(email).build())
          ]}
          onClickSaveButton={saveBoard}
          goTo={setIndex}
        />
      )}
    </Component>
  );
});

export default CreateBoard;
